import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-adddishes',
  templateUrl: './adddishes.component.html',
  styleUrls: ['./adddishes.component.css']
})
export class AdddishesComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  public imagePath;
  imgURL: any;
  public message: string;

  pageColl: any = [];
  //Edit Get Values
  dishesColl: any = [];
  dishesid: number = 0

  //pageColl:any=[];

  dishesform = new FormGroup({
    dishesid: new FormControl(0),
    dishesname: new FormControl('', Validators.required),
    dishesimage: new FormControl(null),
    pageid: new FormControl(null),
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Dishes";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.dishesid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.dishesform.reset();
      this.fnInitializeDishes();

      if (this.dishesid > 0) {
        this.fngetDishes();
      }
      this.fngetPage()
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.dishesid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeDishes() {
    this.dishesform.setValue({

      dishesid: 0,
      dishesname: '',
      dishesimage: null,
      pageid: null,
      inactive: false,
    })
  }

  fngetPage() {
    var data = {
      'pageid': 0,
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.pageColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetDishes() {
    var data = {
      'dishesid': this.dishesid,
    }
    this.appService.getDishes(data)
      .subscribe(
        (res) => {
          this.dishesColl = res.json().result;
          var editdata = this.dishesColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.dishesform.valid) {
        //Check
        var data = {
          'dishesid': this.dishesid,
          'dishesname': this.dishesform.get('dishesname').value,
          dishesimage: this.strImage,
          picurl: this.picurl,
          'pageid': this.dishesform.get('pageid').value,
          'inactive': this.dishesform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckDishes(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.dishesform.get('dishesid').value > 0) {
                //Update Department
                this.fnSetDishes(data);
              }
              else {
                //Insert Department
                this.fnInsertDishes(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.dishesform.controls['dishesname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertDishes(data) {

    this.appService.fnInsertDishes(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Dishes has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.dishesname);

          this.fnCloseDishes();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetDishes(data) {

    this.appService.fnSetDishes(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Dishes has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.dishesid, data.dishesname);

          this.fnCloseDishes();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseDishes() {
    try {
      this.router.navigate(['/Dishes']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.dishesform.setValue({
      dishesid: deptBO.dishesid,
      dishesname: deptBO.dishesname,
      dishesimage: null,
      pageid: deptBO.pageid,
      inactive: deptBO.inactive,
    });
    this.picurl = deptBO.picurl;

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }






  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
