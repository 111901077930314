import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { RestaurantfindComponent } from '../grid/restaurantfind/restaurantfind.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandfindComponent } from '../grid/brandfind/brandfind.component';
import { CuisinefindComponent } from '../grid/cuisinefind/cuisinefind.component';
import { DishesfindComponent } from '../grid/dishesfind/dishesfind.component';
 
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, AfterViewInit, OnDestroy {

  step1class = "";
  step2class = "";
  step3class = "";
  step4class = "";

  pageColl: any = [];


  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Page";

  filterby: String = "ACTIVE";
  

  
searchtxt: any = "";

columnDefs = [
  { headerName: 'Page Name', field: 'pagename'}  


]

gridOptions = {
  enableactioncol: true,
  columnDefs: this.columnDefs,
};
  
  


  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {

     
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetPage();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
  
  }

 

  
  //Event  
  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }

    this.fnEditPage(data.pageid);

   
  }

  fnActionDeleteClick(data) {

    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.pageid);


  }

  fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'pagelist',      
    };
    // this.gridApi.exportDataAsCsv(params);
  }

  fngetPage() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'pageid': 0,
     
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            var pageallColl :any=[]
            pageallColl = res.json().result;
                
            this.pageColl = [];

            for (let abo of pageallColl) {
              if (this.filterby === 'ACTIVE') { 
                if (abo.inactive === 0) {
                  this.pageColl.push(abo)
                }                        
              }
              else if (this.filterby === 'INACTIVE') { 
                if (abo.inactive === 1) {
                  this.pageColl.push(abo)
                }  
              }
              else{
                this.pageColl.push(abo)
              }
            }
            
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
//Grid

fnAddRestaurantFindDetails() {

  const modalRef = this.productFindService.open(RestaurantfindComponent, { size: <any>'l' });


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   

  });
}
fnAddBrandFindDetails() {

  const modalRef = this.productFindService.open(BrandfindComponent, { size: <any>'l' });


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   

  });
}
fnAddCuisineFindDetails() {

  const modalRef = this.productFindService.open(CuisinefindComponent, { size: <any>'l' });


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   

  });
}
fnAddDishesFindDetails() {

  const modalRef = this.productFindService.open(DishesfindComponent, { size: <any>'l' });


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   

  });
}
  fnAddPage() {
    try {
      this.router.navigate(['/AddPage']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditPage(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddPage'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Page?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeletePage(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeletePage(deptid) {
    var data = {
      'pageid': deptid,
    }
    this.appService.fnDeletePage(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The page has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

            this.fngetPage();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  private fnSetDataTable() {
 
   
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }






  fnGetDescription(id) {
    const index = this.pageColl.findIndex(sku => sku.pageid === id);

    if (index) {
      return this.pageColl[index].pagename;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res) => {
      //   },
      //   (err) => {

      //   });

    } catch{

    }
  }



  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

  private fnSetStatus() {

    this.step1class = "";
    this.step2class = "";
    this.step3class = "";
    this.step4class = "";

    this.step1class = "is-complete";
    this.step2class = "is-complete";
    this.step3class = "is-complete";
    this.step4class = "is-active";

  }


}
