import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addpaymentmode',
  templateUrl: './addpaymentmode.component.html',
  styleUrls: ['./addpaymentmode.component.css']
})
export class AddpaymentmodeComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

 

  //Edit Get Values
  paymentmodeColl: any = [];
  paymentmodeid: number = 0

  paytypeColl:any=[];
  //pageColl:any=[];

  paymentmodeform = new FormGroup({
    paymentmodeid: new FormControl(0),
    paymentmodename: new FormControl('', Validators.required),
    paymenttype: new FormControl('', Validators.required),   
    inactive: new FormControl(false),
  });

 

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Paymentmode";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.paymentmodeid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.paymentmodeform.reset();
      this.fnInitializePaymentmode();

      if (this.paymentmodeid > 0) {
        this.fngetPaymentmode();
      }
      this.fnPaytype();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.paymentmodeid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializePaymentmode() {
    this.paymentmodeform.setValue({

      paymentmodeid: 0,
      paymentmodename: '',
      paymenttype: null,
      inactive: false,
    })
  }


fnPaytype(){
  this.paytypeColl=[];
  this.paytypeColl.push({value:'CASH',displayname:'CASH' });
  this.paytypeColl.push({value:'CARD',displayname:'CARD' });
  this.paytypeColl.push({value:'WALLET',displayname:'WALLET' });
}

  //Get 
  fngetPaymentmode() {
    var data = {
      'paymentmodeid': this.paymentmodeid,
    }
    this.appService.getPaymentmode(data)
      .subscribe(
        (res) => {
          this.paymentmodeColl = res.json().result;
          var editdata = this.paymentmodeColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.paymentmodeform.valid) {
        //Check
        var data = {
          'paymentmodeid': this.paymentmodeid,
          'paymentmodename': this.paymentmodeform.get('paymentmodename').value,
          'paymenttype': this.paymentmodeform.get('paymenttype').value,
          'inactive': this.paymentmodeform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckCuisine(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.paymentmodeform.get('paymentmodeid').value > 0) {
                //Update Department
                this.fnSetPaymentmode(data);
              }
              else {
                //Insert Department
                this.fnInsertPaymentmode(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.paymentmodeform.controls['paymentmodename'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertPaymentmode(data) {

    this.appService.fnInsertPaymentmode(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new paymentmode has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.paymentmodename);

          this.fnClosePaymentmode();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetPaymentmode(data) {

    this.appService.fnSetPaymentmode(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The paymentmode has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.paymentmodeid, data.paymentmodename);

          this.fnClosePaymentmode();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnClosePaymentmode() {
    try {
      this.router.navigate(['/Paymentmode']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.paymentmodeform.setValue({
      paymentmodeid: deptBO.paymentmodeid,
      paymentmodename: deptBO.paymentmodename,
     paymenttype: deptBO.paymenttype,
      inactive: deptBO.inactive,
    });
   
    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }







  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
