import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AddcategorypopupComponent } from '../../generalpopup/addcategorypopup/addcategorypopup.component';
import { AddsubcategorypopupComponent } from '../../generalpopup/addsubcategorypopup/addsubcategorypopup.component';
import { AdduompopupComponent } from '../../generalpopup/adduompopup/adduompopup.component';
import { AddproductvariationComponent } from '../addproductvariation/addproductvariation.component';
import { SelectdishesComponent } from '../selectdishes/selectdishes.component';
import { SelectproducttagComponent } from '../selectproducttag/selectproducttag.component';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {


  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  iscategoryaddmode: boolean = false;
  issubcategoryaddmode: boolean = false;
  isuomaddmode: boolean = false;


  //Save Validation
  submitted: boolean;
  successfully: boolean;

  productBO: any = [];
  //Edit Get Values
  productColl: any = [];
  productid: number = 0
  productvariationColl: any = [];
  producttimingcoll: any = [];
  productdishescoll: any = [];
  productdisplaydishescoll: any = [];

  actiontype: string = ""

  producttagcoll: any = [];
  productdisplaytagcoll: any = [];

  categoryColl: any = [];
  subcategoryColl: any = [];
  uomColl: any = [];
  foodsymbolColl: any = [];
  taxgroupColl: any = [];

  //pageColl:any=[];

  productform = new FormGroup({
    productid: new FormControl(0),
    skucode: new FormControl('', Validators.required),
    productname: new FormControl('', Validators.required),
    description: new FormControl(null),
    descriptionhtml: new FormControl(null),
    categoryid: new FormControl(null, Validators.required),
    subcategoryid: new FormControl(null, Validators.required),
    uomid: new FormControl(null, Validators.required),
    uomqty: new FormControl(null, Validators.required),
    price: new FormControl(null, Validators.min(1)),
    foodsymbolid: new FormControl(null, Validators.required),
    taxgroupid: new FormControl(null, Validators.required),
    ispublished: new FormControl(null),
    istaxincluded: new FormControl(null),
    isvariations: new FormControl(null),
    isnopreparation: new FormControl(null),
    isoutofstock: new FormControl(null),
    preparationmin: new FormControl(null),
    isapproved: new FormControl(false),
    isfavourite: new FormControl(false),
    productimage: new FormControl(null),
    cost: new FormControl(null),
    maxorderqty: new FormControl(null),
    linkskucode: new FormControl(null),
    orderno: new FormControl(null),
    mrp: new FormControl(null),
    inactive: new FormControl(false),
  });


  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Product";

  constructor(private appService: DhukanDataService, private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.productid = params.id
      }
      if (params.actiontype !== undefined) {
        this.actiontype = params.actiontype
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.productform.reset();
      this.fnInitializeProduct();

      if (this.productid > 0) {
        this.fngetProduct();
      }

      this.fngetCategory()
      this.fngetUom()
      this.fngetFoodsymbol()
      this.fngetTaxgroup()
      this.fngetProductTiming()
      this.fngetProductDishes()
      this.fngetProductTags()
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.productid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }
    this.iscategoryaddmode = this.loginService.fnCheckUserRoleAction("Category", 'CREATE')
    this.issubcategoryaddmode = this.loginService.fnCheckUserRoleAction("Subcategory", 'CREATE')
    this.isuomaddmode = this.loginService.fnCheckUserRoleAction("Uom", 'CREATE')


    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeProduct() {
    this.productform.setValue({

      productid: 0,
      skucode: 'new',
      productname: null,
      description: null,
      descriptionhtml: null,
      categoryid: null,
      subcategoryid: null,
      uomid: null,
      uomqty: 1,
      price: 0,
      foodsymbolid: null,
      taxgroupid: null,
      ispublished: null,
      istaxincluded:null,
      isvariations: null,
      isnopreparation: null,
      isoutofstock: null,
      preparationmin: null,
      isapproved: null,
      productimage: null,
      isfavourite:false,
      cost: null,
      linkskucode: null,
      maxorderqty: null,
      orderno: null,
      mrp:null,
      inactive: false,
    })
  }


  fngetProductDishes() {
    var data = {
      'productid': this.productid,
    }
    this.appService.getProductDishes(data)
      .subscribe(
        (res) => {
          this.productdishescoll = res.json().result;
          console.log(this.productdishescoll)
          this.fnFormatDishesFormat();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fngetProductTags() {
    var data = {
      'productid': this.productid,
    }
    this.appService.getProductTag(data)
      .subscribe(
        (res) => {
          this.producttagcoll = res.json().result;
          console.log(this.producttagcoll)
          this.fnFormatTagFormat();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetCategory() {
    var data = {
      'categoryid': 0,
      'restaurantid': this.loginService.fnGetModRestaruntId()
    }
    this.appService.getCategory(data)
      .subscribe(
        (res) => {
          this.categoryColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetSubCategory() {

    if (this.productform.get('categoryid').value > 0) {

      var data = {
        'categoryid': this.productform.get('categoryid').value,
        'restaurantid': this.loginService.fnGetModRestaruntId()
      }
      this.appService.getSubcategory(data)
        .subscribe(
          (res) => {
            this.subcategoryColl = res.json().result;

            if (this.subcategoryColl.length > 0) {
              let ischeck = false;
              for (let lbo of this.subcategoryColl) {
                if (lbo.subcategoryid === this.productform.get('subcategoryid').value) {
                  ischeck = true;
                }
              }

              if (ischeck === false) {
                this.productform.patchValue({ subcategoryid: null });
              }

            }

          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }
    else {
      this.subcategoryColl = []
      this.productform.patchValue({ subcategoryid: null });
    }

  }


  fnCategoryChange() {
    let categoryid = this.productform.get('categoryid').value;
    if (categoryid === null) {
      this.productform.patchValue({ subcategoryid: null });
    }

  }

  fngetFoodsymbol() {
    var data = {
      'foodsymbolid': 0,
    }
    this.appService.getFoodsymbol(data)
      .subscribe(
        (res) => {
          this.foodsymbolColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fngetUom() {
    var data = {
      'uomid': 0,
      'restaurantid': this.loginService.fnGetModRestaruntId()
    }
    this.appService.getUom(data)
      .subscribe(
        (res) => {
          this.uomColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fngetTaxgroup() {
    var data = {
      'taxgroupid': 0,
    }
    this.appService.getTaxgroup(data)
      .subscribe(
        (res) => {
          this.taxgroupColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetProductTiming() {
    var data = {
      'productid': this.productid,
    }
    this.appService.getProductTiming(data)
      .subscribe(
        (res) => {
          this.producttimingcoll = res.json().result;


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetProduct() {
    var data = {
      'productid': this.productid,
    }
    this.appService.getProduct(data)
      .subscribe(
        (res) => {

          this.productColl = res.json().result[0];
          this.productvariationColl = res.json().result[1];

          var editdata = this.productColl[0];
          this.productBO = editdata
          console.log(this.productBO)
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.productform.valid) {
        //Check
        var data = {
          'productid': this.productid,
          'restaurantid': this.loginService.fnGetModRestaruntId(),
          'skucode': this.productform.get('skucode').value,
          'productname': this.productform.get('productname').value,
          'description': this.productform.get('description').value,
          'descriptionhtml': this.productform.get('descriptionhtml').value,
          'categoryid': this.productform.get('categoryid').value,
          'subcategoryid': this.productform.get('subcategoryid').value,
          'uomid': this.productform.get('uomid').value,
          'uomqty': this.productform.get('uomqty').value,
          'price': this.productform.get('price').value,
          'foodsymbolid': this.productform.get('foodsymbolid').value,
          'taxgroupid': this.productform.get('taxgroupid').value,
          'istaxincluded': this.productform.get('istaxincluded').value,
          'ispublished': this.productform.get('ispublished').value,
          'isvariations': this.productform.get('isvariations').value,
          'isnopreparation': this.productform.get('isnopreparation').value,
          'isoutofstock': this.productform.get('isoutofstock').value,
          'preparationmin': this.productform.get('preparationmin').value,
          'isapproved': this.productform.get('isapproved').value,
          'isfavourite': this.productform.get('isfavourite').value,
          'productimage': this.strImage,
          'picurl': this.picurl,
          'producttimingcoll': this.producttimingcoll,
          'cost': this.productform.get('cost').value,
          'linkskucode': this.productform.get('linkskucode').value,
          'maxorderqty': this.productform.get('maxorderqty').value,
          'orderno': this.productform.get('orderno').value,
          'mrp': this.productform.get('mrp').value,
          'inactive': this.productform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'productdishescoll': this.productdishescoll,
          'producttagcoll': this.producttagcoll,
        }
        this.appService.fnCheckProduct(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;
            
            if (_checkresult === "done") {
              if (this.productform.get('productid').value > 0) {
                //Update Department
                this.fnSetProduct(data);
              }
              else {
                //Insert Department
                this.fnInsertProduct(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.productform.controls['productname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertProduct(data) {

    this.appService.fnInsertProduct(data).subscribe(
      (res) => {

          
        if (res.json().status === 200) {
          this.toastr.success('', "The new Product has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.productname);

          this.fnCloseProduct();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetProduct(data) {
  
    this.appService.fnSetProduct(data).subscribe(
      (res) => {
        console.log(res.json())
        if (res.json().status === 200) {
          this.toastr.success('', "The Product has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.productid, data.productname);

          this.fnCloseProduct();
        }
      },
      (err) => {
        console.log(err)
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseProduct() {
    try {
      this.router.navigate(['/Product']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  
  fnCopytoallBtnClick() {
    if (this.producttimingcoll.length > 0) {
      var data = this.producttimingcoll[0];
      for (let abo of this.producttimingcoll) {
        abo.inactive = data.inactive;
        abo.starttime = data.starttime;
        abo.endtime = data.endtime;
      }
    }
  }



  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }



  //Product Variation
  private fnAddProductVariation() {

    const modalRef = this.productFindService.open(AddproductvariationComponent, { size: <any>'l', backdrop: 'static',keyboard: false  });
    modalRef.componentInstance.productid = this.productid

    modalRef.componentInstance.fnInitializeProductFormGroup();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fnInsertProductVariation(receivedEntry);
    });

  }


  private fnEditProductVariation(deptBO) {

    const modalRef = this.productFindService.open(AddproductvariationComponent, { size: <any>'l', backdrop: 'static',keyboard: false  });
    modalRef.componentInstance.productvariationsid = deptBO.productvariationsid
    modalRef.componentInstance.productid = this.productid
    modalRef.componentInstance.variationsname = deptBO.variationsname
    modalRef.componentInstance.uomid = deptBO.uomid
    modalRef.componentInstance.uomqty = deptBO.uomqty
    modalRef.componentInstance.price = deptBO.price
    modalRef.componentInstance.cost = deptBO.cost
    modalRef.componentInstance.mrp = deptBO.mrp
    
    modalRef.componentInstance.isoutofstock = deptBO.isoutofstock
    modalRef.componentInstance.isdisplay = deptBO.isdisplay
    modalRef.componentInstance.inactive = deptBO.inactive

    modalRef.componentInstance.fnInitializeProductFormGroup();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fnSetProductVariation(receivedEntry);
    });

  }


  public fnDeleteProductVariationConfirm(deptBO) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Product Variation?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteProductVariation(deptBO)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Insert
  fnInsertProductVariation(data) {

    this.appService.fnInsertProductVariation(data).subscribe(
      (res) => {

        //console.log(res.json())
        if (res.json().result != "") {
          this.toastr.success('', "The New Product Variation has been added successfully.Insert Successfully");

          // this.fnInsertActivityLog('INSERT', 0, data.productname);
          this.fngetProduct();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetProductVariation(data) {

    this.appService.fnSetProductVariation(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Product Variation has been updated successfully.");

          //  this.fnInsertActivityLog('EDIT', this.productid, data.productname);

          this.fngetProduct();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Delete
  fnDeleteProductVariation(deptBO) {

    var data = {
      productvariationsid: deptBO.productvariationsid,
      modifiedby: this.loginService.fnGetModUserId(),
    }

    this.appService.fnDeleteProductVariation(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Product Variation has been deleted successfully.");

          //  this.fnInsertActivityLog('EDIT', this.productid, data.productname);

          this.fngetProduct();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  fnSetApproved() {
    var data = {
      'productid': this.productid,
      'isapproved': this.productBO.isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }

    this.appService.fnSetProductApproved(data).subscribe((res) => {

      window.location.reload()
      if (res.json().result === 200) {

        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }
  fnSetOutofStock() {
    var data = {
      'productid': this.productid,
      'isoutofstock': this.productBO.isoutofstock,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetProductOutofStock(data).subscribe((res) => {
      if (res.json().result === 200) {
        this.toastr.success('', "The OutofStock has been updated successfully.");
      }
    })
  }
  checkValueDishes(values: any, index) {
    this.productdishescoll[index].ischeck = values.currentTarget.checked;
  }
  fnFormatDishesFormat() {
    this.productdisplaydishescoll = [];

    for (let abo of this.productdishescoll) {
      if (abo.ischeck) {
        this.productdisplaydishescoll.push(abo)
      }
    }

  }

  checkValueTag(values: any, index) {
    this.producttagcoll[index].ischeck = values.currentTarget.checked;
  }
  fnFormatTagFormat() {
    this.productdisplaytagcoll = [];

    for (let abo of this.producttagcoll) {
      if (abo.ischeck) {
        this.productdisplaytagcoll.push(abo)
      }
    }

  }
  fnEditBtnClick() {
    this.iseditmode = true;

    this.isviewmode = false;
  }

  private fnEditMode(deptBO) {

    this.productform.setValue({
      productid: deptBO.productid,
      skucode: deptBO.skucode,
      productname: deptBO.productname,
      description: deptBO.description,
      descriptionhtml: deptBO.descriptionhtml,
      categoryid: deptBO.categoryid,
      subcategoryid: deptBO.subcategoryid,
      uomid: deptBO.uomid,
      uomqty: deptBO.uomqty,
      price: deptBO.price,
      foodsymbolid: deptBO.foodsymbolid,
      taxgroupid: deptBO.taxgroupid,
      istaxincluded: deptBO.istaxincluded,
      ispublished: deptBO.ispublished,
      isvariations: deptBO.isvariations,
      isnopreparation: deptBO.isnopreparation,
      isoutofstock: deptBO.isoutofstock,
      preparationmin: deptBO.preparationmin,
      isapproved: deptBO.isapproved,
      isfavourite: deptBO.isfavourite,
      productimage: null,
      cost: deptBO.cost,
      linkskucode: deptBO.linkskucode,
      maxorderqty: deptBO.maxorderqty,
      orderno: deptBO.orderno,
      mrp:deptBO.mrp,
      inactive: deptBO.inactive,
    });

    this.picurl = deptBO.picurl;


    this.fngetSubCategory()

    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;

    if (this.actiontype === "EDIT") {
      this.iseditmode = true ;
      this.isviewmode =false;
    }

  }
  //AddCategory
  private fnAddCategory() {

    const modalRef = this.productFindService.open(AddcategorypopupComponent, { size: <any>'m' , backdrop: 'static',keyboard: false });
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetCategory();

      if (receivedEntry > 0) {
        this.productform.patchValue({ categoryid: receivedEntry })
      }
    });

  }
  //AddSubcategory
  private fnAddSubcategory() {

    const modalRef = this.productFindService.open(AddsubcategorypopupComponent, { size: <any>'m', backdrop: 'static',keyboard: false  });
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetSubCategory();

      if (receivedEntry > 0) {
        this.productform.patchValue({ subcategoryid: receivedEntry })
      }
    });

  }
  //AddUom
  private fnAddUom() {

    const modalRef = this.productFindService.open(AdduompopupComponent, { size: <any>'m', backdrop: 'static',keyboard: false  });
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetUom();

      if (receivedEntry > 0) {
        this.productform.patchValue({ uomid: receivedEntry })
      }
    });

  }
  fnSelectDishes() {

    const modalRef = this.productFindService.open(SelectdishesComponent, { size: <any>'l', backdrop: 'static',keyboard: false  });

    modalRef.componentInstance.productid = this.productid;
    modalRef.componentInstance.productdishescoll = this.productdishescoll;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.productdisplaydishescoll = receivedEntry;
      this.fnFormatDishesFormat()

    });
  }


  fnSelectTag() {

    const modalRef = this.productFindService.open(SelectproducttagComponent, { size: <any>'l' , backdrop: 'static',keyboard: false });

    modalRef.componentInstance.productid = this.productid;
    modalRef.componentInstance.producttagcoll = this.producttagcoll;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.productdisplaytagcoll = receivedEntry;
      this.fnFormatTagFormat()

    });
  }

  fnCloseDishes(cBO) {
    for (let abo of this.productdishescoll) {
      if (abo.dishesid === cBO.dishesid) {
        abo.ischeck = false
      }
      this.fnFormatDishesFormat()
    }
  }

  fnCloseTag(cBO) {
    for (let abo of this.producttagcoll) {
      if (abo.producttagtypeid === cBO.producttagtypeid) {
        abo.ischeck = false
      }
      this.fnFormatTagFormat()
    }
  }

  fnGetDeepLink() {
   
    var data = {
      linktype: 'NATIVEPRODUCT',
      linktargetid: this.loginService.fnGetModRestaruntId(),
      refid:this.productid,
    }
    this.appService.fnGetDeepLink(data).subscribe(
      (res) => {
       
        var link=res.json().shortLink
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.toastr.success('', "Link Copied !.");

      },
      (err) => {
        this.toastr.error('Error', err);
      });

    
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
