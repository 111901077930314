import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { CustomeraddressComponent } from '../customeraddress/customeraddress.component';

@Component({
  selector: 'app-addcustomer',
  templateUrl: './addcustomer.component.html',
  styleUrls: ['./addcustomer.component.css']
})
export class AddcustomerComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;
  isedit:boolean=false;

  //Edit Get Values
  customerBO: any = {};
  customerdetColl: any = [];
  customerid: number = 0

  customeraddressColl: any = [];
  customerorderColl: any = [];
  customerdevicesColl: any = [];
  customercartColl: any = [];
  customeraddressdraftColl: any = [];
  customerdailylogColl: any = [];
  //pageColl:any=[];

  private gridApi;
  private gridColumnApi;


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Customer";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.customerid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;

      if (this.customerid > 0) {
        this.fngetCustomerDetails();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.customerid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }







  //Get 
  fngetCustomerDetails() {

    var data = {
      'customerid': this.customerid,

    }

    this.appService.getCustomerDetails(data)
      .subscribe(
        (res) => {
          var result = res.json().result;


          if (result.length > 0) {
            this.customerBO = result[0][0]

            this.customeraddressColl = result[1]
            this.customerorderColl = result[2]
            this.customerdevicesColl = result[3]
            this.customercartColl = result[4]
            
            this.customeraddressdraftColl = result[5]
            this.customerdailylogColl= result[6]
          }




        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnAddCustomerAddress() {

    const modalRef = this.productFindService.open(CustomeraddressComponent, { size: <any>'l', backdrop: 'static',keyboard: false  });
    modalRef.componentInstance.customerid = this.customerid;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
     
    });
  }

  fnEditCustomerAddress(index) {


    const modalRef = this.productFindService.open(CustomeraddressComponent, { size: <any>'l' , backdrop: 'static',keyboard: false  });

    var data = this.customeraddressColl[index];
    modalRef.componentInstance.addressid = data.addressid
    modalRef.componentInstance.customerid = data.customerid
    modalRef.componentInstance.addressnickname = data.addressnickname;
    modalRef.componentInstance.houseno = data.houseno;
    modalRef.componentInstance.landmark = data.landmark;
    modalRef.componentInstance.firstname = data.firstname;
    modalRef.componentInstance.lastname = data.lastname;
    modalRef.componentInstance.mobile = data.mobile;
    modalRef.componentInstance.street = data.street;
    modalRef.componentInstance.isdefault = data.isdefault;
    modalRef.componentInstance.inactive = data.inactive;
    modalRef.componentInstance.latitude = data.latitude;
    modalRef.componentInstance.longitude = data.longitude;
    modalRef.componentInstance.maplocationname = data.maplocationname;
    modalRef.componentInstance.addressothername = data.addressothername;


    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      
      

    });

  }

  fnDeleteCustomerAddressConfirm(index) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete details?')
        .then((confirmed) => {
          if (confirmed) {
           
            this.fnDeleteCustomerAddress(index)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  

 private  fnDeleteCustomerAddress(index){
      var data = {
        'addressid':this.customeraddressColl[index].addressid ,            
      }
     this.appService.fnDeleteCustomerAddressAdmin(data)
       .subscribe(
         (res)=>{ 
           if(res.json().status===200){
            this.toastr.success('', " The Address has been deleted successfully.");                
             this.fngetCustomerDetails();
           }else{
             this.toastr.error('',res.json().err_field);   
           }
         },
         (err)=>{          
           this.toastr.error('Error', err);
         })
 }


 
 fnEditOrder(orderid){
  try{
      let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': orderid,
      }
      }

    this.router.navigate(['/AddOrder'], navigationExtras);
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}


  //Close
  fnCloseCustomer() {
    try {
      this.router.navigate(['/Customer']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }




  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
