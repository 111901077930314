import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderoptionComponent } from '../orderoption/orderoption.component';
import { OrderassignComponent } from '../orderassign/orderassign.component';
import { OrdermaplocationComponent } from '../ordermaplocation/ordermaplocation.component';
import { PrintorderComponent } from '../printorder/printorder.component';
@Component({
  selector: 'app-addorder',
  templateUrl: './addorder.component.html',
  styleUrls: ['./addorder.component.css']
})
export class AddorderComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;


  //Edit Get Values
  orderBO: any = {};
  orderdetColl: any = [];
  ordersummaryColl: any = [];
  ordersummaryBO: any = {};
  orderid: number = 0

  orderstatusColl: any = [];
  orderassignColl: any = [];
  //pageColl:any=[];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Cart";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private productFindService: NgbModal, private confirmationDialogService: ConfirmationDialogService,
    private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.orderid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;

      if (this.orderid > 0) {
        this.fngetOrderdetails();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.orderid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }







  //Get 
  fngetOrderdetails() {
    var data = {
      'orderid': this.orderid,

    }

    this.appService.getOrderDetails(data)
      .subscribe(
        (res) => {

          var result = res.json().result;


          if (result.length > 0) {

            this.orderBO = result[0][0]

            this.orderdetColl = result[1]

            this.ordersummaryColl = result[2]

            if (this.ordersummaryColl.length > 0) {
              this.ordersummaryBO = this.ordersummaryColl[0]
            }
            // console.log(result[1])
          }




        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //OrderOption
  fnAddOrderOption() {
    const modalRef = this.productFindService.open(OrderoptionComponent, { size: <any>'l' });

    modalRef.componentInstance.statusname = this.orderBO.status

    // modalRef.componentInstance.statusname='CONFIRMED';
    // modalRef.componentInstance.statusname='READYTOPICK';
    // modalRef.componentInstance.statusname='PICKED';
    // modalRef.componentInstance.statusname='DELIVERED';

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      if (receivedEntry === 'UNCONFIRMED' || receivedEntry === 'UNPICKED' || receivedEntry === 'UNREADYTOPICK' || receivedEntry === 'UNDELIVERED') {
        this.fnOrderConfirmation(receivedEntry);
      }
      else if (receivedEntry === 'CANCELLED') {
        this.fnCancelOrderConfirmation();
      }

    });


  }

  fnOrderStatus(info) {
    var data = {
      'orderid': this.orderid,
      'statustype': info,
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.setOrderStatus(data).subscribe((res) => {
      if (res.json().status === 200) {
        this.orderstatusColl = res.json().result;
        window.location.reload()
      }
    })
  }


  fnOrderConfirmation(status) {
    var msg = ""
    if (status === "CONFIRMED") {
      msg = "confirm"
    }
    else if (status === "READYTOPICK") {
      if (this.orderBO.restauranttype === 'RESTAURANT') {
        msg = "ready to pick"
      }
      else {
        msg = "pack"
      }
    
    }
    else if (status === "PICKED") {
      if (this.orderBO.restauranttype === 'RESTAURANT') {
        msg = "pick"
      }
      else {
        msg = "ship"
      }     
    }
    else if (status === "DELIVERED") {
      msg = "deliver"
    }
    else if (status === "UNCONFIRMED") {
      msg = "Revert Confirm"
    }
    else if (status === "UNREADYTOPICK") {
      msg = "Revert Ready to Pick"
    }
    else if (status === "UNPICKED") {
      msg = "Revert Pick"
    }
    else if (status === "UNDELIVERED") {
      msg = "Revert Deliver"
    }

    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to ' + msg + ' this order?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnOrderStatus(status)
          //   this.fnConfirmOrder(status)
        }
      }
      );
  }

  //ConfirmOrder
  fnConfirmOrder() {

    if (this.orderBO.restauranttype === 'RESTAURANT') {
      const modalRef = this.productFindService.open(OrderassignComponent, { size: <any>'m' });
      modalRef.componentInstance.orderBO = this.orderBO;
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Confirm this order?')
          .then((confirmed) => {
            if (confirmed) {
              this.fnSetOrderStatusDp('CONFIRMED', receivedEntry.deliverypartnerid, receivedEntry.trackno)
            }
          }
          );
      });
    }
    else {

      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Confirm this order?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnSetOrderStatusDp('CONFIRMED', null, null)
          }
        }
        );
    }
  }
  fnPickOrder() {

    if (this.orderBO.restauranttype === 'RESTAURANT') {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Pick this order?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnSetOrderStatusDp('PICKED', null, null)
        }
      }
      );
    }
    else {
      const modalRef = this.productFindService.open(OrderassignComponent, { size: <any>'m' });
      modalRef.componentInstance.orderBO = this.orderBO;
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        console.log(receivedEntry)
        this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Pack this order?')
          .then((confirmed) => {
            if (confirmed) {
            
              this.fnSetOrderStatusDp('PICKED', receivedEntry.deliverypartnerid, receivedEntry.trackno)
            }
          }
          );
      });
       
    }
  }

  fnSetOrderStatusDp(info, deliverypartnerid,trackno) {
    var data = {
      'orderid': this.orderid,
      'statustype': info,
      'deliverypartnerid': deliverypartnerid,
      'trackno': trackno,
      'modifiedby': this.loginService.fnGetModUserId(),
    }

    this.appService.setOrderStatus(data).subscribe((res) => {
      if (res.json().status === 200) {

        window.location.reload()

      }
    })
  }

  //CancelOrder
  fnCancelOrderConfirmation() {

    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to cancel this order?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnCancelOrderStatus()
        }
      }
      );
  }

  fnCancelOrderStatus() {
    var data = {
      'orderid': this.orderid,
      'cancelldesc': '',
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.setCancelOrderAdmin(data).subscribe((res) => {
      if (res.json().status === 200) {
        var result = res.json().result;
        if (result.length > 0) {
          var resBO = result[0];
          if (resBO.checkresult === "DONE") {
            window.location.reload()
          }
          else if (resBO.checkresult === "FAILED") {
            this.toastr.warning('', "Already confirmed this order.");
            window.location.reload()
          }

        }


      }
    })
  }

  //Close
  fnCloseOrder() {
    try {
      this.router.navigate(['/Order']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Print
  fnPrintOrder() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT ORDER";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintOrder();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      // window.location.reload()      
    });

  }

  fnViewCustomerLocation() {
    const modalRef = this.productFindService.open(OrdermaplocationComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.latitude = this.orderBO.customerlatitude;
    modalRef.componentInstance.longitude = this.orderBO.customerlongitude;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {



    });

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
