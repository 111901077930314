import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { LoginComponent } from './components/login/login.component';

import { ProductgroupComponent } from './components/productgroup/productgroup.component';
import { AddproductgroupComponent } from './components/productgroup/addproductgroup/addproductgroup.component';
import { CuisineComponent } from './components/cuisine/cuisine.component';
import { AddcuisineComponent } from './components/cuisine/addcuisine/addcuisine.component';
import { CompanyComponent } from './components/company/company.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { AddrestaurantComponent } from './components/restaurant/addrestaurant/addrestaurant.component';
import { SplcategoryComponent } from './components/splcategory/splcategory.component';
import { AddsplcategoryComponent } from './components/splcategory/addsplcategory/addsplcategory.component';
import { EstablishtypeComponent } from './components/establishtype/establishtype.component';
import { AddestablishtypeComponent } from './components/establishtype/addestablishtype/addestablishtype.component';
import { ProducttagtypeComponent } from './components/producttagtype/producttagtype.component';
import { AddproducttagtypeComponent } from './components/producttagtype/addproducttagtype/addproducttagtype.component';
import { FoodsymbolComponent } from './components/foodsymbol/foodsymbol.component';
import { AddfoodsymbolComponent } from './components/foodsymbol/addfoodsymbol/addfoodsymbol.component';
import { CategoryComponent } from './components/category/category.component';
import { AddcategoryComponent } from './components/category/addcategory/addcategory.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { AddsubcategoryComponent } from './components/subcategory/addsubcategory/addsubcategory.component';
import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { TaxationComponent } from './components/taxation/taxation.component';
import { AddtaxationComponent } from './components/taxation/addtaxation/addtaxation.component';
import { ProductComponent } from './components/product/product.component';
import { AddproductComponent } from './components/product/addproduct/addproduct.component';

import { TaxgroupComponent } from './components/taxgroup/taxgroup.component';
import { AddtaxgroupComponent } from './components/taxgroup/addtaxgroup/addtaxgroup.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';
import { CustomerComponent } from './components/customer/customer.component';
import { AddcustomerComponent } from './components/customer/addcustomer/addcustomer.component';
import { CartComponent } from './components/cart/cart.component';
import { AddcartComponent } from './components/cart/addcart/addcart.component';
import { BrandComponent } from './components/brand/brand.component';
import { AddbrandComponent } from './components/brand/addbrand/addbrand.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { AdddishesComponent } from './components/dishes/adddishes/adddishes.component';
import { PaymentmodeComponent } from './components/paymentmode/paymentmode.component';
import { AddpaymentmodeComponent } from './components/paymentmode/addpaymentmode/addpaymentmode.component';
import { DeliverypartnerComponent } from './components/deliverypartner/deliverypartner.component';
import { AdddeliverypartnerComponent } from './components/deliverypartner/adddeliverypartner/adddeliverypartner.component';
import { PageComponent } from './components/page/page.component';
import { AddpageComponent } from './components/page/addpage/addpage.component';
import { OrderComponent } from './components/order/order.component';
import { AddorderComponent } from './components/order/addorder/addorder.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AddnotificationComponent } from './components/notification/addnotification/addnotification.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { AddcouponComponent } from './components/coupon/addcoupon/addcoupon.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddsettingsComponent } from './components/settings/addsettings/addsettings.component';
import { DeliverychargesComponent } from './components/deliverycharges/deliverycharges.component';
import { AdddeliverychargesComponent } from './components/deliverycharges/adddeliverycharges/adddeliverycharges.component';
import { OfferComponent } from './components/offer/offer.component';
import { AddofferComponent } from './components/offer/addoffer/addoffer.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { AreaComponent } from './components/area/area.component';
import { AddareaComponent } from './components/area/addarea/addarea.component';
import { SalessummaryComponent } from './components/report/salessummary/salessummary.component';
import { SalesdetailsComponent } from './components/report/salesdetails/salesdetails.component';
import { BulkproductComponent } from './components/product/bulkproduct/bulkproduct.component';
import { OrderitemsummaryComponent } from './components/report/orderitemsummary/orderitemsummary.component';
import { AssignorderlistComponent } from './components/order/assignorderlist/assignorderlist.component';
import { OsproductlistComponent } from './components/product/osproductlist/osproductlist.component';
import { FailedorderlistComponent } from './components/order/failedorderlist/failedorderlist.component';
import { FailedorderComponent } from './components/failedorder/failedorder.component';
import { ViewfailedorderComponent } from './components/failedorder/viewfailedorder/viewfailedorder.component';
import { HomeComponent } from './components/home/home.component';
import { DailyappviewerComponent } from './components/report/dailyappviewer/dailyappviewer.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Dashboard', component: DashboardComponent },

  { path: 'Login', component: LoginComponent },

  { path: 'ProductGroup', component: ProductgroupComponent },
  { path: 'AddProductGroup', component: AddproductgroupComponent },
  { path: 'Cuisine', component: CuisineComponent },
  { path: 'AddCuisine', component: AddcuisineComponent },
  { path: 'Company', component: CompanyComponent },
  { path: 'AddCompany', component: AddcompanyComponent },
  { path: 'Restaurant', component: RestaurantComponent },
  { path: 'AddRestaurant', component: AddrestaurantComponent },
  { path: 'Splcategory', component: SplcategoryComponent },
  { path: 'AddSplcategory', component: AddsplcategoryComponent },
  { path: 'Establishtype', component: EstablishtypeComponent },
  { path: 'AddEstablishtype', component: AddestablishtypeComponent },
  { path: 'Producttagtype', component: ProducttagtypeComponent },
  { path: 'AddProducttagtype', component: AddproducttagtypeComponent },
  { path: 'Foodsymbol', component: FoodsymbolComponent },
  { path: 'AddFoodsymbol', component: AddfoodsymbolComponent },
  { path: 'Category', component: CategoryComponent },
  { path: 'AddCategory', component: AddcategoryComponent },
  { path: 'Subcategory', component: SubcategoryComponent },
  { path: 'AddSubcategory', component: AddsubcategoryComponent },
  { path: 'Uom', component: UomComponent },
  { path: 'AddUom', component: AdduomComponent },
  { path: 'User', component: UserComponent },
  { path: 'AddUser', component: AdduserComponent },
  { path: 'Taxation', component: TaxationComponent },
  { path: 'AddTaxation', component: AddtaxationComponent },
  { path: 'Product', component: ProductComponent },
  { path: 'AddProduct', component: AddproductComponent },
  { path: 'ProductBulk', component: BulkproductComponent },
  { path: 'OutOfProductList', component: OsproductlistComponent },
  { path: 'Taxgroup', component: TaxgroupComponent },
  { path: 'AddTaxgroup', component: AddtaxgroupComponent },
  { path: 'Userrole', component: UserroleComponent },
  { path: 'AddUserrole', component: AdduserroleComponent },
  { path: 'AddCustomer', component: AddcustomerComponent },
  { path: 'Customer', component: CustomerComponent },
  { path: 'Cart', component: CartComponent },
  { path: 'AddCart', component: AddcartComponent },
  { path: 'Brand', component: BrandComponent },
  { path: 'AddBrand', component: AddbrandComponent },
  { path: 'Dishes', component: DishesComponent },
  { path: 'AddDishes', component: AdddishesComponent },
  { path: 'Paymentmode', component: PaymentmodeComponent },
  { path: 'AddPaymentmode', component: AddpaymentmodeComponent },
  { path: 'Deliverypartner', component: DeliverypartnerComponent },
  { path: 'AddDeliverypartner', component: AdddeliverypartnerComponent }, 
  { path: 'Page', component: PageComponent },
  { path: 'AddPage', component: AddpageComponent },
  { path: 'Order', component: OrderComponent },
  { path: 'AddOrder', component: AddorderComponent },
  { path: 'FailedOrderList', component: FailedorderComponent },
  { path: 'AddFailedOrder', component: ViewfailedorderComponent },
  { path: 'AssignOrderList', component: AssignorderlistComponent },
  { path: 'Notification', component: NotificationComponent },
  { path: 'AddNotification', component: AddnotificationComponent },
  { path: 'Coupon', component: CouponComponent },
  { path: 'AddCoupon', component: AddcouponComponent },
  { path: 'Settings', component: SettingsComponent },
  { path: 'AddSettings', component: AddsettingsComponent },
  { path: 'Deliverycharges', component: DeliverychargesComponent },
  { path: 'AddDeliverycharges', component: AdddeliverychargesComponent },
  { path: 'Offer', component: OfferComponent },
  { path: 'AddOffer', component: AddofferComponent },
  { path: 'Approvals', component: ApprovalsComponent },
  { path: 'City', component: CityComponent },
  { path: 'AddCity', component: AddcityComponent },
  { path: 'Area', component: AreaComponent },
  { path: 'AddArea', component: AddareaComponent },
  { path: 'SalesSummaryReport', component: SalessummaryComponent },
  { path: 'SalesDetailsReport', component: SalesdetailsComponent },
  { path: 'OrderItemReport', component: OrderitemsummaryComponent },
  { path: 'DailyAppViewerReport', component: DailyappviewerComponent },
]; 


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
