import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { AddestablishmentpopupComponent } from '../../generalpopup/addestablishmentpopup/addestablishmentpopup.component';
@Component({
  selector: 'app-selectestablishment',
  templateUrl: './selectestablishment.component.html',
  styleUrls: ['./selectestablishment.component.css']
})
export class SelectestablishmentComponent implements OnInit {


  establishmentid: number = 0;
  establishmentColl: any = [];

  @Input() restaurantestablishmentcoll: any = [];
  @Input() restaurantid: any = 0;
  @Output() emitData = new EventEmitter();

  constructor(private toastr: ToastrService, private appService: DhukanDataService, private productFindService: NgbModal,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {

  }

  //Get 
  fngetEstablishmente() {
    var data = {
      'establishtypeid': 0,
    }
    this.appService.getEstablishtype(data)
      .subscribe(
        (res) => {
          this.establishmentColl = res.json().result;



        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnUpdateEstablishment() {
    this.emitData.emit(this.restaurantestablishmentcoll);
    this.activeModal.close(true);
  }


  checkValue(values: any, index) {
    this.restaurantestablishmentcoll[index].ischeck = values.currentTarget.checked;
  }

 //AddCuisine
 private fnAddEstablishment() {

  const modalRef = this.productFindService.open(AddestablishmentpopupComponent, { size: <any>'m' });
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    this.fngetEstablishmente();
  });
}
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
