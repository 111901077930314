import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addcategorypopup',
  templateUrl: './addcategorypopup.component.html',
  styleUrls: ['./addcategorypopup.component.css']
})
export class AddcategorypopupComponent implements OnInit{

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  categoryColl:any=[];
 categoryid:number=0
  
  //pageColl:any=[];
  
  categoryform=new FormGroup({        
    categoryid:new FormControl(0),         
    categoryname:new FormControl('',Validators.required),   
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Category";


  @Input() categoryname: any = '';
  @Output() emitData = new EventEmitter();

  constructor(private toastr: ToastrService, private appService: DhukanDataService,private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
      this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.categoryid = params.id
        }  
       });
       this.fnServiceChanges();
  }

  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.categoryform.reset();
         this.fnInitializeCategory(); 
  
         if(this.categoryid>0){
           this.fngetCategory();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.categoryid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeCategory(){
     this.categoryform.setValue({
       
      categoryid:0, 
      categoryname:'',    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetCategory() {        
       var data = {
         'categoryid':this.categoryid,    
         'restaurantid':0,        
       }
       this.appService.getCategory(data)
       .subscribe(
         (res)=>{                  
           this.categoryColl=res.json().result;
           var editdata =this.categoryColl[0];
    
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.categoryform.valid){
           //Check
           var data = {
                 'categoryid': this.categoryid,                   
                 'categoryname': this.categoryform.get('categoryname').value,                 
                 'restaurantid':this.loginService.fnGetModRestaruntId(),
                 'inactive':this.categoryform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckCategory(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                     
                         //Insert Department
                         this.fnInsertCategory(data);

                 }
                 else{        
                       if(_checkresult==="name"){
                         this.categoryform.controls['categoryname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
   //Insert
   fnInsertCategory(data) {

    this.appService.fnInsertCategory(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var categoryid = 0;
          if (result.length > 0) {
            categoryid = result[0].categoryid
          }


          this.toastr.success('', "The new Category has been added successfully.Insert Successfully");
          this.emitData.emit(categoryid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}