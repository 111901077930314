import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pagelayoutimage',
  templateUrl: './pagelayoutimage.component.html',
  styleUrls: ['./pagelayoutimage.component.css']
})
export class PagelayoutimageComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  layouttypeColl: any = [];


  linktypeColl: any = [];
  cuisineColl: any = [];
  pageColl: any = [];
  dishesColl: any = [];
  brandColl: any = [];
  restaurantColl: any = [];
  productColl: any = [];
  cityColl: any = [];

  @Input() websiteimagepath: string = '';
  @Input() mobileimagepath: string = '';

  @Input() arwebsiteimagepath: string = '';
  @Input() armobileimagepath: string = '';

  //set image
  @Input() image;
  @Input() strImage: any;
  @Input() mobileimage;
  @Input() mobilestrImage: any;

  @Input() arimage;
  @Input() arstrImage: any;
  @Input() armobileimage;
  @Input() armobilestrImage: any;

  @Input() linktype: string = '';
  @Input() linktargetid: any = 0;
  @Input() linktargetfilter: string = '';

  @Input() cuisineid: any = 0;
  @Input() pageid: any = 0;
  @Input() productgroupid: any = 0;
  @Input() contentid: any = 0;
  @Input() offerid: any = 0;
  @Input() productid: any = 0;
  @Input() restaurantid: any = 0;
  @Input() dishesid: any = 0;
  @Input() productname: string = '';
  @Input() orderbyid: number = null;
  @Input() pagerestaurantid: number = null;

  @Input() paddingleft: number = 0;
  @Input() paddingright: number = 0;
  @Input() paddingtop: number = 0;
  @Input() paddingbottom: number = 0;


  @Input() pagecityid: any = '';

  
  @Input() paddingleftweb:number =0;  
  @Input() paddingrightweb:number =0;  
  @Input() paddingtopweb:number =0;  
  @Input() paddingbottomweb:number =0;  

  layouttypesubmitted: boolean;
  layouttypesuccessfully: boolean;


  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  ngOnInit() {
    this.fngetLinkType();
    this.fngetCity();
  }




  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddPageLayoutImages() {
    this.layouttypesubmitted = true;
    this.layouttypesuccessfully = false;
    //Validate



    var _req = {
      websiteimagepath: this.websiteimagepath,
      websiteimage: this.strImage,
      websiteimageload: this.image,
      mobileimagepath: this.mobileimagepath,
      mobileimage: this.mobilestrImage,
      mobileimageload: this.mobileimage,
      arwebsiteimagepath: this.arwebsiteimagepath,
      arwebsiteimage: this.arstrImage,
      arwebsiteimageload: this.arimage,
      armobileimagepath: this.armobileimagepath,
      armobileimage: this.armobilestrImage,
      armobileimageload: this.armobileimage,
      linktype: this.linktype,
      linktargetid: this.fnGetLinkTypeId(this.linktype),
      linktargetfilter: this.linktargetfilter,
      targetproductname: this.productname,
      orderbyid: this.orderbyid,
      paddingleft: this.paddingleft,
      paddingright: this.paddingright,
      paddingtop: this.paddingtop,
      paddingbottom: this.paddingbottom,
      pagecityid: this.fnGetCitiesId(),
      paddingleftweb:this.paddingleftweb,      
      paddingrightweb:this.paddingrightweb,      
      paddingtopweb:this.paddingtopweb,      
      paddingbottomweb:this.paddingbottomweb,
    }

    this.emitData.emit(_req);
    this.activeModal.close(true);



  }



  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {

    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.websiteimagepath = "";
  }


  //Mobile Image Import
  mobilechangeListener($event): void {
    try {
      this.mobilereadThis($event.target);
    } catch (ex) {

    }
  }

  mobilereadThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.mobileimage = myReader.result;
      this.mobilestrImage = this.mobileimage.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  mobileremoveimage() {
    this.mobileimage = null;
    this.mobilestrImage = null;
    this.mobileimagepath = "";
  }




  //Ar Image Import
  archangeListener($event): void {
    try {
      this.arreadThis($event.target);
    } catch (ex) {

    }
  }

  arreadThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.arimage = myReader.result;
      this.arstrImage = this.arimage.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  arremoveimage() {
    this.arimage = null;
    this.arstrImage = null;
    this.arwebsiteimagepath = "";
  }


  //AR Mobile Image Import
  armobilechangeListener($event): void {
    try {
      this.armobilereadThis($event.target);
    } catch (ex) {

    }
  }

  armobilereadThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.armobileimage = myReader.result;
      this.armobilestrImage = this.armobileimage.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  armobileremoveimage() {
    this.armobileimage = null;
    this.armobilestrImage = null;
    this.armobileimagepath = "";
  }



  fngetLinkType() {
    let _type = [
      { display: "Cuisine", name: "CUISINE" },
      { display: "Brand", name: "BRAND" },
      { display: "Dishes", name: "DISHES" },
      { display: "Restaurant", name: "RESTAURANT" },
      { display: "RestaurantProduct", name: "RESTAURANTPRODUCT" },
      { display: "Page", name: "PAGE" },
      { display: "Offer", name: "OFFER" },
      { display: "ProductGroup", name: "PRODUCTGROUP" },
      { display: "Html", name: "HTML" },
      { display: "WebUrl", name: "WEBURL" },
      { display: "Offers Page", name: "OFFERS" },
      { display: "Newarrivals Page", name: "NEWARRIVALS" },
      { display: "Dealoftheday Page", name: "DEALOFTHEDAY" },
      { display: "Fresh Page", name: "FRESH" },
      { display: "Retail Page", name: "RETAIL" },
      { display: "Native Page", name: "NATIVE" },
      { display: "Restaurant Page", name: "RESTAURANTPAGE" },
    ]

    this.linktypeColl = _type;
  }




  fngetCity() {

    var data = {
      'cityid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {


          if (res.json().status == 200) {
            this.cityColl = res.json().result;
            this.fnApplyCityCheck();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }




  fngetCuisine() {

    var data = {
      'cuisineid': 0,
      'deptid': 0,
    }


    this.appService.getCuisine(data)
      .subscribe(
        (res) => {
          this.cuisineColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetDishes() {

    var data = {
      'dishesid': 0,
      'deptid': 0,
    }


    this.appService.getDishes(data)
      .subscribe(
        (res) => {
          this.dishesColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetBrand() {

    var data = {
      'brandid': 0,
      'deptid': 0,
    }


    this.appService.getBrand(data)
      .subscribe(
        (res) => {
          this.brandColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetRestaurant() {

    var data = {
      'restaurantid': 0,
      'deptid': 0,
    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {
          this.restaurantColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }

  fngetRestaurantProduct() {
    this.productColl = [];

    if (this.restaurantid > 0) {
      var data = {
        'restaurantid': this.restaurantid,
        'deptid': 0,
      }
      this.appService.getProductListview(data)
        .subscribe(
          (res) => {
            this.productColl = res.json().result;

          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }

  }

  fngetPage() {

    var data = {
      'pageid': 0
    }


    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.pageColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }





  fnLinkTypeChange() {
    let type = this.linktype;

    if (type === "CUISINE") {
      this.fngetCuisine();
    }
    else if (type === "PAGE") {
      this.fngetPage();
    }
    else if (type === "DISHES") {
      this.fngetDishes();
    }
    else if (type === "BRAND") {
      this.fngetBrand();
    }
    else if (type === "RESTAURANT") {
      this.fngetRestaurant();
    }
    else if (type === "RESTAURANTPRODUCT") {
      this.fngetRestaurant();

      if (this.pagerestaurantid > 0) {
        this.restaurantid = this.pagerestaurantid
        this.fngetRestaurantProduct();
      }

    }


  }

  fnSelectProduct() {

    // const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
    // modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

    // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    //   for(let wbo of receivedEntry){

    //     //this.linktargetid=wbo.productid;
    //     // this.bannerform.patchValue({ 
    //        this.productid=wbo.skuid;        
    //        this.productname=wbo.productname;
    //     // });

    //   }
    // });

  }

  checkValue(values: any, index) {
    this.cityColl[index].ischeck = values.currentTarget.checked;
  }

  fnGetLinkTypeId(type) {
    if (type === "CUISINE") {
      return this.cuisineid;
    }
    else if (type === "DISHES") {
      return this.dishesid;
    }
    else if (type === "RESTAURANT") {
      return this.restaurantid;
    }
    else if (type === "PAGE") {
      return this.pageid;
    }
    else if (type === "RESTAURANTPRODUCT") {
      return this.productid;
    }
    else if (type === "OFFER") {
      return this.offerid;
    }
    else if (type === "PRODUCTGROUP") {
      return this.productgroupid;
    }
    else if (type === "HTML") {
      return this.contentid;
    }
    else {
      return null;
    }
  }

  fnGetCitiesId() {
    var _check = "";

    for (let abo of this.cityColl) {
      if (abo.ischeck === true) {
        if (_check === "") {
          _check = abo.cityid;
        }
        else {
          _check = _check + "," + abo.cityid;
        }
      }
    }

    return _check;

  }

  fnApplyCityCheck() {
  

    if (this.pagecityid) {
      let aColl = this.pagecityid.split(",")
    
      for (let abo of aColl) {
        for (let pbo of this.cityColl) {
        
          if (pbo.cityid === Number(abo)) {
            pbo.ischeck = true;
          }
        }
      }
    }
  }

  fnApplyallBtnClick(){
    for (let pbo of this.cityColl) {
      pbo.ischeck = true;
    }
  }

  fnUnCheckBtnClick(){
    for (let pbo of this.cityColl) {
      pbo.ischeck = false;
    }
  }

}
