import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit, AfterViewInit, OnDestroy {

  approvalsColl: any = [];
  restaurantColl: any = [];
  companyColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Approvals";


  approvals:string="COMPANY";







  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetRestaurant();
    this.fngetComapany();
    this.fnGetApprovals();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }
  fnGetApprovals() {
    this.approvalsColl=[];
    this.approvalsColl.push({ value: 'COMPANY', displayname: 'Merchant' });
    this.approvalsColl.push({ value: 'RESTAURANT', displayname: 'Restaurant' });
 
  }

  fngetRestaurant() {
   
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'restaurantid': 0,
      'filterby': "INACTIVE",
    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.restaurantColl = res.json().result;
        
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fngetComapany() {
   
    var data = {
      'companyid': 0,
      'filterby':"INACTIVE",
    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          console.log(res)
          this.fnHideProgress();
          if (res.json().status == 200) {

            this.companyColl = res.json().result;
            console.log(this.companyColl)
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnSetApprovedCompany(index) {
   
    var data = {
      'companyid':  this.companyColl[index].companyid,
      'isapproved': this.companyColl[index].isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetCompanyApproved(data).subscribe((res) => {
      if (res.json().result === 200) {
        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }



  fnSetApprovedRestaurant(index) {
    var data = {
      'restaurantid': this.restaurantColl[index].restaurantid,
      'isapproved': this.restaurantColl[index].isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetRestaurantApproved(data).subscribe((res) => {
      if (res.json().result === 200) {
        
        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }
  selectAllRestaurant(){
    for(let row of this.restaurantColl){
      row.isapproved=true;
    }
   }
   selectAllCompany(){
    for(let row of this.companyColl){
      row.isapproved=true;
    }
   }
   fnApplyAllRestaurant(){
   
    for (let i = 0; i < this.restaurantColl.length; i++) {
     this.fnSetApprovedRestaurant(i);
     window.location.reload()
  }
   }
   fnApplyAllCompany(){
   
    for (let i = 0; i < this.companyColl.length; i++) {
     this.fnSetApprovedCompany(i);
     window.location.reload()
  }
   }
  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnViewCompany(index) {
 
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id':this.companyColl[index].companyid,
        }
      }

      this.router.navigate(['/AddCompany'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
