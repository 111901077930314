import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliverypartnermaplocationComponent } from '../deliverypartnermaplocation/deliverypartnermaplocation.component';
@Component({
  selector: 'app-adddeliverypartner',
  templateUrl: './adddeliverypartner.component.html',
  styleUrls: ['./adddeliverypartner.component.css']
})
export class AdddeliverypartnerComponent implements OnInit{

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  show: boolean;

  pattern1 =  "^[0-9_-]{10,12}";
  //Edit Get Values
  deliverypartnerColl: any = [];
  deliverypartnerid: number = 0
  deliverypartnerBO: any = [];

  cityColl: any = [];
  deliverypartnerorderColl: any = [];
  //pageColl:any=[];

  deliverypartnerform = new FormGroup({
    deliverypartnerid: new FormControl(0),
    deliverypartnername: new FormControl('', Validators.required),
    deliverypartnercode: new FormControl(null, Validators.required),
    mobileno:  new FormControl('',Validators.required),
    pwd:new FormControl(''),
    assignedarea:new FormControl(''),
    isapproved: new FormControl(false),
    cityid: new FormControl(null, Validators.required),
    deliverypartnerimage: new FormControl(null),
    inactive: new FormControl(false),
  });

 
  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Deliverypartner"; 
   
  

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,private productFindService: NgbModal,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.deliverypartnerid = params.id
      }
    });
    this.fnServiceChanges();
  
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.deliverypartnerform.reset();
      this.fnInitializeDeliverypartner();

      if (this.deliverypartnerid > 0) {
        this.fngetDeliverypartner();
      }

      this.fngetCity();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.deliverypartnerid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeDeliverypartner() {
    this.deliverypartnerform.setValue({

      deliverypartnerid: 0,
      deliverypartnername: '',
      deliverypartnercode:'new',
      mobileno: '',
      pwd: '',
      assignedarea: '',
      isapproved: false,
      deliverypartnerimage: null,
      cityid:null,
      inactive: false,
    })
  }





  fngetCity() {


    this.fnShowProgress();
    var data = {
      'brandid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.cityColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetDeliverypartner() {
    var data = {
      'deliverypartnerid': this.deliverypartnerid,
    }
    this.appService.getDeliverypartner(data)
      .subscribe(
        (res) => {
          this.deliverypartnerColl = res.json().result[0];
          var editdata = this.deliverypartnerColl[0];
          this.deliverypartnerBO = editdata
          this.fnEditMode(editdata);
        
          
          this.deliverypartnerorderColl= res.json().result[1];
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.deliverypartnerform.valid) {
        //Check
        var data = {
          'deliverypartnerid': this.deliverypartnerid,
          'deliverypartnername': this.deliverypartnerform.get('deliverypartnername').value,
          'deliverypartnercode': this.deliverypartnerform.get('deliverypartnercode').value,
          'mobileno': this.deliverypartnerform.get('mobileno').value,
          'pwd': this.deliverypartnerform.get('pwd').value,
          'assignedarea': this.deliverypartnerform.get('assignedarea').value,
          'isapproved': this.deliverypartnerform.get('isapproved').value,
          'cityid': this.deliverypartnerform.get('cityid').value,      
          deliverypartnerimage: this.strImage,
          picurl: this.picurl,    
          'inactive': this.deliverypartnerform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckDeliverypartner(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.deliverypartnerform.get('deliverypartnerid').value > 0) {
                //Update Department
                this.fnSetDeliverypartner(data);
              }
              else {
                //Insert Department
                this.fnInsertDeliverypartner(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.deliverypartnerform.controls['mobileno'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertDeliverypartner(data) {

    this.appService.fnInsertDeliverypartner(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Deliverypartner has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.deliverypartnername);

          this.fnCloseDeliverypartner();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetDeliverypartner(data) {

    this.appService.fnSetDeliverypartner(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Deliverypartner has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.deliverypartnerid, data.deliverypartnername);

          this.fnCloseDeliverypartner();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  fnSetApproved() {
    var data = {
      'deliverypartnerid': this.deliverypartnerid,
      'isapproved': this.deliverypartnerBO.isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetDeliverypartnerApproved(data).subscribe((res) => {
      window.location.reload()
      if (res.json().result === 200) {
        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }
  //Close
  fnCloseDeliverypartner() {
    try {
      this.router.navigate(['/Deliverypartner']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  
 
 fnEditOrder(orderid){
  try{
      let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': orderid,
      }
      }

    this.router.navigate(['/AddOrder'], navigationExtras);
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}



fnViewDeliveryPartnerLocation() {
  const modalRef = this.productFindService.open(DeliverypartnermaplocationComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
  modalRef.componentInstance.deliverypartnerid = this.deliverypartnerBO.deliverypartnerid;
  modalRef.componentInstance.latitude = this.deliverypartnerBO.latitude;
  modalRef.componentInstance.longitude = this.deliverypartnerBO.longitude;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    
    

  });

}

fnEditBtnClick(){

  this.iseditmode = true;
 
  this.isviewmode = false;
}
  private fnEditMode(deptBO) {

    this.deliverypartnerform.setValue({
      deliverypartnerid: deptBO.deliverypartnerid,
      deliverypartnername: deptBO.deliverypartnername,
      deliverypartnercode: deptBO.deliverypartnercode,
      mobileno: deptBO.mobileno,
      pwd: deptBO.pwd,
      assignedarea: deptBO.assignedarea,
      isapproved: deptBO.isapproved,
      cityid: deptBO.cityid,
      deliverypartnerimage:null,
      inactive: deptBO.inactive,
    });
    this.picurl = deptBO.picurl;

    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;
  }

  fnShowpassword(event) {
 
    this.show = !this.show;
  }


  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
