import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Time } from '@angular/common';

@Component({
  selector: 'app-printorder',
  templateUrl: './printorder.component.html',
  styleUrls: ['./printorder.component.css']
})
export class PrintorderComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() orderHTML: string = '';

  @Input() orderid: number = 0;
  @Input() deliveryorderid: number = 0;

  @Input() excepteddt: Date = null;
  @Input() exceptedtime: Time = null;

  pordermainBO: any = {};
  porderdetailsColl: any = [];
  pordersummaryBO: any = {};
  pordertaxColl: any = [];
  porderpayBO: any = {};
  pordercanceldetailsColl: any = [];


  pproductmainBO: any = {};
  pproductdetailsColl: any = [];
  pproductsummaryBO: any = {};
  pproductdeptColl: any = [];


  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService, private loginService: LoginDataService) { }

  ngOnInit() {
  }



  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  public fnPrintApplyHTML() {


    document.getElementById("print-section").innerHTML = this.orderHTML;
    //this.emitData.emit("");  
    //this.activeModal.close(true); 

  }


  // Print Chrome
  fnPrintInvoice() {


    let printContents, popupWin;

    if (this.doctype === 'PRINT PICKLIST') {
      printContents = document.getElementById('product_print-section').innerHTML;
    }
    else if (this.doctype === 'PRINT INVOICE') {
      printContents = document.getElementById('print-section-invoice').innerHTML;
    }
    else if (this.doctype === 'PRINT ORDER') {
      printContents = document.getElementById('print-section').innerHTML;
    }
    else if (this.doctype === 'PRINT DOSLIP') {
      printContents = document.getElementById('print-section-doslip').innerHTML;
    }


    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<body onload="window.print();window.close()">${printContents}</body>`);

    //popupWin.document.close();
  }


  public fnPrintPickList() {
    var data = {
      'orderid': this.orderid,
    }
    this.appService.fnGetPrintProductOrder(data)
      .subscribe(
        (res) => {
          var _res = res.json().result;

          this.pproductmainBO = _res[0][0];
          this.pproductdetailsColl = _res[1];
          this.pproductsummaryBO = _res[2][0];
          this.pproductdeptColl = _res[3];


        },
        (err) => {
          console.log('Error', err);
        })
  }

  public fnPrintOrder() {
    var data = {
      'orderid': this.orderid,
    }
    this.appService.fnGetPrintOrder(data)
      .subscribe(
        (res) => {
          console.log(res.json())
          var _res = res.json().result;
          


          this.pordermainBO = _res[0][0];
          this.porderdetailsColl = _res[1];
          this.pordertaxColl = _res[2];
          this.pordersummaryBO = _res[3][0];
          this.porderpayBO = _res[4][0];
          this.pordercanceldetailsColl = _res[5];


        },
        (err) => {
          console.log('Error', err);
        })
  }

  public fnPrintOrderInvoice() {
    var data = {
      'orderid': this.orderid,
    }
    this.appService.fnGetPrintInvoiceOrder(data)
      .subscribe(
        (res) => {
          var _res = res.json().result;

          this.pordermainBO = _res[0][0];
          this.porderdetailsColl = _res[1];
          this.pordertaxColl = _res[2];
          if (_res[3].length) {
            this.pordersummaryBO = _res[3][0];
          }
          if (_res[4].length) {
            this.porderpayBO = _res[4][0];
          }
          this.pordercanceldetailsColl = _res[5];
        },
        (err) => {
          console.log('Error', err);
        })
  }

  public fnPrintDeliveryOrderSlip() {
    var data = {
      'deliveryorderid': this.deliveryorderid,
    }
    this.appService.fnGetPrintDeliveryOrder(data)
      .subscribe(
        (res) => {
          var _res = res.json().result;

          this.pordermainBO = _res[0][0];
          this.porderdetailsColl = _res[1];
          this.pordertaxColl = _res[2];
          if (_res[3].length) {
            this.pordersummaryBO = _res[3][0];
          }
          if (_res[4].length) {
            this.porderpayBO = _res[4][0];
          }
          this.pordercanceldetailsColl = _res[5];
        },
        (err) => {
          console.log('Error', err);
        })
  }


}
