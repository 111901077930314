import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';


import * as XLSX from 'xlsx'
@Component({
  selector: 'app-importproduct',
  templateUrl: './importproduct.component.html',
  styleUrls: ['./importproduct.component.css']
})
export class ImportproductComponent implements OnInit {

  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  excelColl: any = [];

  ngOnInit() {
  }
  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      type NewType = XLSX.WorkSheet;

      const ws: NewType = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      this.excelColl = data;

    };
  }



  fnSaveBtnClick() {
    let _dataColl=[];


    for (let row of this.excelColl) {
      var data = {
        restaurantcode: row.restaurantcode,
        skucode: row.skucode,
        productname: row.productname,
        categoryname: row.categoryname,
        subcategoryname: row.subcategoryname,
        uom: row.uom,
        uomqty: row.uomqty,
        taxgroup: row.taxgroup,
        price: row.price,
        foodsymbol: row.foodsymbol,
        specialcategory: row.specialcategory,
        producttag: row.producttag,    
        descr: row.descr,
        modifiedby: this.loginService.fnGetModUserId(),
      }

      _dataColl.push(data)     
    }

    this.fnInsertExcelImport(_dataColl)    

  }


  fnInsertExcelImport(_dataColl) {
    
    var data = {
      filename:'jjj',
      restaurantid:this.loginService.fnGetModRestaruntId(),
      modifiedby: this.loginService.fnGetModUserId(),
      productColl:_dataColl,
    }
    this.appService.fnInsertImportProduct(data).subscribe((res) => {
      console.log(res.json())
      if (res.json().status === 200) {
        
          this.toastr.success('', " The Product import successfully.");                 
          this.activeModal.close(false);
       

      }
    },
      (err) => {
        this.toastr.error('Error', err);
      });
  }
  public decline() {
    this.activeModal.close(false);
  }

}
