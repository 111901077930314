import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addcompany',
  templateUrl: './addcompany.component.html',
  styleUrls: ['./addcompany.component.css']
})
export class AddcompanyComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  restaurantcompanyColl: any = [];
  //Edit Get Values
  companyColl: any = [];
  companyid: number = 0

  companyBO: any = [];
  restaurantColl: any = [];
  //pageColl:any=[];

  companyform = new FormGroup({
    companyid: new FormControl(0),
    //productgroup:new FormControl('',Validators.required),            
    companyname: new FormControl('', Validators.required),
    companycode: new FormControl('', Validators.required),
    address: new FormControl(''),
    isapproved: new FormControl(false),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Company";



  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.companyid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.companyform.reset();
      this.fnInitializeCompany();

      if (this.companyid > 0) {
        this.fngetCompany();
        this.fngetRestaurant();
      }
      this.fngetRestaurantCompany();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.companyid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeCompany() {
    this.companyform.setValue({

      companyid: 0,
      companyname: '',
      companycode: 'New',
      address: '',
      isapproved: false,
      inactive: false,
    })
  }

  //Get 
  fngetRestaurant() {
    var data = {
      'companyid': this.companyid,

    }

    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {
          var result = res.json().result;
          this.restaurantColl = result;
          // console.log(result[1])
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetCompany() {
    var data = {
      'companyid': this.companyid,

    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          this.companyColl = res.json().result;
          var editdata = this.companyColl[0];
          this.companyBO = editdata
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetRestaurantCompany() {
    var data = {
      'companyid': this.companyid,

    }

    this.appService.getRestaurantCompany(data)
      .subscribe(
        (res) => {
          var result = res.json().result;
          this.restaurantcompanyColl = result;
          console.log(this.restaurantcompanyColl)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.companyform.valid) {
        //Check
        var data = {
          'companyid': this.companyid,
          'companyname': this.companyform.get('companyname').value,
          'companycode': this.companyform.get('companycode').value,
          'address': this.companyform.get('address').value,
          'isapproved': this.companyform.get('isapproved').value,
          'inactive': this.companyform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckCompany(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.companyform.get('companyid').value > 0) {
                //Update Department
                this.fnSetCompany(data);
              }
              else {
                //Insert Department
                this.fnInsertCompany(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.companyform.controls['companyname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertCompany(data) {

    this.appService.fnInsertCompany(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Company has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.companyname);

          this.fnCloseCompany();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetCompany(data) {

    this.appService.fnSetCompany(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The company has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.companyid, data.companyname);

          this.fnCloseCompany();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  fnSetApproved() {
    var data = {
      'companyid': this.companyid,
      'isapproved': this.companyBO.isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetCompanyApproved(data).subscribe((res) => {
      window.location.reload()
      if (res.json().result === 200) {
        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }


  
  //Close
  fnCloseCompany() {
    try {
      this.router.navigate(['/Company']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnEditBtnClick() {
    this.iseditmode = true;

    this.isviewmode = false;
  }

  private fnEditMode(deptBO) {

    this.companyform.setValue({

      companyid: deptBO.companyid,
      companyname: deptBO.companyname,
      companycode: deptBO.companycode,
      address: deptBO.address,
      isapproved: deptBO.isapproved,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
