import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addsplcategory',
  templateUrl: './addsplcategory.component.html',
  styleUrls: ['./addsplcategory.component.css']
})
export class AddsplcategoryComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  public imagePath;
  imgURL: any;
  public message: string;

  //Edit Get Values
  splcategoryColl: any = [];
  splcategoryid: number = 0

  //pageColl:any=[];

  splcategoryform = new FormGroup({
    splcategoryid: new FormControl(0),     
    splcategoryname: new FormControl('', Validators.required),
    splcategoryimage: new FormControl(null),     
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Splcategory";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.splcategoryid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.splcategoryform.reset();
      this.fnInitializeSplCategory();

      if (this.splcategoryid > 0) {
        this.fngetSplCategory();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.splcategoryid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeSplCategory() {
    this.splcategoryform.setValue({

      splcategoryid: 0,
      splcategoryname: '',
      splcategoryimage: null,    
      inactive: false,
    })
  }




  //Get 
  fngetSplCategory() {
    var data = {
      'splcategoryid': this.splcategoryid,
    }
    this.appService.getSplcategory(data)
      .subscribe(
        (res) => {
          this.splcategoryColl = res.json().result;
          var editdata = this.splcategoryColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.splcategoryform.valid) {
        //Check
        var data = {
          'splcategoryid': this.splcategoryid,
          'splcategoryname': this.splcategoryform.get('splcategoryname').value,
          splcategoryimage: this.strImage,
          picurl: this.picurl,
          'inactive': this.splcategoryform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckSplcategory(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.splcategoryform.get('splcategoryid').value > 0) {
                //Update Department
                this.fnSetSplCategory(data);
              }
              else {
                //Insert Department
                this.fnInsertSplCategory(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.splcategoryform.controls['splcategoryname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertSplCategory(data) {

    this.appService.fnInsertSplcategory(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new SplCategory has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.splcategoryname);

          this.fnCloseSplCategory();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetSplCategory(data) {

    this.appService.fnSetSplcategory(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The SplCategory has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.splcategoryid, data.splcategoryname);

          this.fnCloseSplCategory();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseSplCategory() {
    try {
      this.router.navigate(['/Splcategory']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.splcategoryform.setValue({

      splcategoryid: deptBO.splcategoryid,
      splcategoryname: deptBO.splcategoryname,
      splcategoryimage: null,
      inactive: deptBO.inactive,
    });
    this.picurl = deptBO.picurl;

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
