import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestaurantfindComponent } from '../../grid/restaurantfind/restaurantfind.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-addoffer',
  templateUrl: './addoffer.component.html',
  styleUrls: ['./addoffer.component.css']
})
export class AddofferComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  pageColl: any = [];

  //Edit Get Values
  offerColl: any = [];
  offerid: number = 0
  offerdetColl: any = [];

  offerBO: any = {};
  offertypeColl: any = [];
  //pageColl:any=[];

  offerform = new FormGroup({
    offerid: new FormControl(0),
    offercode: new FormControl('', Validators.required),
    offername: new FormControl('', Validators.required),
    offerdisplayname: new FormControl(''),
    offertype: new FormControl(''),
    offerfrom: new FormControl(null, Validators.required),
    offerto: new FormControl(null, Validators.required),
    offerfromtime: new FormControl(null),
    offertotime: new FormControl(null),
    offerperc: new FormControl(null),
    seourlname: new FormControl(''),
    pageid: new FormControl(null),
    inactive: new FormControl(false),
  });


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Offer";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private productFindService: NgbModal, private confirmationDialogService: ConfirmationDialogService,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.offerid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.offerform.reset();
      this.fnInitializeOffer();

      if (this.offerid > 0) {
        this.fngetOffer();
      }
      this.fngetPage();
      this.fnGetOfferType()

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.offerid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeOffer() {
    this.offerform.setValue({

      offerid: 0,
      offername: '',
      offercode: 'New',
      offerdisplayname: '',
      offertype: '',
      offerfrom: null,
      offerto: null,
      offerfromtime: null,
      offertotime: null,
      offerperc: 0,
      seourlname: '',
      pageid: null,
      inactive: false,
    })
  }

  fnGetOfferType() {
    this.offertypeColl.push({ value: 'WEEKEND OFFER', displayname: 'Weekend Offer' });

  }
  fngetPage() {
    var data = {
      'pageid': 0,
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.pageColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetOffer() {
    var data = {
      'offerid': this.offerid,
    }
    this.appService.getOffer(data)
      .subscribe(
        (res) => {

          console.log(res.json().result);

          this.offerColl = res.json().result[0];
          var editdata = this.offerColl[0];
          this.fnEditMode(editdata);
          this.offerBO = editdata;
          this.offerdetColl = res.json().result[1];
          console.log(res.json().result)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.offerform.valid) {
        //Check
        var data = {
          'offerid': this.offerid,
          'offercode': this.offerform.get('offercode').value,
          'offername': this.offerform.get('offername').value,
          'offerdisplayname': this.offerform.get('offerdisplayname').value,
          'offertype': this.offerform.get('offertype').value,
          'offerfrom': this.fnFormatDatetime(this.offerform.get('offerfrom').value),
          'offerto': this.fnFormatDatetime(this.offerform.get('offerto').value),
          'offerfromtime': this.offerform.get('offerfromtime').value,
          'offertotime': this.offerform.get('offertotime').value,
          'offerperc': this.offerform.get('offerperc').value,
          'seourlname': this.offerform.get('seourlname').value,
          'pageid': this.offerform.get('pageid').value,
          'inactive': this.offerform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'offerdetColl': this.offerdetColl,
        }
        this.appService.fnCheckOffer(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.offerform.get('offerid').value > 0) {
                //Update Department
                this.fnSetOffer(data);
              }
              else {
                //Insert Department
                this.fnInsertOffer(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.offerform.controls['offername'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertOffer(data) {

    this.appService.fnInsertOffer(data).subscribe(
      (res) => {
        if (res.json().result == 200) {
          this.toastr.success('', "The new Offer has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.offername);

          this.fnCloseOffer();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetOffer(data) {

    this.appService.fnSetOffer(data).subscribe(
      (res) => {
        if (res.json().status == 200) {
          this.toastr.success('', "The Offer has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.offerid, data.offername);

          this.fnCloseOffer();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  fnRestaurantProduct() {

    const modalRef = this.productFindService.open(RestaurantfindComponent, { size: <any>'xl' });

    modalRef.componentInstance.offerdetColl = this.offerdetColl;


    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      for (let row of receivedEntry) {
        this.offerdetColl.push(row);

      }
    });
  }
  fnDeletefnRestaurantDetailsConfirm(index) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete details?')
        .then((confirmed) => {
          if (confirmed) {
            this.offerdetColl.splice(index, 1)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnRestaurantDetails(index) {



  }

  //Close
  fnCloseOffer() {
    try {
      this.router.navigate(['/Offer']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {
    deptBO.offerfrom = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.offerfrom));
    deptBO.offerto = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.offerto));
    this.offerform.setValue({
      offerid: deptBO.offerid,
      offercode: deptBO.offercode,
      offername: deptBO.offername,
      offerdisplayname: deptBO.offerdisplayname,
      offertype: deptBO.offertype,
      offerfrom: deptBO.offerfrom,
      offerto: deptBO.offerto,
      offerfromtime: deptBO.offerfromtime,
      offertotime: deptBO.offertotime,
      offerperc: deptBO.offerperc,
      seourlname: deptBO.seourlname,
      pageid: deptBO.pageid,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
