import { Time } from '@angular/common';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addrestaurantslot',
  templateUrl: './addrestaurantslot.component.html',
  styleUrls: ['./addrestaurantslot.component.css']
})
export class AddrestaurantslotComponent implements OnInit {

  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  
  @Input() slotid: number = 0;
  @Input() restaurantid: number = 0;
  @Input() starttime: any = null;
  @Input() endtime: any = null;
  @Input() cutoftime: any = null;
  @Input() inactive:  number = 0;
 
  slotform=new FormGroup({
    restaurantid:new FormControl(0),      
    starttime:new FormControl(null,Validators.required),    
    endtime:new FormControl(null,Validators.required),      
    cutoftime:new FormControl(null,Validators.required),         
    inactive:new FormControl(false),      
  });
  
   //Save Validation
   submitted:boolean;
  successfully: boolean;
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress: boolean = false;
  
  
  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService,
    private loginService:LoginDataService,private toastr: ToastrService) { }

  ngOnInit() {
    if (this.slotid > 0) {
        this.fngetRestaurantSlot()
      }
  }

  public fnInitializeProductFormGroup(){
    this.slotform.patchValue({   
      restaurantid:this.restaurantid,   
      //  starttime:this.starttime,
      //  endtime:this.endtime,         
      //  cutoftime:this.cutoftime,   
      //  inactive:this.inactive,        
    })


 }

 fngetRestaurantSlot() {
  var data = {
    'slotid': this.slotid,
  }
  this.appService.fnGetRestaurantSlot(data)
    .subscribe(
      (res) => {

        var result = res.json().result;
        
        this.fnEditMode(result[0]);

      },
      (err) => {
        this.toastr.error('Error', err);
      })
 }
 public fnEditMode(abo){
  this.slotform.setValue({   
     restaurantid:abo.restaurantid,   
     starttime:abo.starttime,
     endtime:abo.endtime,         
     cutoftime:abo.cutoftime,   
     inactive:abo.inactive,        
  })

  console.log(this.slotform.get('starttime').value)

}

  
  
  //Save Btn Click
  fnSaveBtnClick(){
  
    try{
      this.submitted=true;
      this.successfully=false;
 
 
      //Validate
      if(this.slotform.valid){        
             
         this.fnShowProgress();
                 
          //Check
        var data = {
                'slotid': this.slotid,
                'restaurantid': this.restaurantid,       
                'starttime':this.slotform.get('starttime').value,              
                'endtime':this.slotform.get('endtime').value,                
                'cutoftime':this.slotform.get('cutoftime').value,    
                'inactive': this.slotform.get('inactive').value,    
                'createdby': this.loginService.fnGetModUserId(),    
                'modifiedby':this.loginService.fnGetModUserId(),                  
          }
          this.emitData.emit(data);  
          this.activeModal.close(true);  
       }
    }catch(ex){


      this.fnHideProgress();      
      this.fnShowErrorMsg(ex);
    }
 }
 
  
  public decline() {
    this.activeModal.dismiss();
  }

  
  


  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
    console.log(ex)
    this.toastr.warning('Internal Error', ex);       
  }
  



}
