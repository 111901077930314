import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-customeraddress',
  templateUrl: './customeraddress.component.html',
  styleUrls: ['./customeraddress.component.css']
})
export class CustomeraddressComponent implements OnInit {

  addresstypeColl: any = [];

  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isduplicate: boolean;

  customeraddressform = new FormGroup({
    addressid: new FormControl(0),
    customerid: new FormControl(null, Validators.required),
    addressnickname: new FormControl('', Validators.required),
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    mobile: new FormControl(''),
    houseno: new FormControl('', Validators.required),
    street: new FormControl(''),
    landmark: new FormControl('', Validators.required),
    isdefault: new FormControl(false),
    inactive: new FormControl(false),
    latitude: new FormControl(null),
    longitude: new FormControl(null),
    maplocationname: new FormControl(''),
    addressothername: new FormControl(''),
  });



  @Input() addressid: any = 0;
  @Input() customerid: any = null;
  @Input() addressnickname: any = '';
  @Input() firstname: any = '';
  @Input() lastname: any = '';
  @Input() mobile: any = '';
  @Input() houseno: any = '';
  @Input() street: any = '';
  @Input() landmark: any = '';
  @Input() isdefault: any = false;
  @Input() inactive: any = false;
  @Input() latitude: any = null;
  @Input() longitude: any = null;
  @Input() maplocationname: any = '';
  @Input() addressothername: any = '';

  @Output() emitData = new EventEmitter();

  mapType = 'roadmap';
  
  ngOnInit() {

    this.customeraddressform.reset();
    this.fnInitializeFormGroup();

    this.fnAddressType();
  }

  fnAddressType() {
    this.addresstypeColl=[]
    this.addresstypeColl.push({value:'HOME',displayname:'Home'})
    this.addresstypeColl.push({value:'OFFICE',displayname:'Office'})
    this.addresstypeColl.push({value:'OTHERS',displayname:'Others'})
  }

  fnInitializeFormGroup() {
    this.customeraddressform.setValue({
      addressid: this.addressid,
      customerid: this.customerid,
      addressnickname: this.addressnickname,
      firstname: this.firstname,
      lastname: this.lastname,
      mobile: this.mobile,
      houseno: this.houseno,
      street: this.street,
      landmark: this.landmark,
      isdefault: this.isdefault,
      inactive: this.inactive,
      latitude: this.latitude,
      longitude: this.longitude,
      maplocationname: this.maplocationname,
      addressothername: this.addressothername,
    })
  }


  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.customeraddressform.valid) {

        //Check
        var data = {
          addressid: this.addressid,
          customerid: this.customeraddressform.get('customerid').value,
          addressnickname: this.customeraddressform.get('addressnickname').value,
          firstname: this.customeraddressform.get('firstname').value,
          lastname: this.customeraddressform.get('lastname').value,
          mobile: this.customeraddressform.get('mobile').value,
          houseno: this.customeraddressform.get('houseno').value,
          street: this.customeraddressform.get('street').value,
          landmark: this.customeraddressform.get('landmark').value,
          isdefault: this.customeraddressform.get('isdefault').value,
          inactive: this.customeraddressform.get('inactive').value,
          latitude: this.customeraddressform.get('latitude').value,
          longitude: this.customeraddressform.get('longitude').value,
          maplocationname: this.customeraddressform.get('maplocationname').value,
          addressothername: this.customeraddressform.get('addressothername').value,
        }
if(this.addressid>0){
  this.fnSetCustomerAddress(data)
}
 else{
  this.fnInsertCustomerAddress(data);
 }       
 window.location.reload()
        this.activeModal.close(true);
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
fnInsertCustomerAddress(data){
this.appService.fnInsertCustomerAddressAdmin(data).subscribe((res)=>{
  if(res.json().result===200){
    
  }
})
}
fnSetCustomerAddress(data){
  this.appService.fnSetCustomerAddressAdmin(data).subscribe((res)=>{
   
    if(res.json().result===200){
      
    }
  })
  }
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
