import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { AddcuisinepopupComponent } from '../../generalpopup/addcuisinepopup/addcuisinepopup.component';
@Component({
  selector: 'app-selectcuisine',
  templateUrl: './selectcuisine.component.html',
  styleUrls: ['./selectcuisine.component.css']
})
export class SelectcuisineComponent implements OnInit {

  cusineid:number=0;
  cuisineColl:any=[];

  @Input() restaurantcuisinecoll:any=[];
  @Input() restaurantid: any = 0;
  @Output() emitData = new EventEmitter();
  constructor(private toastr: ToastrService, private appService: DhukanDataService,private productFindService: NgbModal,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
  
  }

   //Get 
   fngetCuisine() {
    var data = {
      'cuisineid': 0,
    }
    this.appService.getCuisine(data)
      .subscribe(
        (res) => {
          this.cuisineColl = res.json().result;
      
          

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

 
fnUpdateCuisine(){
  this.emitData.emit(this.restaurantcuisinecoll);
  this.activeModal.close(true);
}


checkValue(values: any, index) {
  this.restaurantcuisinecoll[index].ischeck = values.currentTarget.checked;
}

 //AddCuisine
 private fnAddCuisine() {

  const modalRef = this.productFindService.open(AddcuisinepopupComponent, { size: <any>'m' });
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    this.fngetCuisine();
  });
}
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
