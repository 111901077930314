import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmfailedorderComponent } from '../confirmfailedorder/confirmfailedorder.component';

@Component({
  selector: 'app-viewfailedorder',
  templateUrl: './viewfailedorder.component.html',
  styleUrls: ['./viewfailedorder.component.css']
})
export class ViewfailedorderComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;


  //Edit Get Values
  cartBO: any ={};
  cartdetColl: any = [];
  cartid: number = 0

  //pageColl:any=[];
  paymentmodeColl: any = [];

  
  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Cart";

  constructor(private appService: DhukanDataService,private productFindService: NgbModal, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.cartid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
   
      if (this.cartid > 0) {
        this.fngetCartdetails();
        this.fngetPaymentMode();
      }
      

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.cartid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  




  //Get 
  fngetCartdetails() {
    var data = {
      'cartid': this.cartid,
      
    }

    this.appService.getCartDetails(data)
      .subscribe(
        (res) => {
          
          var result=res.json().result;
          if(result.length>0){
            this.cartBO=result[0][0]
            this.cartdetColl = result[1]
          }
        
        
        
        
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetPaymentMode() {
    var data = {
    
    }

    this.appService.getPaymentmode(data)
      .subscribe(
        (res) => {
          
         this.paymentmodeColl=res.json().result;
               
        
        
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  fnConfirmOrder() {
    const modalRef = this.productFindService.open(ConfirmfailedorderComponent, { size: <any>'m' });
    modalRef.componentInstance.cartBO = this.cartBO;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      console.log(receivedEntry) 
      this.fnSetConfirmOrderAdmin(receivedEntry.orderid,receivedEntry.paymentid)     
    });
     
  }


  
  fnSetConfirmOrderAdmin(orderid,paymentid){
    var data = {
      'orderid':orderid,
      'paymentid': paymentid,
      'reforderno':this.cartBO.reforderno,
    }

    this.appService.setOrderConfimrFailed(data)
      .subscribe(
        (res) => {
          
          var result=res.json().result;
          if(result.length>0){
           this.fnInsertOrderAdmin(orderid,paymentid)
          }       
          else {
            this.toastr.error('', "PaymentId inccorect");
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  fnInsertOrderAdmin(orderid,paymentid){
    var data = {
      'sessionid': this.cartBO.sessionid,
      'customerid': this.cartBO.customerid,
      'paymodeid': this.fnGetOnlinePayment(),
      'onlineorderid':orderid,
      'onlinepaymentid': paymentid,
      'ipaddress':''
    }

    this.appService.getCartDetails(data)
      .subscribe(
        (res) => {
          if(res.json().status==200){
            this.toastr.success('', "The Order has been updated successfully.");
            this.fnCloseCart();
          }
                     
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Close
  fnCloseCart() {
    try {
      this.router.navigate(['/FailedOrderList']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  
  fnGetOnlinePayment(){
    var paymentmodeid=0

    for(let abo of this.paymentmodeColl){
      if (abo.paymenttype === 'ONLINE') {
        paymentmodeid=abo.paymentmodeid;
      }
    }

    return paymentmodeid

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
