import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { AppSettings } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {

  public islogin:boolean=false;
  
  serviceloginChange: Subject<boolean> = new Subject<boolean>();


  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  constructor(private http: Http, public router: Router) { }


  fnpostInputParams(url, params) {
    const headers = new Headers({    
     // 'Content-Type':  "application/JSON",
      'token': (localStorage.exwtoken === undefined) ? '' : JSON.parse(localStorage.exwtoken),
      'restoken': (localStorage.restoken === undefined) ? '' : JSON.parse(localStorage.restoken)                
    });

    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }
  
  


  //Get UserRole List
  fnGetUserRoleList(){    
    var data = {
      'userid':0,                          
    }

    this.fnpostInputParams(AppSettings.getUserRoleRightsUserUrl,data)
    .subscribe(
      (res)=>{               
      
        if(res.json().status==200){  
          var _res =res.json().result; 

          if(_res.length){
            

            if(_res[0].length>0){
              this.userBO=_res[0][0];
            }
            else{
              this.userBO={};
            }

            this.userrolerightsColl = [];

            //this.userrolerightsColl=_res[1];
          }


          this.userView={
              userBO:this.userBO,
              userrolerightsColl:this.userrolerightsColl
          }
          
          console.log(this.userView)
          this.serviceloginChange.next( this.userView);
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          

        this.fnShowErrorMsg('Error'+ err);
      })

  }

      
  

  fnCheckUser(){
    
    if( this.userBO.userid){
      return true;
    }
    // else if(localStorage.adtoken !== undefined){
    //   return true;
    // }
    else{
      return false;
    }

  }

  fnGetModUserId(){
    if( this.userBO.userid){
      return this.userBO.userid;
    }
    else{
      return 0;
    }
  }


  fnGetModRestaruntId(){
    if( this.userBO.restaurantid){
      return this.userBO.restaurantid;
    }
    else{
      return 0;
    }
  }


  fnUserFaildRedirect(){
    if(!this.userBO){
      this.router.navigate(['/Login']);
    }
  }

  fnCheckAdminUser(){
    
    if( this.userBO.userid){
      return this.userBO.isadmin;
    }
    else{
      return 0;
    }
  }


  fnCheckUserRoleAll(formname){
    
    if( this.userrolerightsColl){

      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname  ); 
      if(index>-1){ 
          var _pBO=this.userrolerightsColl[index];
         
          if(_pBO.uappcreate || _pBO.uappalter || _pBO.uappdelete  || _pBO.uappview || _pBO.uappprint || _pBO.uappexport ){
            return true;
          }
          else{
            return false;
          }
      }
    }else{
      return false;
    }
  }
  
  fnCheckUserRoleAction(formname,action){
    return true;
    
    if( this.userrolerightsColl){

      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname  ); 
      if(index>-1){         
          var _pBO=this.userrolerightsColl[index];

          if(action==='CREATE' && _pBO.uappcreate){
            return true;
          }
          else if(action==='ALTER' && _pBO.uappalter){
            return true;
          }
          else if(action==='DELETE' && _pBO.uappdelete){
            return true;
          }
          else if(action==='VIEW' && _pBO.uappview){
            return true;
          }
          else if(action==='PRINT' && _pBO.uappprint){
            return true;
          }
          else if(action==='EXPORT' && _pBO.uappexport){
            return true;
          }
          else{
            return false;
          }
      }
      else{
        return false;
      }
      
    }else{
      return false;
    }
  }
  fnDecimalBlur(event) {    
    if (event.target.value !== '') {
     
      event.target.value = parseFloat(event.target.value).toFixed(2)
 
    }
  }

  fnCheckFornControlNotfound(fc) {
    let ischeck = false;
    const validator = fc;
      
    if (validator.errors) {
      if (validator.errors.notfound) {
        ischeck = true;
      }         
    }

    return ischeck;
  }  
 private  fnShowErrorMsg(ex){
  console.log(ex);       
}



}
