import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pagelayout',
  templateUrl: './pagelayout.component.html',
  styleUrls: ['./pagelayout.component.css']
})
export class PagelayoutComponent implements OnInit {
  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  layouttypeColl:any=[];

  @Input() layouttypeid: number = null;
  @Input() orderno: string = '';
  @Input() headername: string = '';
  @Input() subheadername: string = '';
  
  @Input() websiteimagepath:string = '';  
  @Input() mobileimagepath:string = ''; 
  

  //set image
  @Input() image;
  @Input() strImage: any;
  @Input() mobileimage;
  @Input() mobilestrImage: any;
  
  
  linktypeColl:any=[];
  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  vendorColl:any=[];
  pageColl:any=[];
  offerColl:any=[];
  productgroupColl:any=[];
  contentColl:any=[];

  @Input() linktype:string = '';  
  @Input() linktargetid:any = 0;  
  @Input() linktargetfilter:string = '';  

  @Input() deptid:any = 0;  
  @Input() categoryid:any = 0;  
  @Input() subcategoryid:any = 0;  
  @Input() brandid:any = 0;  
  @Input() vendorid:any = 0; 
  @Input() offerid:any = 0;  
  @Input() productgroupid:any = 0;  
  @Input() pageid:any = 0;  
  @Input() contentid:any = 0;  
  @Input() productid:any = 0;    
  @Input() productname:string = ''; 

  @Input() perrowweb:any = 0;  
  @Input() perrowmobile:any = 0;  


  @Input() layouttypename: string = '';

  @Input() skippaddingleft:boolean = false;  
  @Input() skippaddingright:boolean = false;  
  @Input() skippaddingtop:boolean = false;  
  @Input() skippaddingbottom:boolean = false;  

  @Input() isweb:boolean = false;  
  @Input() ismobile:boolean = false;  
  @Input() inactive:boolean  = false;   

  @Input() bannerheight:number =0;  

  @Input() paddingleft:number =0;  
  @Input() paddingright:number =0;  
  @Input() paddingtop:number =0;  
  @Input() paddingbottom:number =0;  

  @Input() backcolorcode: string = '';

  @Input() paddingleftweb:number =0;  
  @Input() paddingrightweb:number =0;  
  @Input() paddingtopweb:number =0;  
  @Input() paddingbottomweb:number =0;  

  layouttypesubmitted:boolean;
  layouttypesuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  ngOnInit() {
    this.fngetLayoutType();
    this.fngetLinkType();
  }
  
  
  fngetLayoutType() {        
    var data = {
      'layouttypeid':0,            
    }
    this.appService.getLayoutType(data)
    .subscribe(
      (res)=>{                  
        this.layouttypeColl=res.json().result;     

        console.log(res.json().result)
      },
    (err)=>{          
      //this.toastr.error('Error', err);
    })
  }

  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddPageLayout(){

    this.layouttypesubmitted=true;
    this.layouttypesuccessfully=false;
    //Validate
    if(this.layouttypeid >0){  

      const specindex = this.layouttypeColl.findIndex(sku => sku.layouttypeid === this.layouttypeid  );  
      
      var _req={        
        layouttypeid:this.layouttypeid,
        layouttype:this.layouttypeColl[specindex].layouttype,        
        noofimages:this.layouttypeColl[specindex].noofimages,  
        orderno:this.orderno,    
        headername:this.headername,    
        subheadername:this.subheadername,    
        websiteimagepath:this.websiteimagepath,
        websiteimage:this.strImage,      
        websiteimageload:this.image,        
        mobileimagepath:this.mobileimagepath, 
        mobileimage:this.mobilestrImage,
        mobileimageload:this.mobileimage,  
        linktype:this.linktype,    
        linktargetid:this.fnGetLinkTypeId(this.linktype),  
        perrowweb:this.perrowweb,    
        perrowmobile:this.perrowmobile,   
        skippaddingleft:this.skippaddingleft,   
        skippaddingright:this.skippaddingright,   
        skippaddingtop:this.skippaddingtop,   
        skippaddingbottom:this.skippaddingbottom, 
        isweb:this.isweb, 
        ismobile:this.ismobile, 
        inactive:this.inactive,
        linktargetfilter:this.linktargetfilter,
        bannerheight:this.bannerheight,
        targetproductname:this.productname,      
        paddingleft:this.paddingleft,      
        paddingright:this.paddingright,      
        paddingtop:this.paddingtop,      
        paddingbottom:this.paddingbottom,      
        backcolorcode: this.backcolorcode,  
        paddingleftweb:this.paddingleftweb,      
        paddingrightweb:this.paddingrightweb,      
        paddingtopweb:this.paddingtopweb,      
        paddingbottomweb:this.paddingbottomweb,
      }
  
      console.log(_req)
      this.emitData.emit(_req);  
      this.activeModal.close(true);  
    }


  }


  fnSetLayoutTypeName(){
    const specindex = this.layouttypeColl.findIndex(sku => sku.layouttypeid === this.layouttypeid  );  

    this.layouttypename=this.layouttypeColl[specindex].layouttype;
  }
  
//Image Import
changeListener($event): void {
  try{
    this.readThis($event.target);
  }catch(ex){
  
  }
}

readThis(inputValue: any): void {

  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
      this.image = myReader.result;           
      this.strImage = this.image.split(',')[1];      
  }
  myReader.readAsDataURL(file);
}

removeimage(){
    this.image =null;  
    this.strImage =null;
    this.websiteimagepath="";
}


//Mobile Image Import
mobilechangeListener($event): void {
  try{
    this.mobilereadThis($event.target);
  }catch(ex){
  
  }
}

mobilereadThis(inputValue: any): void {

var file: File = inputValue.files[0];
var myReader: FileReader = new FileReader();

myReader.onloadend = (e) => {
    this.mobileimage = myReader.result;    
    this.mobilestrImage = this.mobileimage.split(',')[1];    
}
myReader.readAsDataURL(file);
}

mobileremoveimage(){
  this.mobileimage =null;  
  this.mobilestrImage =null;
  this.mobileimagepath="";
}





fngetLinkType() { 
  let _type=[
    {display:"Department",name:"DEPARTMENT"},
    {display:"Category",name:"CATEGORY"},
    {display:"SubCategory",name:"SUBCATEGORY"},
    {display:"Brand",name:"BRAND"},
    {display:"Vendor",name:"VENDOR"},
    {display:"Page",name:"PAGE"},
    {display:"Product",name:"PRODUCT"},
    {display:"Offer",name:"OFFER"},
    {display:"ProductGroup",name:"PRODUCTGROUP"},
    {display:"Html",name:"HTML"},
    {display:"WebUrl",name:"WEBURL"},
    {display:"Offers Page",name:"OFFERS"},
    {display:"Newarrivals Page",name:"NEWARRIVALS"},
    {display:"Dealoftheday Page",name:"DEALOFTHEDAY"},
  ]

  this.linktypeColl=_type;
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}



fngetProductGroup() {        
 
  var data = {
    'productgroupid':0            
  }


    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fngetPage() {        
 
  var data = {
    'pageid':0            
  }


    this.appService.getPageByListView(data)
    .subscribe(
      (res)=>{                  
        this.pageColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}






fnLinkTypeChange(){
  let type=this.linktype;
  
 if(type==="CATEGORY"){
    this.fngetCategory();
  }
  
  else if(type==="BRAND"){
    this.fngetBrand();
  }
  
 
  else if(type==="PRODUCTGROUP"){
    this.fngetProductGroup();
  }
 
  else if(type==="PAGE"){
    this.fngetPage();
  }
}

fnSelectProduct(){
 
  // const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  // modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

  //   for(let wbo of receivedEntry){

  //     //this.linktargetid=wbo.productid;
  //     // this.bannerform.patchValue({ 
  //        this.productid=wbo.skuid;        
  //        this.productname=wbo.productname;
  //     // });

  //   }
  // });

}



fnGetLinkTypeId(type){
  
  if(type==="CATEGORY"){
    return this.categoryid;
  }
 
  else if(type==="BRAND"){
    return this.brandid;
  }
  else if(type==="PRODUCT"){
    return this.productid;
  } 
  
  else if(type==="OFFER"){
    return this.offerid;
  } 
  else if(type==="PRODUCTGROUP"){
    return this.productgroupid;
  } 
  else if(type==="HTML"){
    return this.contentid;
  } 
  else if(type==="PAGE"){
    return this.pageid;
  } 
  else {
    return null;
  }
}





}
