import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addnotification',
  templateUrl: './addnotification.component.html',
  styleUrls: ['./addnotification.component.css']
})
export class AddnotificationComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  public imagePath;
  imgURL: any;
  public message: string;

  notificationtypeColl: any = [];

  linktypeColl: any = [];
  cuisineColl: any = [];
  pageColl: any = [];
  dishesColl: any = [];
  brandColl: any = [];
  restaurantColl: any = [];
  productColl: any = [];

  //Edit Get Values
  notificationColl: any = [];
  notificationid: number = 0

  customerColl: any = [];
  deviceColl: any = [];

  notificationform = new FormGroup({
    notificationid: new FormControl(0),
    notificationname: new FormControl('', Validators.required),
    linktargetid: new FormControl(null),
    customerid: new FormControl(null),
    title: new FormControl('', Validators.required),
    notificationmsg: new FormControl(''),
    notificationtype: new FormControl(''),
    notificationimage: new FormControl(null),
    targettype: new FormControl(''),
    target: new FormControl(''),
    linktype: new FormControl(''),
    customimgpath: new FormControl(''),
    notificationmsgtype: new FormControl(''),
    refid: new FormControl(null),
    issend: new FormControl(false),

    inactive: new FormControl(false),
  });


  cuisineid: any = 0;
  pageid: any = 0;
  productgroupid: any = 0;
  contentid: any = 0;
  offerid: any = 0;
  productid: any = 0;
  restaurantid: any = 0;
  dishesid: any = 0;
  productname: string = '';
  orderbyid: number = null;
  pagerestaurantid: number = null;

  //set image
  image;
  strImage: any;
  notificationimgpath: string = "";
  picname: string = "";

  filterby: string = "GROUP";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Notification";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.notificationid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.notificationform.reset();
      this.fnInitializeNotification();

      if (this.notificationid > 0) {
        this.fngetNotification();
      }
      this.fngetCustomer();
      this.fnGetNotificationtype();
      this.fngetLinkType();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.notificationid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeNotification() {
    this.notificationform.setValue({

      notificationid: 0,
      notificationname: '',
      linktargetid: null,
      customerid: null,
      title: '',
      notificationmsg: '',
      notificationtype: '',
      notificationimage: null,
      targettype: '',
      target: '',
      linktype: '',
      customimgpath: '',
      notificationmsgtype: '',
      refid: null,
      issend: false,
      inactive: false,
    })
  }

  fnGetNotificationtype() {
    this.notificationtypeColl = [];
    this.notificationtypeColl.push({ name: 'INITIAL', displayname: 'Initial State' })
    this.notificationtypeColl.push({ name: 'EXPANDED', displayname: 'Expanded State' })

  }


  fngetCustomer() {
    var data = {
      'customerid': 0,
    }
    this.appService.getCustomer(data)
      .subscribe(
        (res) => {
          this.customerColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCustomerDevice() {
    this.deviceColl = [];

    if (this.notificationform.get('customerid').value > 0) {
      this.fnShowProgress();
      var data = {
        'customerid': this.notificationform.get('customerid').value,
      }
      this.appService.getCustomerDetails(data)
        .subscribe(
          (res) => {
            this.fnHideProgress();
            //console.log(res.json().result)
            this.deviceColl = res.json().result[3];

          },
          (err) => {
            this.fnHideProgress();
            this.toastr.error('Error', err);
          })

    }

  }

  fngetLinkType() {
    let _type = [
      { display: "Cuisine", name: "CUISINE" },
      { display: "Brand", name: "BRAND" },
      { display: "Dishes", name: "DISHES" },
      { display: "Restaurant", name: "RESTAURANT" },      
      { display: "Native", name: "NATIVEVIEW" },      
      { display: "Fresh", name: "FRESHVIEW" },      
      { display: "RestaurantProduct", name: "RESTAURANTPRODUCT" },
      { display: "NativeProduct", name: "NATIVEPRODUCT" },
      { display: "FreshProduct", name: "FRESHPRODUCT" },
      { display: "Page", name: "PAGE" },
      { display: "Offer", name: "OFFER" },
      { display: "ProductGroup", name: "PRODUCTGROUP" },
      { display: "Html", name: "HTML" },
      { display: "WebUrl", name: "WEBURL" },
      { display: "Offers Page", name: "OFFERS" },
      { display: "Newarrivals Page", name: "NEWARRIVALS" },
      { display: "Dealoftheday Page", name: "DEALOFTHEDAY" },
      { display: "Fresh Page", name: "FRESH" },
      { display: "Retail Page", name: "RETAIL" },
      { display: "Native Page", name: "NATIVE" },
      { display: "Restaurant Page", name: "RESTAURANTPAGE" },
    ]

    this.linktypeColl = _type;
  }



  fngetCuisine() {

    var data = {
      'cuisineid': 0,
      'deptid': 0,
    }


    this.appService.getCuisine(data)
      .subscribe(
        (res) => {
          this.cuisineColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetDishes() {

    var data = {
      'dishesid': 0,
      'deptid': 0,
    }


    this.appService.getDishes(data)
      .subscribe(
        (res) => {
          this.dishesColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetBrand() {

    var data = {
      'brandid': 0,
      'deptid': 0,
    }


    this.appService.getBrand(data)
      .subscribe(
        (res) => {
          this.brandColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }
  fngetRestaurant() {

    var data = {
      'restaurantid': 0,
      'deptid': 0,
    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {
          this.restaurantColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }

  fngetRestaurantProduct() {
    this.productColl = [];

    if (this.restaurantid > 0) {
      var data = {
        'restaurantid': this.restaurantid,
        'deptid': 0,
      }
      this.appService.getProductListview(data)
        .subscribe(
          (res) => {
            this.productColl = res.json().result;

          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }

  }

  fngetPage() {

    var data = {
      'pageid': 0
    }


    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.pageColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })

  }








  //Get 
  fngetNotification() {
    var data = {
      'notificationid': this.notificationid,
    }
    this.appService.getNotification(data)
      .subscribe(
        (res) => {
          this.notificationColl = res.json().result;
          var editdata = this.notificationColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick(type) {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.notificationform.valid) {
        //Check
        var data = {
          'notificationid': this.notificationid,
          'notificationname': this.notificationform.get('notificationname').value,
          //'linktargetid': this.notificationform.get('linktargetid').value,
          'customerid': this.notificationform.get('customerid').value,
          'title': this.notificationform.get('title').value,
          'notificationmsg': this.notificationform.get('notificationmsg').value,
          'notificationtype': this.notificationform.get('notificationtype').value,
          notificationimage: this.strImage,
          notificationimgpath: this.notificationimgpath,
          'targettype': this.notificationform.get('targettype').value,
          'target': this.notificationform.get('target').value,
          'linktype': this.notificationform.get('linktype').value,
          'linktargetid': this.fnGetLinkTypeId(this.notificationform.get('linktype').value),
          'customimgpath': this.notificationform.get('customimgpath').value,
          'notificationmsgtype': this.notificationform.get('notificationmsgtype').value,
          'refid': this.fnGetRefId(this.notificationform.get('linktype').value),
          'issend': this.notificationform.get('issend').value,
          'inactive': this.notificationform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'action': type,
        }
        this.appService.fnCheckNotification(data).subscribe(
          (res) => {

            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.notificationform.get('notificationid').value > 0) {
                //Update Notification
                this.fnSetNotification(data);
              }
              else {
                //Insert Notification
                this.fnInsertNotification(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.notificationform.controls['notificationname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertNotification(data) {

    this.appService.fnInsertNotification(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Notification has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.notificationname);

          this.fnCloseNotification();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetNotification(data) {

    this.appService.fnSetNotification(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          if (data.action === 'SAVE') {
            this.fnHideProgress();
            this.toastr.success('', "The new notification has been added successfully.Insert Successfully");


            this.fnCloseNotification();
          }
          else {
            this.fnSend();
          }

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseNotification() {
    try {
      this.router.navigate(['/Notification']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnSend() {
    this.fnShowProgress();
    var senddata = {
      'notificationid': this.notificationid,
    }
    this.appService.getNotification(senddata)
      .subscribe(
        (res) => {
          this.notificationColl = [];
          this.notificationColl = res.json().result;
          var editdata = this.notificationColl[0];

          console.log(editdata)
          this.fnSendNotificationMessage(editdata)

        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnSendNotificationMessage(data) {

    this.appService.fnSendNotification(data, this.deviceColl).subscribe(
      (res) => {

        if (res.json().result != "") {
          this.fnHideProgress();
          if (data.notificationid > 0) {
            this.toastr.success('', "The  notification has been update successfully");
          }
          else {
            this.toastr.success('', "The new notification has been added successfully.Insert Successfully");
          }

          this.fnInsertActivityLog('SENDNOTIFICATION', this.notificationid, data.title);

          this.fnCloseNotification();

        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });
  }


  private fnEditMode(deptBO) {

    this.notificationform.setValue({

      notificationid: deptBO.notificationid,
      notificationname: deptBO.notificationname,
      linktargetid: deptBO.linktargetid,
      customerid: deptBO.customerid,
      title: deptBO.title,
      notificationmsg: deptBO.notificationmsg,
      notificationtype: deptBO.notificationtype,
      notificationimage: null,
      targettype: deptBO.targettype,
      target: deptBO.target,
      linktype: deptBO.linktype,
      customimgpath: deptBO.customimgpath,
      notificationmsgtype: deptBO.notificationmsgtype,
      refid: deptBO.refid,
      issend: deptBO.issend,
      inactive: deptBO.inactive,
    });
    this.notificationimgpath = deptBO.notificationimgpath;

    this.fnSetLinkTypeId(deptBO.linktype, deptBO.linktargetid);

    this.fnSetProductId(deptBO.linktype, deptBO.refid);

    this.fngetCustomerDevice();

    this.fnLinkTypeChange();

    if (deptBO.customerid > 0) {
      this.filterby = 'CUSTOMER';
    }


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }







  fnLinkTypeChange() {
    let type = this.notificationform.get('linktype').value;

    if (type === "CUISINE") {
      this.fngetCuisine();
    }
    else if (type === "PAGE") {
      this.fngetPage();
    }
    else if (type === "DISHES") {
      this.fngetDishes();
    }
    else if (type === "BRAND") {
      this.fngetBrand();
    }
    else if (type === "RESTAURANT" || type === "NATIVEVIEW" || type === "FRESHVIEW") {
      this.fngetRestaurant();
    }
    else if (type === "RESTAURANTPRODUCT" || type === "NATIVEPRODUCT" || type === "FRESHPRODUCT") {
      this.fngetRestaurant();

      if (this.pagerestaurantid > 0) {
        this.restaurantid = this.pagerestaurantid
        this.fngetRestaurantProduct();
      }

    }

  }


  fnGetLinkTypeId(type) {
    if (type === "CUISINE") {
      return this.cuisineid;
    }
    else if (type === "DISHES") {
      return this.dishesid;
    }
    else if (type === "RESTAURANT" || type === "NATIVEVIEW" || type === "FRESHVIEW") {
      return this.restaurantid;
    }
    else if (type === "PAGE") {
      return this.pageid;
    }
    else if (type === "RESTAURANTPRODUCT" || type === "NATIVEPRODUCT" || type === "FRESHPRODUCT") {
      return this.restaurantid;
    }
    else if (type === "OFFER") {
      return this.offerid;
    }
    else if (type === "PRODUCTGROUP") {
      return this.productgroupid;
    }
    else if (type === "HTML") {
      return this.contentid;
    }
    else {
      return null;
    }
  }




  fnGetRefId(type) {
    if (type === "RESTAURANTPRODUCT" || type === "NATIVEPRODUCT" || type === "FRESHPRODUCT") {
      return this.productid;
    }

    else {
      return null;
    }
  }


  fnSetLinkTypeId(type, id) {
    if (type === "CUISINE") {
      this.cuisineid = id;
    }
    else if (type === "DISHES") {
      this.dishesid = id;
    }
    else if (type === "RESTAURANT" || type === "NATIVEVIEW" || type === "FRESHVIEW") {
      this.restaurantid = id;
    }
    else if (type === "PAGE") {
      this.pageid = id;
    }
    else if (type === "RESTAURANTPRODUCT" || type === "NATIVEPRODUCT" || type === "FRESHPRODUCT") {
      this.restaurantid = id;
    }
    else if (type === "OFFER") {
      this.offerid = id;
    }
    else if (type === "PRODUCTGROUP") {
      this.productgroupid = id;
    }
    else if (type === "HTML") {
      this.contentid = id;
    }
    else {
      return null;
    }
  }



  fnSetProductId(type, id) {
    if (type === "RESTAURANTPRODUCT"  || type === "NATIVEPRODUCT" || type === "FRESHPRODUCT") {
      this.productid = id;

      this.fngetRestaurantProduct();
    }

  }


  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.notificationimgpath = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }






  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
