import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addestablishmentpopup',
  templateUrl: './addestablishmentpopup.component.html',
  styleUrls: ['./addestablishmentpopup.component.css']
})
export class AddestablishmentpopupComponent implements OnInit {
  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  establishtypeColl:any=[];
  establishtypeid:number=0
  
  //pageColl:any=[];
  
  establishtypeform=new FormGroup({        
    establishtypeid:new FormControl(0),     
    //productgroup:new FormControl('',Validators.required),            
    establishtypename:new FormControl('',Validators.required),   
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="EstablishType";

  @Input() establishtypename: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.establishtypeid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.establishtypeform.reset();
         this.fnInitializeEstablishType(); 
  
         if(this.establishtypeid>0){
           this.fngetEstablishType();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.establishtypeid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeEstablishType(){
     this.establishtypeform.setValue({
       
      establishtypeid:0, 
      establishtypename:'',    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetEstablishType() {        
       var data = {
         'establishtypeid':this.establishtypeid,            
       }
       this.appService.getEstablishtype(data)
       .subscribe(
         (res)=>{                  
           this.establishtypeColl=res.json().result;
           var editdata =this.establishtypeColl[0];
         
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.establishtypeform.valid){
           //Check
           var data = {
                 'establishtypeid': this.establishtypeid,                   
                 'establishtypename':this.establishtypeform.get('establishtypename').value,                 
                 'inactive':this.establishtypeform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckEstablishtype(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                      
                   
                         //Insert Department
                         this.fnInsertEstablishType(data);
                   
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.establishtypeform.controls['establishtypename'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
     
     }
  }
  
  //Insert
  fnInsertEstablishType(data){    
   
     this.appService.fnInsertEstablishtype(data).subscribe(
       (res)=>{
        if (res.json().status === 200) {
          console.log(res.json())
          var result = res.json().result;
       
          var establishtypeid = 0;
          if (result.length > 0) {
            establishtypeid = result[0].establishtypeid
          }

          this.toastr.success('', "The new EstablishType has been added successfully.Insert Successfully");

          this.emitData.emit(establishtypeid);
          this.activeModal.close(true);


       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}