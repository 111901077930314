import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Subject, Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-deliverypartnermaplocation',
  templateUrl: './deliverypartnermaplocation.component.html',
  styleUrls: ['./deliverypartnermaplocation.component.css']
})
export class DeliverypartnermaplocationComponent implements OnInit {

  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  @Input() deliverypartnerid: any = 0;
  @Input() latitude: any = null;
  @Input() longitude: any = null;

  updateon: Date = new Date();

  @Output() emitData = new EventEmitter();

  mapType = 'roadmap';
  userLocationMarkerAnimation: string;

  subscription: Subscription;
  intervalId: number;

  ngOnInit() {
    const source = interval(2000);
    this.subscription = source.subscribe(val => this.fngetDeliverypartner());

  }
  ngOnDestroy(): void {   
    this.subscription && this.subscription.unsubscribe();
    clearInterval(this.intervalId);
  }
  
  
  mapReading() {
    this.userLocationMarkerAnimation = 'BOUNCE';
  }

  fngetDeliverypartner() {
    var data = {
      'deliverypartnerid': this.deliverypartnerid,
    }
    this.appService.getDeliverypartner(data)
      .subscribe(
        (res) => {
          var deliverypartnerColl = res.json().result[0];
          var editdata = deliverypartnerColl[0];
          this.latitude = editdata.latitude;
          this.longitude = editdata.longitude;
          this.updateon=editdata.locationupdateon
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
