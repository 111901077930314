import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppSettings } from './services/constants/constants';

//Additional 
//Model
import { NgbModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
//progress bar
import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//Toast Service
import { ToastrModule } from 'ngx-toastr';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Map
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
//EDITOR
import { CKEditorModule } from 'ngx-ckeditor';
//Table
import { DataTablesModule } from 'angular-datatables';
//Date Pikker
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
//Image Compress
import { NgxImageCompressService } from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max';
//Barcode
import { NgxBarcodeModule } from 'ngx-barcode';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Device Info
import { DeviceDetectorModule } from 'ngx-device-detector';
//Currency
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";

//Firebase
// import {AngularFireModule} from 'angularfire2'
// import {AngularFireDatabaseModule} from 'angularfire2/database'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

//Chart
import { NgxChartsModule } from '@swimlane/ngx-charts';

//Drag
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import { NgxEmojiPickerModule } from 'ngx-emoji-picker';

import { NgxMaskModule, IConfig } from 'ngx-mask'


import { NgxPaginationModule } from 'ngx-pagination'; 

import { Ng2SearchPipeModule } from 'ng2-search-filter';

//Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';

// service
import { DhukanDataService } from './services/dhukan/dhukan-data.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';


import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { LoginComponent } from './components/login/login.component';
import { DateFormatPipe } from './date-format.pipe';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { DisablecontrolDirective } from './disablecontrol.directive';
import { HttpcancelService } from './services/dhukan/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageHttpInterceptor } from './interceptor/managehttp.interceptor';
import { ProcessComponent } from './components/process/process.component';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore/angular-fire-firestore';
import { ProductgroupComponent } from './components/productgroup/productgroup.component';
import { AddproductgroupComponent } from './components/productgroup/addproductgroup/addproductgroup.component';
import { ImageformatterComponent } from './components/grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from './components/grid/buttonformatter/buttonformatter.component';
import { ActionformatterComponent } from './components/grid/actionformatter/actionformatter.component';

import { AddcuisineComponent } from './components/cuisine/addcuisine/addcuisine.component';
import { CuisineComponent } from './components/cuisine/cuisine.component';
import { CompanyComponent } from './components/company/company.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { AddrestaurantComponent } from './components/restaurant/addrestaurant/addrestaurant.component';
import { SplcategoryComponent } from './components/splcategory/splcategory.component';
import { AddsplcategoryComponent } from './components/splcategory/addsplcategory/addsplcategory.component';
import { EstablishtypeComponent } from './components/establishtype/establishtype.component';
import { AddestablishtypeComponent } from './components/establishtype/addestablishtype/addestablishtype.component';
import { ProducttagtypeComponent } from './components/producttagtype/producttagtype.component';
import { AddproducttagtypeComponent } from './components/producttagtype/addproducttagtype/addproducttagtype.component';
import { FoodsymbolComponent } from './components/foodsymbol/foodsymbol.component';
import { AddfoodsymbolComponent } from './components/foodsymbol/addfoodsymbol/addfoodsymbol.component';
import { CategoryComponent } from './components/category/category.component';
import { AddcategoryComponent } from './components/category/addcategory/addcategory.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { AddsubcategoryComponent } from './components/subcategory/addsubcategory/addsubcategory.component';
import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { TaxationComponent } from './components/taxation/taxation.component';
import { AddtaxationComponent } from './components/taxation/addtaxation/addtaxation.component';
import { ProductComponent } from './components/product/product.component';
import { AddproductComponent } from './components/product/addproduct/addproduct.component';
import { TaxgroupComponent } from './components/taxgroup/taxgroup.component';
import { AddtaxgroupComponent } from './components/taxgroup/addtaxgroup/addtaxgroup.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';
import { AddproductvariationComponent } from './components/product/addproductvariation/addproductvariation.component';
import { CustomerComponent } from './components/customer/customer.component';
import { AddcustomerComponent } from './components/customer/addcustomer/addcustomer.component';
import { ImportproductComponent } from './components/product/importproduct/importproduct.component';
import { CartComponent } from './components/cart/cart.component';
import { AddcartComponent } from './components/cart/addcart/addcart.component';
import { BrandComponent } from './components/brand/brand.component';
import { AddbrandComponent } from './components/brand/addbrand/addbrand.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { AdddishesComponent } from './components/dishes/adddishes/adddishes.component';
import { PaymentmodeComponent } from './components/paymentmode/paymentmode.component';
import { AddpaymentmodeComponent } from './components/paymentmode/addpaymentmode/addpaymentmode.component';
import { DeliverypartnerComponent } from './components/deliverypartner/deliverypartner.component';
import { AdddeliverypartnerComponent } from './components/deliverypartner/adddeliverypartner/adddeliverypartner.component';
import { PageComponent } from './components/page/page.component';
import { AddpageComponent } from './components/page/addpage/addpage.component';
import { PagelayoutorderComponent } from './components/page/pagelayoutorder/pagelayoutorder.component';
import { PageorderComponent } from './components/page/pageorder/pageorder.component';
import { PagelayoutComponent } from './components/pagelayout/pagelayout.component';
import { PagelayoutimageComponent } from './components/pagelayoutimage/pagelayoutimage.component';
import { RestaurantsaveasComponent } from './components/restaurant/restaurantsaveas/restaurantsaveas.component';
import { OrderComponent } from './components/order/order.component';
import { AddorderComponent } from './components/order/addorder/addorder.component';
import { OrderoptionComponent } from './components/order/orderoption/orderoption.component';
import { OrderassignComponent } from './components/order/orderassign/orderassign.component';
import { CustomeraddressComponent } from './components/customer/customeraddress/customeraddress.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AddnotificationComponent } from './components/notification/addnotification/addnotification.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { AddcouponComponent } from './components/coupon/addcoupon/addcoupon.component';
import { RestaurantfindComponent } from './components/grid/restaurantfind/restaurantfind.component';
import { CuisinefindComponent } from './components/grid/cuisinefind/cuisinefind.component';
import { BrandfindComponent } from './components/grid/brandfind/brandfind.component';
import { DishesfindComponent } from './components/grid/dishesfind/dishesfind.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddsettingsComponent } from './components/settings/addsettings/addsettings.component';
import { DeliverychargesComponent } from './components/deliverycharges/deliverycharges.component';
import { AdddeliverychargesComponent } from './components/deliverycharges/adddeliverycharges/adddeliverycharges.component';
import { OfferComponent } from './components/offer/offer.component';
import { AddofferComponent } from './components/offer/addoffer/addoffer.component';
import { AddcategorypopupComponent } from './components/generalpopup/addcategorypopup/addcategorypopup.component';
import { AddsubcategorypopupComponent } from './components/generalpopup/addsubcategorypopup/addsubcategorypopup.component';
import { AdduompopupComponent } from './components/generalpopup/adduompopup/adduompopup.component';
import { SelectcuisineComponent } from './components/restaurant/selectcuisine/selectcuisine.component';
import { AddcuisinepopupComponent } from './components/generalpopup/addcuisinepopup/addcuisinepopup.component';
import { SelectestablishmentComponent } from './components/restaurant/selectestablishment/selectestablishment.component';
import { SelectdishesComponent } from './components/product/selectdishes/selectdishes.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { AddestablishmentpopupComponent } from './components/generalpopup/addestablishmentpopup/addestablishmentpopup.component';
import { AdddishespopupComponent } from './components/generalpopup/adddishespopup/adddishespopup.component';
import { NgMaskCusDirectiveDirective } from './ng_mask_cus-directive.directive';
import { RenogridComponent } from './components/grid/renogrid/renogrid.component';
import { SortDirective } from './sort.directive';
import { StatusordersComponent } from './components/order/statusorders/statusorders.component';
import { SelectproducttagComponent } from './components/product/selectproducttag/selectproducttag.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { OrdermaplocationComponent } from './components/order/ordermaplocation/ordermaplocation.component';
import { DeliverypartnermaplocationComponent } from './components/deliverypartner/deliverypartnermaplocation/deliverypartnermaplocation.component';
import { AreaComponent } from './components/area/area.component';
import { AddareaComponent } from './components/area/addarea/addarea.component';
import { SalessummaryComponent } from './components/report/salessummary/salessummary.component';
import { SalesdetailsComponent } from './components/report/salesdetails/salesdetails.component';
import { AddrestaurantslotComponent } from './components/restaurant/addrestaurantslot/addrestaurantslot.component';
import { TimeFormatPipe } from './time-format.pipe';
import { PrintorderComponent } from './components/order/printorder/printorder.component';
import { BulkproductComponent } from './components/product/bulkproduct/bulkproduct.component';
import { OrderitemsummaryComponent } from './components/report/orderitemsummary/orderitemsummary.component';
import { ExportgridComponent } from './components/grid/exportgrid/exportgrid.component';
import { AssignorderlistComponent } from './components/order/assignorderlist/assignorderlist.component';
import { OrdergridComponent } from './components/grid/ordergrid/ordergrid.component';
import { OsproductlistComponent } from './components/product/osproductlist/osproductlist.component';
import { OutofstockoptionComponent } from './components/product/outofstockoption/outofstockoption.component';
import { ProductgridComponent } from './components/grid/productgrid/productgrid.component';
import { FailedorderlistComponent } from './components/order/failedorderlist/failedorderlist.component';
import { FailedorderComponent } from './components/failedorder/failedorder.component';
import { ViewfailedorderComponent } from './components/failedorder/viewfailedorder/viewfailedorder.component';
import { ConfirmfailedorderComponent } from './components/failedorder/confirmfailedorder/confirmfailedorder.component';
import { HomeComponent } from './components/home/home.component';
import { DailyappviewerComponent } from './components/report/dailyappviewer/dailyappviewer.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "left",
//   allowNegative: true,
//   decimal: ".",
//   precision: 2,
//   prefix: " ",
//   suffix: "",
//   thousands: ""
// };

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};



const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: "",
  separatorLimit: "9999999999",
  allowNegativeNumbers: false,
  dropSpecialCharacters:false,
  patterns:{
    'V': {pattern: new RegExp('-?')},
    '0': {pattern: new RegExp('[0-9]')}
  }
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    HeaderComponent,
    ConfirmationDialogComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    LoginComponent,
    DateFormatPipe,
    DatetimeFormatPipe,
    TimeFormatPipe,
    LoadingComponent,
    DisablecontrolDirective,
    ProcessComponent,
    ProductgroupComponent,
    AddproductgroupComponent,
    ImageformatterComponent,
    ButtonformatterComponent,
    ActionformatterComponent,
    CuisineComponent,
    AddcuisineComponent,
    CompanyComponent,
    AddcompanyComponent,
    RestaurantComponent,
    AddrestaurantComponent,
    SplcategoryComponent,
    AddsplcategoryComponent,
    EstablishtypeComponent,
    AddestablishtypeComponent,
    ProducttagtypeComponent,
    AddproducttagtypeComponent,
    FoodsymbolComponent,
    AddfoodsymbolComponent,
    CategoryComponent,
    AddcategoryComponent,
    SubcategoryComponent,
    AddsubcategoryComponent,
    UomComponent,
    AdduomComponent,
    UserComponent,
    AdduserComponent,
    TaxationComponent,
    AddtaxationComponent,
    ProductComponent,
    AddproductComponent,
    TaxgroupComponent,
    AddtaxgroupComponent,
    UserroleComponent,
    AdduserroleComponent,
    AddproductvariationComponent,
    CustomerComponent,
    ImportproductComponent,
    CartComponent,
    AddcartComponent,
    BrandComponent,
    AddbrandComponent,
    DishesComponent,
    AdddishesComponent,
    PaymentmodeComponent,
    AddpaymentmodeComponent,
    DeliverypartnerComponent,
    AdddeliverypartnerComponent,
    PageComponent,
    AddpageComponent,
    PagelayoutorderComponent,
    PageorderComponent,
    PagelayoutComponent,
    PagelayoutimageComponent,
    RestaurantsaveasComponent,
    OrderComponent,
    AddorderComponent,
    AddcustomerComponent,
    OrderoptionComponent,
    OrderassignComponent,
    CustomeraddressComponent,
    NotificationComponent,
    AddnotificationComponent,
    CouponComponent,
    AddcouponComponent,
    RestaurantfindComponent,
    CuisinefindComponent,
    BrandfindComponent,
    DishesfindComponent,
    SettingsComponent,
    AddsettingsComponent,
    OfferComponent,
    DeliverychargesComponent,
    AdddeliverychargesComponent,
    AddofferComponent,
    AddcategorypopupComponent,
    AddsubcategorypopupComponent,
    AdduompopupComponent,
    SelectcuisineComponent,
    AddcuisinepopupComponent,
    SelectestablishmentComponent,
    SelectdishesComponent,
    ApprovalsComponent,
    AddestablishmentpopupComponent,
    AdddishespopupComponent,
    NgMaskCusDirectiveDirective,
    RenogridComponent,
    SortDirective,
    StatusordersComponent,
    SelectproducttagComponent,
    CityComponent,
    AddcityComponent,
    OrdermaplocationComponent,
    DeliverypartnermaplocationComponent,
    AreaComponent,
    AddareaComponent,
    SalessummaryComponent,
    SalesdetailsComponent,
    AddrestaurantslotComponent,
    PrintorderComponent,
    BulkproductComponent,
    OrderitemsummaryComponent,
    ExportgridComponent,
    AssignorderlistComponent,
    OrdergridComponent,
    OsproductlistComponent,
    OutofstockoptionComponent,
    ProductgridComponent,
    FailedorderlistComponent,
    FailedorderComponent,
    ViewfailedorderComponent,
    ConfirmfailedorderComponent,
    HomeComponent,
    DailyappviewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    NgSelectModule,
    DragDropModule,
    CKEditorModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    SidebarModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxEmojiPickerModule.forRoot(),
    CurrencyMaskModule,
    NgxMaskModule.forRoot(maskConfig),
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-center'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyClUICP4-qGf2r4SSCZF5MzbSFXT6mIbvM",    
      libraries: ["places"]
    }),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxChartsModule,
    AgmDirectionModule,
    //AngularFireModule.initializeApp(AppSettings.firebaseConfig),
    AngularFireDatabaseModule,
    AgGridModule.withComponents([ImageformatterComponent, ButtonformatterComponent])
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, NgxImageCompressService, DateFormatPipe, Location,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }, { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig }],
  bootstrap: [AppComponent],
  exports: [BrowserModule, TranslateModule],
  entryComponents: [RenogridComponent, ConfirmationDialogComponent, AddproductvariationComponent, ImportproductComponent, PagelayoutorderComponent, PageorderComponent, PagelayoutComponent,
    PagelayoutimageComponent,RestaurantsaveasComponent,OrderoptionComponent,OrderassignComponent,CustomeraddressComponent,RestaurantfindComponent,CuisinefindComponent,
    BrandfindComponent,DishesfindComponent,AddcategorypopupComponent ,AddsubcategorypopupComponent, AdduompopupComponent,SelectcuisineComponent,AddcuisinepopupComponent,
    SelectestablishmentComponent, SelectdishesComponent, AddestablishmentpopupComponent, AdddishespopupComponent, StatusordersComponent, SelectproducttagComponent,
    OrdermaplocationComponent, DeliverypartnermaplocationComponent, AddrestaurantslotComponent, PrintorderComponent, ExportgridComponent,
    AssignorderlistComponent, ProductgridComponent, OutofstockoptionComponent,ConfirmfailedorderComponent],
})
export class AppModule { }
