import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-restaurantsaveas',
  templateUrl: './restaurantsaveas.component.html',
  styleUrls: ['./restaurantsaveas.component.css']
})
export class RestaurantsaveasComponent implements OnInit {


  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isduplicate: boolean;

  restaurantsaveasform = new FormGroup({
    restaurantid: new FormControl(0),
    restaurantname: new FormControl('', Validators.required),
    iscuisine: new FormControl(false),
    isestablishment: new FormControl(false),
    istiming: new FormControl(false),
    isdishes: new FormControl(false),
    isproduct: new FormControl(false),
  });

  restaurantsaveasColl: any = [];



  @Input() restaurantid: any = 0;
  @Input() restaurantname: any = '';
  @Input() iscuisine: any = false;
  @Input() isestablishment: any = false;
  @Input() istiming: any = false;
  @Input() isdishes: any = false;
  @Input() isproduct: any = false;
  @Input() restaurantdetailsColl: any = [];

  @Output() emitData = new EventEmitter();

  ngOnInit() {

    this.restaurantsaveasform.reset();
    this.fnInitializeFormGroup();


  }


  fnInitializeFormGroup() {
    this.restaurantsaveasform.setValue({
      restaurantid: this.restaurantid,
      restaurantname: this.restaurantname,
      iscuisine: true,
      isestablishment: true,
      istiming: true,
      isdishes: true,
      isproduct: true,
    })
  }

  fnInsertRestaurantSaveas(data) {
    // var data = {
    //   'restaurantid': this.restaurantid,
    //   'restaurantname':  this.restaurantsaveasform.get('restaurantname').value,
    //   'iscuisine':  this.restaurantsaveasform.get('iscuisine').value,
    //   'isestablishment':  this.restaurantsaveasform.get('isestablishment').value,
    //   'istiming':  this.restaurantsaveasform.get('istiming').value,
    //   'isdishes':  this.restaurantsaveasform.get('isdishes').value,
    //   'isproduct': this.restaurantsaveasform.get('isproduct').value,
    // }
    this.appService.fnInsertRestaurantSaveas(data)
      .subscribe(
        (res) => {
          console.log(res);

          if (res.json().status === 200) {


            this.emitData.emit('DONE');
            this.activeModal.close(true);


            //this.restaurantsaveasColl = res.json().result;

          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fnSaveBtnClick() {
    try {

      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.restaurantsaveasform.valid) {

        //Check
        var data = {
          restaurantid: this.restaurantid,
          restaurantname: this.restaurantsaveasform.get('restaurantname').value,
          iscuisine: this.restaurantsaveasform.get('iscuisine').value,
          istiming: this.restaurantsaveasform.get('istiming').value,
          isestablishment: this.restaurantsaveasform.get('isestablishment').value,
          isdishes: this.restaurantsaveasform.get('isdishes').value,
          isproduct: this.restaurantsaveasform.get('isproduct').value,
          modifiedby:this.loginService.fnGetModUserId()
        }

        this.fnInsertRestaurantSaveas(data);


      }
    }
    catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
