import { DatePipe } from '@angular/common';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-failedorder',
  templateUrl: './failedorder.component.html',
  styleUrls: ['./failedorder.component.css']
})
export class FailedorderComponent implements OnInit, AfterViewInit, OnDestroy {


  cartColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Cart";


  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Restaurant Name', field: 'restaurantname' }, 
    { headerName: 'Customer ', field: 'firstname' },
    { headerName: 'Mobile', field: 'mobile' },
    { headerName: 'Payment RefNo', field: 'reforderno' },    
    { headerName: 'Product count ', field: 'productcount' },
    { headerName: 'Qty Total ', field: 'qtytotal' },
    { headerName: 'Grand Total ', field: 'grandtotal' },
    {headerName: 'Created On', field: 'createdon', type: 'datetime'},
    {headerName: 'Modified On', field: 'modifiedon', type: 'datetime'},

  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };



  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService
    , private datePipe: DatePipe) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetCart();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetCart() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'cartid': 0,
    }
    this.appService.fnOrderFailedPaid(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.cartColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddCart() {
    try {
      this.router.navigate(['/AddCart']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditCart(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddFailedOrder'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Grid


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }

    this.fnEditCart(data.cartid);


  }



  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'cartlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.cartColl.findIndex(sku => sku.cartid === id);

    if (index) {
      return this.cartColl[index].firstname;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
