import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit  ,AfterViewInit, OnDestroy {
 

  customerColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Customer";


  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Customer Name', field: 'firstname'},   
    { headerName: 'Mobile', field: 'mobile' },   
    { headerName: 'Email', field: 'email' },   
    { headerName: 'Join Date', field: 'createdon', type:'datetime'},   
    
  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
  
    

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetCustomer();     
  }

  fnCheckPrivilage() {
    
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
  
  fngetCustomer() {  
         
    if(this.showProgress){
      return;
    }
     
    this.fnShowProgress();
      var data = {
            'customerid': 0,            
      }
      this.appService.getCustomer(data)
          .subscribe(
            (res)=>{             
              
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.customerColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddCustomer(){
    try{
      this.router.navigate(['/AddCustomer']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditCustomer(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddCustomer'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }




  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }

    this.fnEditCustomer(data.customerid);

   
  }

  

  fnActionExportClick(data) {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'productgrouplist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }


     
  private fnSetDataTable(){
  
  }

      
  fnPrintData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  

fnGetDescription(id){
  const index = this.customerColl.findIndex(sku => sku.customerid === id  ); 

  if(index){
    return this.customerColl[index].firstname;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  // this.appService.fnInsertActivity(data).subscribe(
  //   (res)=>{
  //   },
  //   (err)=>{
   
  //   });

}catch{

}
}


  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
