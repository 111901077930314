import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LoginDataService } from './services/dhukan/login-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewChecked  {
  
 
  title = 'Dhukanadmin';

  islogin:boolean=false;

  constructor(private appService: LoginDataService){}

  ngOnInit(){
   
  }

  ngAfterViewChecked(): void {

    setTimeout(() =>  this.islogin=this.appService.islogin );
     
  }

}
