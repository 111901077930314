import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ExportgridComponent } from '../../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-dailyappviewer',
  templateUrl: './dailyappviewer.component.html',
  styleUrls: ['./dailyappviewer.component.css']
})
export class DailyappviewerComponent implements OnInit {

 
  mainColl: any = [];
  restaurantColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "DailyAppViewer";

  fromdate: any = null;
  todate: any = null;
  restaurantid: any = null;

  isshowfromdate: boolean = false;

  private gridApi;
  private gridColumnApi;
  pinnedBottomRowData;

  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Session', field: 'sessionid' },
    { headerName: 'Customer', field: 'customername' },
    { headerName: 'Address', field: 'address' },
    { headerName: 'City', field: 'cityname' },
    { headerName: 'M.Brand', field: 'brand' },
    { headerName: 'M.Model', field: 'devicemodel' },
    { headerName: 'OpenOn', field: 'modifiedon',type:'datetime' },
    { headerName: 'LastViewOn', field: 'lastviewon',type:'datetime' },
  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };




  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private toastr: ToastrService, private loginService: LoginDataService, private productFindService: NgbModal) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {

      this.fromdate = new Date();
      this.todate = new Date();


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetReportListView();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetReportListView() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();

    var fromdate = null;
    if (!this.isshowfromdate) {
      fromdate = this.fnFormatDatetime(this.fromdate);
    }
    var data = {
      'fromdate': fromdate,
      'todate': this.fnFormatDatetime(this.todate),
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getReportDailyAppViewer(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.mainColl = res.json().result;


            this.fnSetTable();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  private fnSetTable() {
    if (this.gridApi) {

      this.gridApi.sizeColumnsToFit();
    }

  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {
      const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
      modalRef.componentInstance.doctype = this.formname;
      modalRef.componentInstance.columnDefs = this.columnDefs;
      modalRef.componentInstance.contentColl = this.mainColl;
      modalRef.componentInstance.fnSetTable();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    
      
      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnFormatDecimal(params) {
    if (params.value) {
      return params.value.toFixed(2);
    }
  }



  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
