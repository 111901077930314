import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

 
  transform(value: string) {
    var datePipe = new DatePipe("en-US");
    if(value){
      value = datePipe.transform(value, 'h:mm:ss a');
    }     
     return value;
 }

}
