import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-addcoupon',
  templateUrl: './addcoupon.component.html',
  styleUrls: ['./addcoupon.component.css']
})
export class AddcouponComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  public imagePath;
  imgURL: any;
  public message: string;
  //Save Validation
  submitted: boolean;
  successfully: boolean;

 

  //Edit Get Values
  couponColl: any = [];
  couponid: number = 0

  customerColl: any = [];
  //pageColl:any=[];

  couponform = new FormGroup({
    couponid: new FormControl(0),
    couponcode: new FormControl('', Validators.required),
    couponname: new FormControl('', Validators.required),
    coupontype: new FormControl(''),
    coupontargetid: new FormControl(null),
    ispresentage: new FormControl(false),
    presentage: new FormControl(null),
    amt: new FormControl(null),
    startdt: new FormControl(''),
    enddt: new FormControl(''),
    iscustomerlimit: new FormControl(false),
    customerlimitvalue: new FormControl(null),
    limittype: new FormControl(''),
    limitvalue: new FormControl(null),
    title: new FormControl(''),
    descr: new FormControl(''),
    termandcondition: new FormControl(''),
    inactive: new FormControl(false),
    couponimage: new FormControl(null),
    limitamt: new FormControl(null),
    customerid: new FormControl(null),
  });

  //set image
  image;
  strImage: any;
  couponpicurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Coupon";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.couponid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.couponform.reset();
      this.fnInitializeNotification();

      if (this.couponid > 0) {
        this.fngetCoupon();
      }
      this.fngetCustomer();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.couponid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeNotification() {
    this.couponform.setValue({

      couponid: 0,
      couponcode: '',
      couponname: '',
      coupontype: '',
      coupontargetid: null,
      ispresentage: false,
      presentage: null,
      amt: null,
      startdt: '',
      enddt: '',
      iscustomerlimit: false,
      customerlimitvalue: null,
      limittype: '',
      limitvalue: null,
      title: '',
      descr: '',
      termandcondition: '',
      inactive: false,
      couponimage: null,
      limitamt: null,
      customerid: null,
    })
  }



  fngetCustomer() {
    var data = {
      'customerid': 0,
    }
    this.appService.getCustomer(data)
      .subscribe(
        (res) => {
          this.customerColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetCoupon() {
    var data = {
      'couponid': this.couponid,
    }
    this.appService.getCoupon(data)
      .subscribe(
        (res) => {
          this.couponColl = res.json().result;
          var editdata = this.couponColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.couponform.valid) {
        //Check
        var data = {
          'couponid': this.couponid,
          'couponcode': this.couponform.get('couponcode').value,
          'couponname': this.couponform.get('couponname').value,
          'coupontype': this.couponform.get('coupontype').value,
          'coupontargetid': this.couponform.get('coupontargetid').value,
          'ispresentage': this.couponform.get('ispresentage').value,
          'presentage': this.couponform.get('presentage').value,
          'amt': this.couponform.get('amt').value,
          'startdt': this.fnFormatDatetime(this.couponform.get('startdt').value),
          'enddt': this.fnFormatDatetime(this.couponform.get('enddt').value),
          'iscustomerlimit': this.couponform.get('iscustomerlimit').value,
          'customerlimitvalue': this.couponform.get('customerlimitvalue').value,
          'limittype': this.couponform.get('limittype').value,
          'limitvalue': this.couponform.get('limitvalue').value,
          'title': this.couponform.get('title').value,
          'descr': this.couponform.get('descr').value,
          'termandcondition': this.couponform.get('termandcondition').value,
          couponimage: this.strImage,
          couponpicurl: this.couponpicurl,
          'inactive': this.couponform.get('inactive').value,
          // 'couponpicurl': this.couponform.get('couponpicurl').value,
          'limitamt': this.couponform.get('limitamt').value,
          'customerid': this.couponform.get('customerid').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckCoupon(data).subscribe(
          (res) => {

            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.couponform.get('couponid').value > 0) {
                //Update Notification
                this.fnSetCoupon(data);
              }
              else {
                //Insert Notification
                this.fnInsertCoupon(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.couponform.controls['couponname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertCoupon(data) {

    this.appService.fnInsertCoupon(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Coupon has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.couponname);

          this.fnCloseCoupon();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetCoupon(data) {

    this.appService.fnSetCoupon(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Coupon has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.couponid, data.couponname);

          this.fnCloseCoupon();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseCoupon() {
    try {
      this.router.navigate(['/Coupon']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    deptBO.startdt = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.startdt));
    deptBO.enddt = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.enddt));

    this.couponform.setValue({

      couponid: deptBO.couponid,
      couponcode: deptBO.couponcode,
      couponname: deptBO.couponname,
      coupontype: deptBO.coupontype,
      coupontargetid: deptBO.coupontargetid,
      ispresentage: deptBO.ispresentage,
      presentage: deptBO.presentage,
      amt: deptBO.amt,
      startdt: deptBO.startdt,
      enddt: deptBO.enddt,
      iscustomerlimit: deptBO.iscustomerlimit,
      customerlimitvalue: deptBO.customerlimitvalue,
      limittype: deptBO.limittype,
      limitvalue: deptBO.limitvalue,
      title: deptBO.title,
      descr: deptBO.descr,
      termandcondition: deptBO.termandcondition,
      inactive: deptBO.inactive,
      couponimage :null,
      limitamt: deptBO.limitamt,
      customerid: deptBO.customerid
    });

    this.couponpicurl = deptBO.couponpicurl;
   

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

//Image Import
changeListener($event): void {
  try {
    this.readThis($event.target);
  } catch (ex) {
    this.fnShowErrorMsg(ex);
  }
}

readThis(inputValue: any): void {

  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
    this.image = myReader.result;
    this.strImage = this.image.split(',')[1];
  }
  myReader.readAsDataURL(file);
}

removeimage() {
  this.image = null;
  this.strImage = null;
  this.couponpicurl = "";
}

fnOpenFileBrowser(event: any) {

  event.preventDefault();

  let element: HTMLElement = document.getElementById('image') as HTMLElement;
  element.click();

}






fnInsertActivityLog(action, refno, desc) {
  try {
    var data = {
      logtype: action,
      logat: this.formname,
      refnum: refno,
      logdescr: desc,
      logby: this.loginService.fnGetModUserId(),
    }


    // this.appService.fnInsertActivity(data).subscribe(
    //   (res)=>{
    //   },
    //   (err)=>{

    //   });

  } catch {

  }
}




  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
