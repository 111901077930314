import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';


@Component({
  selector: 'app-addcuisine',
  templateUrl: './addcuisine.component.html',
  styleUrls: ['./addcuisine.component.css']
})
export class AddcuisineComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  pageColl: any = [];
  //Save Validation
  submitted: boolean;
  successfully: boolean;

  public imagePath;
  imgURL: any;
  public message: string;

  //Edit Get Values
  cuisineColl: any = [];
  cuisineid: number = 0

  //pageColl:any=[];

  cuisineform = new FormGroup({
    cuisineid: new FormControl(0),
    cuisinename: new FormControl('', Validators.required),
    cuisineimage: new FormControl(null),
    pageid:new FormControl(null),
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Cuisine";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.cuisineid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.cuisineform.reset();
      this.fnInitializeCuisine();

      if (this.cuisineid > 0) {
        this.fngetCuisine();
      }
      this.fngetPage();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.cuisineid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeCuisine() {
    this.cuisineform.setValue({

      cuisineid: 0,
      cuisinename: '',
      cuisineimage: null,
      pageid: null,
      inactive: false,
    })
  }

  fngetPage() {
    var data = {
      'pageid': 0,
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          this.pageColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  


  //Get 
  fngetCuisine() {
    var data = {
      'cuisineid': this.cuisineid,
    }
    this.appService.getCuisine(data)
      .subscribe(
        (res) => {
          this.cuisineColl = res.json().result;
          var editdata = this.cuisineColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.cuisineform.valid) {
        //Check
        var data = {
          'cuisineid': this.cuisineid,
          'cuisinename': this.cuisineform.get('cuisinename').value,
          cuisineimage: this.strImage,
          picurl: this.picurl,
          'pageid': this.cuisineform.get('pageid').value,
          'inactive': this.cuisineform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckCuisine(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.cuisineform.get('cuisineid').value > 0) {
                //Update Department
                this.fnSetCuisine(data);
              }
              else {
                //Insert Department
                this.fnInsertCuisine(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.cuisineform.controls['cuisinename'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertCuisine(data) {

    this.appService.fnInsertCuisine(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Cuisine has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.cuisinename);

          this.fnCloseCuisine();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetCuisine(data) {

    this.appService.fnSetCuisine(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Cuisine has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.cuisineid, data.cuisinename);

          this.fnCloseCuisine();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseCuisine() {
    try {
      this.router.navigate(['/Cuisine']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.cuisineform.setValue({
      cuisineid: deptBO.cuisineid,
      cuisinename: deptBO.cuisinename,
      cuisineimage: null,
      pageid: deptBO.pageid, 
      inactive: deptBO.inactive,
    });
    this.picurl = deptBO.picurl;

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }






  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
