import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDataService } from '../services/dhukan/login-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userBO:any={};
  userrolerightsColl:any=[];
  userView: any = {};
  
  restaurantname: any = "";
  restaurantcode: any = "";
  
  isadminview: boolean = false;
  isrestaruntview: boolean = false;
  issuperadminview: boolean = false;
  
  constructor(public router: Router,private loginService: LoginDataService) {
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO = this.userView.userBO                

          this.restaurantname = this.userBO.restaurantname;
          this.restaurantcode= this.userBO.restaurantcode;
          if (this.userBO.adminview === 'DONE') {
            this.isadminview = true;
          }
  
          if (this.userBO.restaurantview === 'DONE') {
            this.isrestaruntview = true;
          }

          if (this.userBO.superadminview === 'DONE') {
            this.issuperadminview = true;
          }



        }      
        
    }); 
  }

  ngOnInit() {
    if(localStorage.exwtoken === undefined){
      this.router.navigate(['/Login']);
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

   
  }

  fnRestaruntSwitch(){    
    localStorage.removeItem("restoken");
       
    this.router.navigate(['/']).then(()=>{
      window.location.reload();
    });

  }


  fnLogout(){
    localStorage.removeItem("exwtoken");
    localStorage.removeItem("restoken");
    this.router.navigate(['/Login']);
  }

}
