import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addsubcategorypopup',
  templateUrl: './addsubcategorypopup.component.html',
  styleUrls: ['./addsubcategorypopup.component.css']
})
export class AddsubcategorypopupComponent implements OnInit {
//Mode Values
isaddmode: boolean = false;
iseditmode: boolean = false;
isdeletemode: boolean = false;
isviewmode: boolean = false;

//Save Validation
submitted: boolean;
successfully: boolean;



//Edit Get Values
subcategoryColl: any = [];
categoryColl: any = [];
subcategoryid: number = 0

//pageColl:any=[];

subcategoryform = new FormGroup({
  subcategoryid: new FormControl(0),
  categoryid: new FormControl('', Validators.required),
  //productgroup:new FormControl('',Validators.required),            
  subcategoryname: new FormControl('', Validators.required),
  inactive: new FormControl(false),
});




isloading: boolean = false;
isprivilage: boolean = false;
showProgress: boolean = false;
formname: String = "Subcategory";

 @Input() subcategoryname: any = '';
 @Input() categoryid: number = null;
 @Output() emitData = new EventEmitter();


 constructor(private toastr: ToastrService, private appService: DhukanDataService,private route: ActivatedRoute,
   private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
     this.route.queryParams.subscribe(params => {
       if(params.id!==undefined){
         this.subcategoryid = params.id
       }  
      });
      this.fnServiceChanges();
 }
 
 
 fnServiceChanges(){
   this.loginService.serviceloginChange.subscribe((value) => {
         if(this.loginService.fnCheckUser()){
          
           if(this.fnCheckUserPririvilage()){
             this.isloading=true;
             this.fnOnInitForm();
           }
           else{
             this.fnUserFailedForm();
           }
             
         }
         else{
           this.fnUserFailedForm();
         }
   });
 }
 
 ngOnInit() {
  try {
    if (this.loginService.fnCheckUser()) {
      if (this.fnCheckUserPririvilage()) {
        this.isloading = true;
        this.fnOnInitForm();
      }
      else {
        this.fnUserFailedForm();
      }
    }
    else {
      this.loginService.fnGetUserRoleList();
    }

  } catch (ex) {
    this.fnShowErrorMsg(ex);
  }
}

fnOnInitForm() {
  try {
    this.isaddmode = true;
    this.subcategoryform.reset();
    this.fnInitializeSubcategory();

    if (this.subcategoryid > 0) {
      this.fngetSubcategory();

    }
    this.fngetCategory();

  } catch (ex) {
    this.fnShowErrorMsg(ex);
  }
}



fnCheckUserPririvilage() {
  var _check: boolean = false;
  if (this.subcategoryid > 0) {
    _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
  } else {
    _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
  }

  return _check;
}

fnUserFailedForm() {
  this.isprivilage = true;
}


fnInitializeSubcategory() {
  this.subcategoryform.setValue({

    subcategoryid: 0,
    categoryid: null,
    subcategoryname: '',
    inactive: false,
  })
}


fngetCategory() {


 
  var data = {
    'categoryid': 0,
    'restaurantid':this.loginService.fnGetModRestaruntId()
  }
  this.appService.getCategory(data)
    .subscribe(
      (res) => {

    
        if (res.json().status == 200) {
          this.categoryColl = res.json().result;

        }
        else if (res.json().status == 400) {
          this.toastr.error('Error', res.json().err_field);
        }


      },
      (err) => {
       
        this.toastr.error('Error', err);
      })
}

//Get 
fngetSubcategory() {
  var data = {
    'subcategoryid': this.subcategoryid,
    'restaurantid':this.loginService.fnGetModRestaruntId()
  }
  this.appService.getSubcategory(data)
    .subscribe(
      (res) => {
        this.subcategoryColl = res.json().result;
        var editdata = this.subcategoryColl[0];
      
      },
      (err) => {
        this.toastr.error('Error', err);
      })
}




//Save Btn Click
fnSaveBtnClick() {
  try {
    this.submitted = true;
    this.successfully = false;
    //Validate
    if (this.subcategoryform.valid) {
      //Check
      var data = {
        'subcategoryid': this.subcategoryid,
        'categoryid': this.subcategoryform.get('categoryid').value,
        'restaurantid':this.loginService.fnGetModRestaruntId(),
        'subcategoryname': this.subcategoryform.get('subcategoryname').value,
        'inactive': this.subcategoryform.get('inactive').value,
        'createdby': this.loginService.fnGetModUserId(),
        'modifiedby': this.loginService.fnGetModUserId(),
      }
      this.appService.fnCheckSubcategory(data).subscribe(
        (res) => {
          var _checkresult = res.json().result[0].checkfield;

          if (_checkresult === "done") {
          
              //Insert Department
              this.fnInsertSubcategory(data);
         
          }
          else {
            if (_checkresult === "name") {
              this.subcategoryform.controls['subcategoryname'].setErrors({ 'notfound': true });
            }
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        });
    }
  } catch (ex) {
    this.fnShowErrorMsg(ex);
  }
}


 //Insert
 fnInsertSubcategory(data) {

   this.appService.fnInsertSubcategory(data).subscribe(
     (res) => {

       if (res.json().status === 200) {

         var result = res.json().result;

         var subcategoryid = 0;
         if (result.length > 0) {
           subcategoryid = result[0].subcategoryid
         }


         this.toastr.success('', "The new subcategory has been added successfully.Insert Successfully");
         this.emitData.emit(subcategoryid);
         this.activeModal.close(true);

       }
     },
     (err) => {
       this.toastr.error('Error', err);
     });

 }

 public decline() {
   this.activeModal.close(false);
 }


 private fnShowErrorMsg(ex) {
   this.toastr.warning('Internal Error', ex);
 }

}
