import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-ordermaplocation',
  templateUrl: './ordermaplocation.component.html',
  styleUrls: ['./ordermaplocation.component.css']
})
export class OrdermaplocationComponent implements OnInit {



  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  @Input() latitude: any = null;
  @Input() longitude: any = null;

  @Output() emitData = new EventEmitter();

  mapType = 'roadmap';

  ngOnInit() {

 
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
