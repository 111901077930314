import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-adduompopup',
  templateUrl: './adduompopup.component.html',
  styleUrls: ['./adduompopup.component.css']
})
export class AdduompopupComponent implements OnInit  {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  uomColl:any=[];
  uomid:number=0
  
  //pageColl:any=[];
  
  uomform=new FormGroup({        
    uomid:new FormControl(0),     
    //productgroup:new FormControl('',Validators.required),            
    uomname:new FormControl('',Validators.required),   
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Cuisine";


  @Input() uomname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService,private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
      this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.uomid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.uomform.reset();
         this.fnInitializeUom(); 
  
         if(this.uomid>0){
           this.fngetUom();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.uomid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeUom(){
     this.uomform.setValue({
       
      uomid:0, 
      uomname:'',    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetUom() {        
       var data = {
         'uomid':this.uomid,            
       }
       this.appService.getUom(data)
       .subscribe(
         (res)=>{                  
           this.uomColl=res.json().result;
           var editdata =this.uomColl[0];
         
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.uomform.valid){
           //Check
           var data = {
              'uomid': this.uomid,                   
                'restaurantid':this.loginService.fnGetModRestaruntId(),
                 'uomname':this.uomform.get('uomname').value,                 
                 'inactive':this.uomform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckUom(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                     
                         //Insert Department
                         this.fnInsertUom(data);
                  
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.uomform.controls['uomname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }



  //Insert
  fnInsertUom(data) {

    this.appService.fnInsertUom(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var uomid = 0;
          if (result.length > 0) {
            uomid = result[0].uomid
          }


          this.toastr.success('', "The new Uom has been added successfully.Insert Successfully");
          this.emitData.emit(uomid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
