import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-orderoption',
  templateUrl: './orderoption.component.html',
  styleUrls: ['./orderoption.component.css']
})
export class OrderoptionComponent implements OnInit {

  @Input() statusname:String="";
  @Output() emitData = new EventEmitter();

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,
    private toastr: ToastrService, private productFindService: NgbModal,private loginService:LoginDataService) { }
  ngOnInit() {
  }


  fnBtnClick(data){
    this.emitData.emit(data);
    this.activeModal.close(true);
}
  
  public decline() {
    this.activeModal.close(false);
  }

}
