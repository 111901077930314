import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ImportproductComponent } from '../importproduct/importproduct.component';
import { OutofstockoptionComponent } from '../outofstockoption/outofstockoption.component';

@Component({
  selector: 'app-osproductlist',
  templateUrl: './osproductlist.component.html',
  styleUrls: ['./osproductlist.component.css']
})
export class OsproductlistComponent implements OnInit, AfterViewInit, OnDestroy {


  productColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "OutOfStockList";

  filterby: string = "AVAILABLE";



  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Sku', field: 'skucode' },
    { headerName: 'Product', field: 'productname' },
    { headerName: 'Category', field: 'categoryname' },
    { headerName: 'Subcategory', field: 'subcategoryname' },
    { headerName: 'Uom', field: 'uomname' },
    { headerName: 'Uomqty', field: 'uomqty' },
    { headerName: 'Status', field: 'productstatus' },
    { headerName: 'Info', field: 'productstatusmsg' },
    

  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1,
  };



  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService,
    private productFindService: NgbModal, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetProduct();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetProduct() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'productid': 0,
      'restaurantid': this.loginService.fnGetModRestaruntId(),
      'filterby': this.filterby
    }
    this.appService.getProductListview(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.productColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddProduct() {
    try {
      this.router.navigate(['/AddProduct']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

  fnImportBtn() {

    const modalRef = this.productFindService.open(ImportproductComponent, { size: <any>'l' });



    // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    // });

  }

  fnEditProduct(deptid, actiontype) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'actiontype': actiontype,
        }
      }

      this.router.navigate(['/AddProduct'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnUpdateProduct(data, actiontype) {
    
    var statusmsg=''

    statusmsg=data.productstatus


    if (statusmsg === 'OUTOFSTOCK' || statusmsg === 'TIMEOUT') {
   
      this.fnSetProductAvialable(data.productid, "", "", null)
    } else {
      const modalRef = this.productFindService.open(OutofstockoptionComponent, { size: <any>'m' });
      modalRef.componentInstance.productBO = data;
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        this.fnSetProductAvialable(data.productid, "INACTIVE", receivedEntry.avalablemethod, receivedEntry.avalabledate)
      });
    }
  
  }


  
  fnSetProductAvialable(productid,availablemode,avalablemethod,avalabledate) {

    var data = {
      'productid': productid,
      'restaurantid': this.loginService.fnGetModRestaruntId(),
      'availablemode': availablemode,
      'avalablemethod': avalablemethod,
      'avalabledate': avalabledate,
    }
    this.appService.fnSetProductAvailable(data)
      .subscribe(
        (res) => {
          console.log(res.json());
          if (res.json().status == 200) {
            this.fngetProduct();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  //Grid



  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }

    this.fnEditProduct(data.productid, data.actiontype);

  }

  fnActionDeleteClick(data) {
    if (!this.isdelete) {
      return;
    }


  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'productgrouplist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.productColl.findIndex(sku => sku.productid === id);

    if (index) {
      return this.productColl[index].productname;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
