import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { AdddishespopupComponent } from '../../generalpopup/adddishespopup/adddishespopup.component';

@Component({
  selector: 'app-selectproducttag',
  templateUrl: './selectproducttag.component.html',
  styleUrls: ['./selectproducttag.component.css']
})
export class SelectproducttagComponent implements OnInit {

  tagid: number = 0;
  tagColl: any = [];

  @Input() producttagcoll: any = [];
  @Input() productid: any = 0;
  @Output() emitData = new EventEmitter();

  constructor(private toastr: ToastrService, private appService: DhukanDataService, private productFindService: NgbModal,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {

  }

  //Get 
  fngetDishese() {
    var data = {
      'producttagtypeid': 0,
    }
    this.appService.getDishes(data)
      .subscribe(
        (res) => {
          this.tagColl = res.json().result;



        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnUpdateTags() {
    this.emitData.emit(this.producttagcoll);
    this.activeModal.close(true);
  }


  checkValue(values: any, index) {
    this.producttagcoll[index].ischeck = values.currentTarget.checked;
  }

  
 //AddDishes
 private fnAddDishes() {

  const modalRef = this.productFindService.open(AdddishespopupComponent, { size: <any>'m' });
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    this.fngetDishese();
  });
}
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }



}
