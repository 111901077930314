import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { OrderassignComponent } from '../orderassign/orderassign.component';

@Component({
  selector: 'app-assignorderlist',
  templateUrl: './assignorderlist.component.html',
  styleUrls: ['./assignorderlist.component.css']
})
export class AssignorderlistComponent implements OnInit, AfterViewInit, OnDestroy {

  restaurantColl: any = [];
  deliverypartnerColl: any = [];
  orderColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "OrderAssign";


  deliverypartnerid:any=null;
  restaurantid:any=null;

  filterby: String = "OPEN";
  deliveryfilterby: String = "";
  searchtxt: any = "";
  fromdate: any = null;
  todate: any = null;


  columnDefs = [
    { headerName: 'DeliveryPartner', field: 'deliverypartnername' },
    { headerName: 'OrderNo', field: 'orderno' },
    { headerName: 'Order Date', field: 'orderdate', type: 'datetime', colcls: "tbtx" },
    { headerName: 'Store', field: 'restauranttype' },
    { headerName: 'Restaurant Name', field: 'restaurantname', colcls: "tbtx" },
    { headerName: 'Customer', field: 'firstname' },
    { headerName: 'Mobile', field: 'mobile' },
    { headerName: 'Area', field: 'areaname', colcls: "tbtm" },
    { headerName: 'City', field: 'cityname', colcls: "tbtm" },
    { headerName: 'Status', field: 'status' },
    { headerName: 'Payment', field: 'paymentmodename', colcls: "tbtx" },
    { headerName: 'Total Product', field: 'productcountorg', colcls: "tbtm" },
    { headerName: 'Total Qty', field: 'qtytotalorg', colcls: "tbts" },
    { headerName: 'Grand Total', field: 'grandtotalorg', type: 'decimal', colcls: "tbts" },
  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  orderplacedcount: number = 0;
  confirmcount: number = 0;
  readytopickcount: number = 0;
  pickedcount: number = 0;
  deliverycount: number = 0;
  cancelledcount: number = 0;
  allcount: number = 0;



  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe) {
    this.fromdate = new Date();
    this.todate = new Date();

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetRestaurant();
    this.fngetDeliveryPartner();
    this.fngetOrder();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            console.log(res.json().result)
            let statusColl = res.json().result;

            // this.statusColl=[];

            for (let wbo of statusColl) {
              if (wbo.statusname === 'PLACED') { this.orderplacedcount = wbo.ordercount }
              if (wbo.statusname === 'CONFIRM') { this.confirmcount = wbo.ordercount }
              if (wbo.statusname === 'READYTOPICK') { this.readytopickcount = wbo.ordercount }
              if (wbo.statusname === 'PICKED') { this.pickedcount = wbo.ordercount }
              if (wbo.statusname === 'DELIVERED') { this.deliverycount = wbo.ordercount }
              if (wbo.statusname === 'CANCELLED') { this.cancelledcount = wbo.ordercount }
              if (wbo.statusname === 'ALL') { this.allcount = wbo.ordercount }

            }

            // let result=res.json().result[0];
            // this.orderplacedcount=result.placedcount;
            // this.confirmcount=result.confirmcount;                       
            // this.readytopickcount=result.readytopickcount;
            // this.pickedcount=result.pickedcount;
            // this.deliverycount=result.deliveredcount;              
            // this.allcount=result.allcount;


           // this.fngetOrder();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetReStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            console.log(res.json().result)
            let statusColl = res.json().result;

            // this.statusColl=[];

            for (let wbo of statusColl) {
              if (wbo.statusname === 'PLACED') { this.orderplacedcount = wbo.ordercount }
              if (wbo.statusname === 'CONFIRM') { this.confirmcount = wbo.ordercount }
              if (wbo.statusname === 'READYTOPICK') { this.readytopickcount = wbo.ordercount }
              if (wbo.statusname === 'PICKED') { this.pickedcount = wbo.ordercount }
              if (wbo.statusname === 'DELIVERED') { this.deliverycount = wbo.ordercount }
              if (wbo.statusname === 'CANCELLED') { this.cancelledcount = wbo.ordercount }
              if (wbo.statusname === 'ALL') { this.allcount = wbo.ordercount }

            }

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fngetRestaurant() {

  
    var data = {
      'restaurantid': 0,      
    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {

        
          if (res.json().status == 200) {
            this.restaurantColl = res.json().result;
          
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fngetDeliveryPartner() {
    var data = {
      'deliverypartnerid': 0,      
    }
    this.appService.getDeliverypartner(data)
      .subscribe(
        (res) => {        
          if (res.json().status == 200) {
            this.deliverypartnerColl = res.json().result[0];
          
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fngetOrder() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'orderid': 0,
      'restaurantid': this.restaurantid,
      'deliverypartnerid':this.deliverypartnerid,
      'fromdate': this.fnFormatDatetime(this.fromdate),
      'todate': this.fnFormatDatetime(this.todate),
      'filterby': this.filterby,
      'deliverypartnerfilterby': this.deliveryfilterby,
    }

    this.appService.getOrder(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.orderColl = res.json().result;
            this.fngetReStatus();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddOrder() {
    try {
      this.router.navigate(['/AddOrder']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditOrder(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddOrder'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  //Grid

  fnActionEditClick(data) {
    if (!this.isedit) {
      return;
    }

    this.fnEditOrder(data.orderid);
  }





  fnActionUpdateClick(data, type) {
    const modalRef = this.productFindService.open(OrderassignComponent, { size: <any>'m' });
    modalRef.componentInstance.orderBO = data;
    modalRef.componentInstance.deliverypartnerid = data.deliverypartnerid;
    modalRef.componentInstance.trackno = data.trackno;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fnSetOrderStatusDp(data.orderid, receivedEntry.deliverypartnerid, receivedEntry.trackno)
    });

  }

  fnSetOrderStatusDp(orderid, deliverypartnerid,trackno) {
    var data = {
      'orderid': orderid,
      'deliverypartnerid': deliverypartnerid,
      'trackno': trackno,
      'modifiedby': this.loginService.fnGetModUserId(),
    }

    this.appService.setOrderAssign(data).subscribe((res) => {
      if (res.json().status === 200) {
        this.toastr.success('', "Update Successfully.");
        this.fngetOrder();
      }
    })
  }











  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'orderlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
  fnGetDescription(id) {
    const index = this.orderColl.findIndex(sku => sku.orderid === id);

    if (index) {
      return this.orderColl[index].orderno;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
