import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-producttagtype',
  templateUrl: './producttagtype.component.html',
  styleUrls: ['./producttagtype.component.css']
})
export class ProducttagtypeComponent implements OnInit,AfterViewInit, OnDestroy {
 

  producttagtypeColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Producttagtype";

  
  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Product Tag Type Name', field: 'producttagtypename' },    
    { headerName: 'Offer Name', field: 'offername'},  

  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
  
    

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetProductTag();     
  }

  fnCheckPrivilage() {
    
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
  
  fngetProductTag() {  
         
    if(this.showProgress){
      return;
    }
     
    this.fnShowProgress();
      var data = {
            'producttagtypeid': 0,            
      }
      this.appService.getProducttagtype(data)
          .subscribe(
            (res)=>{             
              
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.producttagtypeColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddProductTag(){
    try{
      this.router.navigate(['/AddProducttagtype']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditProductTag(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddProducttagtype'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Producttagtype?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteProductTag(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteProductTag(deptid){
       var data = {
         'producttagtypeid': deptid,            
       }
      this.appService.fnDeleteProducttagtype(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Producttagtype has been deleted successfully.");                 

             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetProductTag();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  //Grid


  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }

    this.fnEditProductTag(data.producttagtypeid);

    
  }

  fnActionDeleteClick(data) {

    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.producttagtypeid);

   
  }

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'producttagtypelist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }


     
  private fnSetDataTable(){
  
  }

      
  fnPrintData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  

fnGetDescription(id){
  const index = this.producttagtypeColl.findIndex(sku => sku.producttagtypeid === id  ); 

  if(index){
    return this.producttagtypeColl[index].producttagtypename;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  // this.appService.fnInsertActivity(data).subscribe(
  //   (res)=>{
  //   },
  //   (err)=>{
   
  //   });

}catch{

}
}


  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
