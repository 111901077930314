
export const AppSettings = {

  
  //base_url: 'http://192.168.0.179:8000/',
  //base_url: 'http://200.1.50.108:8000/',
  //base_url: 'http://13.127.71.165:7000/',

  //base_url: 'http://192.168.0.181:8000/',

  base_url:'https://api.yesboz.com/',
  //base_url:'https://api.expresswala.com/',
  
  //base_url:'https://liveapi.expresswala.com/',

  firebaseConfig : {
    apiKey: "AIzaSyDp7Q6ZoWVSrQUN2ecjdYEcw-uAb7RCvhY",
    authDomain: "yesbozonline.firebaseapp.com",
    databaseURL: "https://yesbozonline-default-rtdb.firebaseio.com",
    projectId: "yesbozonline",
    storageBucket: "yesbozonline.appspot.com",
    messagingSenderId: "43003562949",
    appId: "1:43003562949:web:eca4376b972a90b796d9ac",
    measurementId: "G-WDFQ0BVDG0"
  },


  insertProductGroupUrl: 'productgroup/insertproductgroup',
  setProductGroupUrl: 'productgroup/setproductgroup',
  checkProductGroupUrl: 'productgroup/checkproductgroup',
  getProductGroupUrl: 'productgroup/getproductgroup',
  deleteProductGroupUrl: 'productgroup/deleteproductgroup',



  //Cuisine
  insertCuisineUrl: 'cuisine/insertcuisine',
  setCuisineUrl: 'cuisine/setcuisine',
  checkCuisineUrl: 'cuisine/checkcuisine',
  getCuisineUrl: 'cuisine/getcuisine',
  deleteCuisineUrl: 'cuisine/deletecuisine',


  //Company
  insertCompanyUrl: 'company/insertcompany',
  setCompanyUrl: 'company/setcompany',
  checkCompanyUrl: 'company/checkcompany',
  getCompanyUrl: 'company/getcompany',
  deleteCompanyUrl: 'company/deletecompany',

  getRestaurantCompanyUrl: 'company/getrestaurantbycompany',
 setCompanyApprovedUrl: 'company/setcompanyapproved',



  //Restaurant
  insertRestaurantUrl: 'restaurant/insertrestaurant',
  setRestaurantUrl: 'restaurant/setrestaurant',
  checkRestaurantUrl: 'restaurant/checkrestaurant',
  getRestaurantUrl: 'restaurant/getrestaurant',
  deleteRestaurantUrl: 'restaurant/deleterestaurant',
  getRestaurantcuisineUrl: 'restaurant/getrestaurantcuisine',
  getRestaurantestablishtypeUrl: 'restaurant/getrestaurantestablishtype',
  getRestauranttimingUrl: 'restaurant/getrestauranttiming',
  getRestaurantBrandUrl: 'restaurant/getrestaurantbrand',
  getRestaurantDishesUrl: 'restaurant/getrestaurantdishes',

  getRestaurantInstancetimingUrl: 'restaurant/getrestaurantinstancetiming',

  setRestaurantApprovedUrl: 'restaurant/setrestaurantapproved',

  insertRestaurantSaveasUrl: 'restaurant/insertrestaurantsaveas',

  insertRestaurantSlotUrl: 'restaurantslot/insertrestaurantslot',
  setRestaurantSlotUrl: 'restaurantslot/setrestaurantslot',
  deleteRestaurantSlotUrl: 'restaurantslot/deleterestaurantslot',
  getRestaurantSlotUrl: 'restaurantslot/getrestaurantslot',

  //Splcategory
  insertSplcategoryUrl: 'splcategory/insertsplcategory',
  setSplcategoryUrl: 'splcategory/setsplcategory',
  checkSplcategoryUrl: 'splcategory/checksplcategory',
  getSplcategoryUrl: 'splcategory/getsplcategory',
  deleteSplcategoryUrl: 'splcategory/deletesplcategory',


  //Establishtype
  insertEstablishtypeUrl: 'establishtype/insertestablishtype',
  setEstablishtypeUrl: 'establishtype/setestablishtype',
  checkEstablishtypeUrl: 'establishtype/checkestablishtype',
  getEstablishtypeUrl: 'establishtype/getestablishtype',
  deleteEstablishtypeUrl: 'establishtype/deleteestablishtype',

  //Producttagtype
  insertProducttagtypeUrl: 'producttagtype/insertproducttagtype',
  setProducttagtypeUrl: 'producttagtype/setproducttagtype',
  checkProducttagtypeUrl: 'producttagtype/checkproducttagtype',
  getProducttagtypeUrl: 'producttagtype/getproducttagtype',
  deleteProducttagtypeUrl: 'producttagtype/deleteproducttagtype',

 
  //City
  insertCityUrl: 'city/insertcity',
  setCityUrl: 'city/setcity',
  checkCityUrl: 'city/checkcity',
  getCityUrl: 'city/getcity',
  deleteCityUrl: 'city/deletecity',

   
  //Area
  insertAreaUrl: 'area/insertarea',
  setAreaUrl: 'area/setarea',
  checkAreaUrl: 'area/checkarea',
  getAreaUrl: 'area/getarea',
  deleteAreaUrl: 'area/deletearea',
 
  //Foodsymbol
  insertFoodsymbolUrl: 'foodsymbol/insertfoodsymbol',
  setFoodsymbolUrl: 'foodsymbol/setfoodsymbol',
  checkFoodsymbolUrl: 'foodsymbol/checkfoodsymbol',
  getFoodsymbolUrl: 'foodsymbol/getfoodsymbol',
  deleteFoodsymbolUrl: 'foodsymbol/deletefoodsymbol',

  //Category
  insertCategoryUrl: 'category/insertcategory',
  setCategoryUrl: 'category/setcategory',
  checkCategoryUrl: 'category/checkcategory',
  getCategoryUrl: 'category/getcategory',
  deleteCategoryUrl: 'category/deletecategory',

  //Subcategory
  insertSubcategoryUrl: 'subcategory/insertsubcategory',
  setSubcategoryUrl: 'subcategory/setsubcategory',
  checkSubcategoryUrl: 'subcategory/checksubcategory',
  getSubcategoryUrl: 'subcategory/getsubcategory',
  deleteSubcategoryUrl: 'subcategory/deletesubcategory',



  //Uom
  insertUomUrl: 'uom/insertuom',
  setUomUrl: 'uom/setuom',
  checkUomUrl: 'uom/checkuom',
  getUomUrl: 'uom/getuom',
  deleteUomUrl: 'uom/deleteuom',

  //User
  insertUserUrl: 'user/insertuser',
  setUserUrl: 'user/setuser',
  checkUserUrl: 'user/checkuser',
  getUserUrl: 'user/getuser',
  deleteUserUrl: 'user/deleteuser',
  getLoginUserUrl: 'user/getloginuser',


  //Taxation
  insertTaxationUrl: 'taxation/inserttaxation',
  setTaxationUrl: 'taxation/settaxation',
  checkTaxationUrl: 'taxation/checktaxation',
  getTaxationUrl: 'taxation/gettaxation',
  deleteTaxationUrl: 'taxation/deletetaxation',



  //Product
  insertProductUrl: 'product/insertproduct',
  setProductUrl: 'product/setproduct',
  setProductBulkUrl: 'product/setproductbulk',
  setProductAvailableUrl: 'product/setproductbyrestaurantavailable',
  checkProductUrl: 'product/checkproduct',
  getProductUrl: 'product/getproduct',
  getProductListviewUrl: 'product/getproductlistview',
  deleteProductUrl: 'product/deleteproduct',
  insertProductVariationsUrl: 'product/insertproductvariations',
  setProductVariationsUrl: 'product/setproductvariations',
  deleteProductVariationsUrl: 'product/deleteproductvariations',
  getProducttimingUrl: 'product/getproducttiming',
  getProductDishesUrl: 'product/getproductdishes',
  getProductTagUrl: 'product/getproducttag',

  setProductApprovedUrl: 'product/setproductapproved',
  setProductOutofStockUrl: 'product/setproductoutofstock',

  //ImportProduct
  insertImportProductUrl: 'product/insertimportproduct',

  //Taxgroup
  insertTaxgroupUrl: 'taxgroup/inserttaxgroup',
  setTaxgroupUrl: 'taxgroup/settaxgroup',
  checkTaxgroupUrl: 'taxgroup/checktaxgroup',
  getTaxgroupUrl: 'taxgroup/gettaxgroup',
  deleteTaxgroupUrl: 'taxgroup/deletetaxgroup',

  getTaxgroupdetailsUrl: 'taxgroup/gettaxgroupdetails  ',

  //Userrole
  insertUserroleUrl: 'userrole/insertuserrole',
  setUserroleUrl: 'userrole/setuserrole',
  checkUserroleUrl: 'userrole/checkuserrole',
  getUserroleUrl: 'userrole/getuserrole',
  deleteUserroleUrl: 'userrole/deleteuserrole',

  getUserRoleRightsUserUrl: 'userrole/getuserrolerightsbyuserid',
  getUserRoleRightsUrl: 'userrole/getuserrolerights',
  getRestaurantViewUrl: 'userrole/getrestaurantview',


  //Customer
  getCustomerUrl: 'customer/getcustomer',
  getCustomerDetailsUrl: 'customer/getcustomerdetails',
  insertCustomerAddressAdminUrl: 'customer/insertcustomeraddressadmin',
  setCustomerAddressAdminUrl: 'customer/setcustomeraddressadmin',
  deleteCustomerAddressAdminUrl: 'customer/deletecustomeraddressadmin',

  //Cart
  getCartUrl: 'cart/getcart',
  getCartDetailsUrl: 'cart/getcartdetails',
 

  //Brand
  insertBrandUrl: 'brand/insertbrand',
  setBrandUrl: 'brand/setbrand',
  checkBrandUrl: 'brand/checkbrand',
  getBrandUrl: 'brand/getbrand',
  deleteBrandUrl: 'brand/deletebrand',


  //Dishes
  insertDishesUrl: 'dishes/insertdishes',
  setDishesUrl: 'dishes/setdishes',
  checkDishesUrl: 'dishes/checkdishes',
  getDishesUrl: 'dishes/getdishes',
  deleteDishesUrl: 'dishes/deletedishes',

  //Paymentmode
  insertPaymentmodeUrl: 'paymentmode/insertpaymentmode',
  setPaymentmodeUrl: 'paymentmode/setpaymentmode',
  checkPaymentmodeUrl: 'paymentmode/checkpaymentmode',
  getPaymentmodeUrl: 'paymentmode/getpaymentmode',
  deletePaymentmodeUrl: 'paymentmode/deletepaymentmode',



  //Deliverypartner
  insertDeliverypartnerUrl: 'deliverypartner/insertdeliverypartner',
  setDeliverypartnerUrl: 'deliverypartner/setdeliverypartner',
  checkDeliverypartnerUrl: 'deliverypartner/checkdeliverypartner',
  getDeliverypartnerUrl: 'deliverypartner/getdeliverypartner',
  getDeliverypartnerlistviewUrl: 'deliverypartner/getdeliverypartnerlistview',
  deleteDeliverypartnerUrl: 'deliverypartner/deletedeliverypartner',

  setDeliverypartneApprovedrUrl: 'deliverypartner/setdeliverypartnerapproved',

  //------PAGE

  insertPageUrl: 'page/insertpage',
  setPageUrl: 'page/setpage',
  getPageUrl: 'page/getpage',
  getPageListViewUrl: 'page/getpagelistview',
  deletePageUrl: 'page/deletepage',
  getLayoutTypeUrl: 'page/getlayouttype',
  setPageLayoutOrderUrl: 'page/setpagelayoutorderno',

  //Order
  getOrderUrl: 'order/getorder',
  getOrderDetailsUrl: 'order/getorderdetails',
  setOrderStatusUrl:'order/setorderstatus',
  setOrderAssignUrl:'order/setorderassign',
  getOrderCustomerDetailsUrl: 'order/getordercustomerdetails',
  getOrderStatusUrl:'order/getorderstatusbyorder',
  setCancelOrderAdminUrl:'order/setcancelorderadmin',
  getOrderFailedPaidUrl: 'order/getorderbyfailedpaidorder',
  insertOrderAdminUrl: 'order/insertorderadmin',
  setOrderConfimrFailedUrl: 'order/setorderconfirmbyfailedpaid',
  
  //Print
  getinvoiceorderUrl: 'order/getinvoiceorder',
  getprintproductorderUrl: 'order/getprintproductorder',
  getprintorderUrl: 'order/getprintorder',
  getprintinvoiceorderUrl: 'order/getprintinvoiceorder',
  getprintdeliveryorderUrl: 'order/getprintdeliveryorder',

 //Notification
 insertNotificationUrl: 'notification/insertnotification',
 setNotificationUrl: 'notification/setnotification',
 checkNotificationUrl: 'notification/checknotification',
 getNotificationUrl: 'notification/getnotification',
 deleteNotificationUrl: 'notification/deletenotification',


 //Coupon
 insertCouponUrl: 'coupon/insertcoupon',
 setCouponUrl: 'coupon/setcoupon',
 checkCouponUrl: 'coupon/checkcoupon',
 getCouponUrl: 'coupon/getcoupon',
 deleteCouponUrl: 'coupon/deletecoupon',


//Settings
setSettingsUrl: 'settings/setsettings',
getSettingsUrl: 'settings/getsettings',


//Deliverycharges
insertDeliverychargesUrl: 'deliverycharges/insertdeliverycharges',
setDeliverychargesUrl: 'deliverycharges/setdeliverycharges',
checkDeliverychargesUrl: 'deliverycharges/checkdeliverycharges',
getDeliverychargesUrl: 'deliverycharges/getdeliverycharges',
deleteDeliverychargesUrl: 'deliverycharges/deletedeliverycharges',

//Offer
insertOfferUrl: 'offer/insertoffer',
setOfferUrl: 'offer/setoffer',
checkOfferUrl: 'offer/checkoffer',
getOfferListviewUrl: 'offer/getofferlistview',
getOfferUrl: 'offer/getoffer',
deleteOfferUrl: 'offer/deleteoffer',

  //------Report
  getreportsalesUrl: 'report/getreportsales',
  getreportactivitylogUrl: 'report/getreportactivitylog',
  getreportorderitemsummaryUrl: 'report/getreportorderitemsummary',
  getreportdailyappviewerUrl: 'report/getreportdailyappviewer',

    //------Dashboard
    getdashboardadminUrl: 'order/getdashboardadmin',

}





