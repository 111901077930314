import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addtaxgroup',
  templateUrl: './addtaxgroup.component.html',
  styleUrls: ['./addtaxgroup.component.css']
})
export class AddtaxgroupComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;
  ischeck: boolean = false;
  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  taxgroupColl: any = [];
  taxgroupid: number = 0

  taxgroupdetailscoll: any = [];
  //pageColl:any=[];

  taxgroupform = new FormGroup({
    taxgroupid: new FormControl(0),
    //productgroup:new FormControl('',Validators.required),            
    taxgroupname: new FormControl('', Validators.required),
    taxgrouprate: new FormControl(null, Validators.required),
    cgsttaxrate: new FormControl(null),
    sgsttaxrate: new FormControl(null),
    igsttaxrate: new FormControl(null),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Taxgroup";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.taxgroupid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.taxgroupform.reset();
      this.fnInitializeTaxgroup();

      if (this.taxgroupid > 0) {
        this.fngetTaxgroup();
      }
      this.fngetTaxgroupdetails();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.taxgroupid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeTaxgroup() {
    this.taxgroupform.setValue({

      taxgroupid: 0,
      taxgroupname: '',
      taxgrouprate: null,
      cgsttaxrate: null,
      sgsttaxrate: null,
      igsttaxrate: null,
      inactive: false,
    })
  }

  fngetTaxgroupdetails() {
    var data = {
      'taxgroupid': this.taxgroupid,
    }
    this.appService.getTaxgroupdetails(data)
      .subscribe(
        (res) => {

          this.taxgroupdetailscoll = res.json().result;
          console.log(this.taxgroupdetailscoll)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetTaxgroup() {
    var data = {
      'taxgroupid': this.taxgroupid,
    }
    this.appService.getTaxgroup(data)
      .subscribe(
        (res) => {
          this.taxgroupColl = res.json().result;
          var editdata = this.taxgroupColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.taxgroupform.valid) {
        //Check
        var data = {
          'taxgroupid': this.taxgroupid,
          'taxgroupname': this.taxgroupform.get('taxgroupname').value,
          'taxgrouprate': this.taxgroupform.get('taxgrouprate').value,
          'cgsttaxrate': this.taxgroupform.get('cgsttaxrate').value,
          'sgsttaxrate': this.taxgroupform.get('sgsttaxrate').value,
          'igsttaxrate': this.taxgroupform.get('igsttaxrate').value,
          'inactive': this.taxgroupform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'taxgroupdetailscoll': this.taxgroupdetailscoll,
        }
        this.appService.fnCheckTaxgroup(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.taxgroupform.get('taxgroupid').value > 0) {
                //Update Department
                this.fnSetTaxgroup(data);
              }
              else {
                //Insert Department
                this.fnInsertTaxgroup(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.taxgroupform.controls['taxgroupname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertTaxgroup(data) {

    this.appService.fnInsertTaxgroup(data).subscribe(
      (res) => {
        if (res.json().status == 200) {
          this.toastr.success('', "The new taxgroup has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.taxgroupname);

          this.fnCloseTaxgroup();
        } else {
          console.log(res.json())
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetTaxgroup(data) {

    this.appService.fnSetTaxgroup(data).subscribe(
      (res) => {
        if (res.json().status == 200) {
          this.toastr.success('', "The taxgroup has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.taxgroupid, data.taxgroupname);

          this.fnCloseTaxgroup();
        } else {
          console.log(res.json())
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  
  fngetTaxrate() {


  }

  fnCalcTaxgrouprate() {

    
    let taxrate = 0;
    var _cgsttaxrate = this.taxgroupform.get('cgsttaxrate').value
    var _sgsttaxrate = this.taxgroupform.get('sgsttaxrate').value
    var _igsttaxrate = this.taxgroupform.get('igsttaxrate').value
    if (_cgsttaxrate > 0 && _sgsttaxrate > 0) {
      taxrate = Number(_cgsttaxrate) + Number(_sgsttaxrate)

    }
    else {
      taxrate = _igsttaxrate
    }

    if (taxrate == null || taxrate === undefined) {
      taxrate = 0;
    }

    this.taxgroupform.patchValue({ taxgrouprate: taxrate })
    

  }
  checkValue(values: any, index) {
    this.taxgroupdetailscoll[index].ischeck = values.currentTarget.checked;

    this.fnCalcTaxgrouprate()

  }


  //Close
  fnCloseTaxgroup() {
    try {
      this.router.navigate(['/Taxgroup']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.taxgroupform.setValue({

      taxgroupid: deptBO.taxgroupid,
      taxgroupname: deptBO.taxgroupname,
      taxgrouprate: deptBO.taxgrouprate,
      cgsttaxrate: deptBO.cgsttaxrate,
      sgsttaxrate: deptBO.sgsttaxrate,
      igsttaxrate: deptBO.igsttaxrate,
      inactive: deptBO.inactive,

    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
