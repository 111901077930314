import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-outofstockoption',
  templateUrl: './outofstockoption.component.html',
  styleUrls: ['./outofstockoption.component.css']
})
export class OutofstockoptionComponent implements OnInit {

  @Input() productBO:any={};

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService, private loginService: LoginDataService,
    private toastr: ToastrService, private productFindService: NgbModal, private datePipe: DatePipe) { }

  //Save Validation
  submitted: boolean;
  successfully: boolean;
  
  isduplicate: boolean;

 productform = new FormGroup({  
    avalablemethod: new FormControl(null, Validators.required),
   avalabledate: new FormControl(null),
   avalabletime:new FormControl(null),
  });

 
  


  @Input()  avalablemethod: any = null;
  @Input()  avalabledate: any = null;

  @Input() methodColl: any = [];
  

  
 

  @Output() emitData = new EventEmitter();

  ngOnInit() {

    this.productform.reset();
    this.fnInitializeFormGroup();
    this.fngetMethod();
    
  }


  fnInitializeFormGroup() {
    this.productform.setValue({      
      avalablemethod: this.avalablemethod,
      avalabledate: null,     
      avalabletime: null, 
    })
  }
  fngetMethod() {
    this.methodColl = []
    this.methodColl.push({ value: 'TWOHOURS', displayname: 'Two hours' })
    this.methodColl.push({ value: 'FOURHOURS', displayname: 'Four Hours' })
    this.methodColl.push({ value: 'NEXTDAY', displayname: 'Next Day' })
    this.methodColl.push({ value: 'PERMANENT', displayname: 'Permanent' })
    this.methodColl.push({ value: 'CUSTOM', displayname: 'Custom' })
  }

 

  fnSaveBtnClick() {
    try {
     

      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.productform.valid) {
      
        //Check
        var data = {
          avalablemethod: this.productform.get('avalablemethod').value,         
          //avalabledate:this.productform.get('avalabledate').value,  
          avalabledate:this.productform.get('avalablemethod').value==="CUSTOM"?this.fnFormatDatetime(this.productform.get('avalabledate').value) +' '+this.productform.get('avalabletime').value:null,
        }


        this.emitData.emit(data);
        this.activeModal.close(true);


      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }
  }

  fnFormatTime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "HH:mm:ss")
      }

    } else {
      return null
    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
