import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['./addsubcategory.component.css']
})
export class AddsubcategoryComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;



  //Edit Get Values
  subcategoryColl: any = [];
  categoryColl: any = [];
  subcategoryid: number = 0

  //pageColl:any=[];

  subcategoryform = new FormGroup({
    subcategoryid: new FormControl(0),
    categoryid: new FormControl('', Validators.required),
    //productgroup:new FormControl('',Validators.required),            
    subcategoryname: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });




  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Subcategory";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.subcategoryid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.subcategoryform.reset();
      this.fnInitializeSubcategory();

      if (this.subcategoryid > 0) {
        this.fngetSubcategory();

      }
      this.fngetCategory();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.subcategoryid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeSubcategory() {
    this.subcategoryform.setValue({

      subcategoryid: 0,
      categoryid: null,
      subcategoryname: '',
      inactive: false,
    })
  }


  fngetCategory() {


    this.fnShowProgress();
    var data = {
      'categoryid': 0,
      'restaurantid':this.loginService.fnGetModRestaruntId()
    }
    this.appService.getCategory(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.categoryColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetSubcategory() {
    var data = {
      'subcategoryid': this.subcategoryid,
      'restaurantid':this.loginService.fnGetModRestaruntId()
    }
    this.appService.getSubcategory(data)
      .subscribe(
        (res) => {
          this.subcategoryColl = res.json().result;
          var editdata = this.subcategoryColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.subcategoryform.valid) {
        //Check
        var data = {
          'subcategoryid': this.subcategoryid,
          'categoryid': this.subcategoryform.get('categoryid').value,
          'restaurantid':this.loginService.fnGetModRestaruntId(),
          'subcategoryname': this.subcategoryform.get('subcategoryname').value,
          'inactive': this.subcategoryform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckSubcategory(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.subcategoryform.get('subcategoryid').value > 0) {
                //Update Department
                this.fnSetSubcategory(data);
              }
              else {
                //Insert Department
                this.fnInsertSubcategory(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.subcategoryform.controls['subcategoryname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertSubcategory(data) {

    this.appService.fnInsertSubcategory(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Subcategory has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.subcategoryid);

          this.fnCloseSubcategory();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetSubcategory(data) {

    this.appService.fnSetSubcategory(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The SubCategory has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.subcategoryid, data.subcategoryname);

          this.fnCloseSubcategory();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseSubcategory() {
    try {
      this.router.navigate(['/Subcategory']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.subcategoryform.setValue({

      subcategoryid: deptBO.subcategoryid,
      categoryid: deptBO.categoryid,
      subcategoryname: deptBO.subcategoryname,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
