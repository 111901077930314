import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-adddishespopup',
  templateUrl: './adddishespopup.component.html',
  styleUrls: ['./adddishespopup.component.css']
})
export class AdddishespopupComponent implements OnInit {
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  public imagePath;
  imgURL: any;
  public message: string;

  //Edit Get Values
  dishesColl: any = [];
  dishesid: number = 0

  //pageColl:any=[];

  dishesform = new FormGroup({
    dishesid: new FormControl(0),
    dishesname: new FormControl('', Validators.required),
    dishesimage: new FormControl(null), 
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "dishes";

  @Input() dishesname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.dishesid = params.id
      }
    });
    this.fnServiceChanges();
  }

  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {

    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.dishesform.reset();
      this.fnInitializeDishes();

      if (this.dishesid > 0) {
        this.fngetDishes();
      }

    } catch (ex) {

    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.dishesid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeDishes() {
    this.dishesform.setValue({

      dishesid: 0,
      dishesname: '',
      dishesimage: null,
      inactive: false,
    })
  }




  //Get 
  fngetDishes() {
    var data = {
      'dishesid': 0,
    }
    this.appService.getDishes(data)
      .subscribe(
        (res) => {
          this.dishesColl = res.json().result;


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {

    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.dishesform.valid) {
        //Check
        var data = {
          'dishesid': this.dishesid,
          'dishesname': this.dishesform.get('dishesname').value,
          dishesimage: this.strImage,
          picurl: this.picurl,
          'inactive': this.dishesform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckDishes(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {

              //Insert Department
              this.fnInsertDishes(data);

            }
            else {
              if (_checkresult === "name") {
                this.dishesform.controls['dishesname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {

    }
  }

  //Insert
  fnInsertDishes(data) {

    this.appService.fnInsertDishes(data).subscribe(
      (res) => {
        
        if (res.json().status === 200) {
          console.log(res.json())
          var result = res.json().result;
       
          var dishesid = 0;
          if (result.length > 0) {
            dishesid = result[0].dishesid
          }

          this.toastr.success('', "The new dishes has been added successfully.Insert Successfully");

          this.emitData.emit(dishesid);
          this.activeModal.close(true);


        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}