import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-restaurantfind',
  templateUrl: './restaurantfind.component.html',
  styleUrls: ['./restaurantfind.component.css']
})
export class RestaurantfindComponent implements OnInit, AfterViewInit, OnDestroy {

  
  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  restaurantColl: any = [];
  filterrestaurantColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = " Restaurant";

  filterby: string = "";


  private gridApi;
  private gridColumnApi;

  

  columnDefs = [
    {
      headerName: 'Operator',
      checkboxSelection: false,
      headerCheckboxSelection: false,
      filter: false,
      sortable: false,
      pinned: 'left',
      field: 'ischeck',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input');
        input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true;
          params.data.ischeck = true;
        } else {
          input.checked = false;
          params.data.ischeck = false;
        }
        input.addEventListener('click', function (event) {        
          input.checked != input.checked;
          params.data.ischeck = input.checked;
        });
        return input;
      }
    },      
    { headerName: 'Restaurant Code', field: 'restaurantcode', sortable: true, filter: true },
    { headerName: 'Restaurant Name', field: 'restaurantname', sortable: true, filter: true },
    { headerName: 'Company', field: 'companyname', sortable: true, filter: true },
    { headerName: 'Address', field: 'address', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 30,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  constructor(private appService: DhukanDataService, public router: Router, private activeModal: NgbActiveModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetRestaurant();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetRestaurant() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'restaurantid': 0,
      'filterby': this.filterby,
    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.restaurantColl = res.json().result;
            //this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  
 

  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


 
  public accept() {

        
    for(let wbo of this.restaurantColl){
      if(wbo.ischeck===true){
        this.filterrestaurantColl.push(wbo);
      }      
    }
 
    this.emitData.emit(this.filterrestaurantColl);  
    this.activeModal.close(true);  
  }
 
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}


// import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, EventEmitter, Input, Output } from '@angular/core';
// import { DataTableDirective } from 'angular-datatables';
// import { Subject } from 'rxjs';
// import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
// import { Router, NavigationExtras } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
// import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// @Component({
//   selector: 'app-restaurantfind',
//   templateUrl: './restaurantfind.component.html',
//   styleUrls: ['./restaurantfind.component.css']
// })
// export class RestaurantfindComponent implements OnInit, AfterViewInit, OnDestroy {

  
//   @Input() doctype: string;
//   @Input() req:{};
//   @Input() btnOkText: string;
//   @Input() btnCancelText: string;
//   @Input() msgboxtype: string;
//   @Output() emitData = new EventEmitter();

//   restaurantColl: any = [];
//   filterrestaurantColl: any = [];

//   isadd: boolean = false;
//   isedit: boolean = false;
//   isdelete: boolean = false;
//   isview: boolean = false;
//   isprint: boolean = false;
//   isexport: boolean = false;

//   isloading: boolean = false;
//   isprivilage: boolean = false;
//   showProgress: boolean = false;
//   formname: String = " Restaurant";

//   filterby: string = "";

//   searchtxt: any = "";

//   columnDefs = [
//     { headerName: 'Restaurant Code', field: 'restaurantcode' },
//     { headerName: 'Restaurant Name', field: 'restaurantname'},
//     { headerName: 'Company', field: 'companyname' },
//     { headerName: 'Address', field: 'address' },
//   ]

//   gridOptions = {
//     enableactioncol: true,
//     columnDefs: this.columnDefs,
//   };

  

//   constructor(private appService: DhukanDataService, public router: Router, private activeModal: NgbActiveModal,
//     private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

//   //User Check
//   fnServiceChanges() {
//     this.loginService.serviceloginChange.subscribe((value) => {
//       if (this.loginService.fnCheckUser()) {
//         this.fnCheckPrivilage();
//         if (this.isview) {
//           this.isloading = true;
//           this.fnOnInitForm();
//         }
//         else {
//           this.fnUserFailedForm();
//         }
//       }
//       else {
//         this.fnUserFailedForm();
//       }
//     });
//   }


//   ngOnInit() {
//     try {



//       if (this.loginService.fnCheckUser()) {
//         this.fnCheckPrivilage();
//         if (this.isview) {
//           this.isloading = true;
//           this.fnOnInitForm();
//         }
//         else {
//           this.fnUserFailedForm();
//         }
//       }
//       else {
//         this.loginService.fnGetUserRoleList();
//       }

//     } catch (ex) {
//       this.fnShowErrorMsg(ex);
//     }
//   }

//   fnOnInitForm() {
//     this.fngetRestaurant();
//   }

//   fnCheckPrivilage() {

//     this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
//     this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
//     this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
//     this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
//     this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
//     this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
//   }

//   fnUserFailedForm() {
//     this.isprivilage = true;
//   }



//   ngAfterViewInit(): void {

//   }

//   ngOnDestroy(): void {

//   }

//   fngetRestaurant() {

//     if (this.showProgress) {
//       return;
//     }

//     this.fnShowProgress();
//     var data = {
//       'restaurantid': 0,
//       'filterby': this.filterby,
//     }
//     this.appService.getRestaurant(data)
//       .subscribe(
//         (res) => {

//           this.fnHideProgress();
//           if (res.json().status == 200) {
//             this.restaurantColl = res.json().result;
//             //this.fnSetDataTable();
//           }
//           else if (res.json().status == 400) {
//             this.toastr.error('Error', res.json().err_field);
//           }


//         },
//         (err) => {
//           this.fnHideProgress();
//           this.toastr.error('Error', err);
//         })
//   }


  
 
//   public accept() {

        
//     for(let wbo of this.restaurantColl){
//       if(wbo.ischeck===true){
//         this.filterrestaurantColl.push(wbo);
//       }      
//     }
 
//     this.emitData.emit(this.filterrestaurantColl);  
//     this.activeModal.close(true);  
//   }
 
//   public decline() {
//     this.activeModal.close(false);
//   }


//   private fnShowProgress() {
//     this.showProgress = true;
//   }
//   private fnHideProgress() {
//     this.showProgress = false;
//   }


//   private fnShowErrorMsg(ex) {
//     this.toastr.warning('Internal Error', ex);
//   }




// }
