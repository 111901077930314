import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addsettings',
  templateUrl: './addsettings.component.html',
  styleUrls: ['./addsettings.component.css']
})
export class AddsettingsComponent implements OnInit  {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  

  //Edit Get Values
  settingsColl: any = [];
  settingsid: number = 0

  //pageColl:any=[];

  settingsform = new FormGroup({
    settingsid: new FormControl(0),
    mode: new FormControl('', Validators.required),
    settingstype: new FormControl('', Validators.required),
    settingsname: new FormControl('', Validators.required),
    settingsvalue: new FormControl('', Validators.required),
    linktype: new FormControl(''),
    linktargetid: new FormControl(null),
    inactive: new FormControl(false),
  });

 

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Settings";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.settingsid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.settingsform.reset();
      this.fnInitializeSettings();

      if (this.settingsid > 0) {
        this.fngetSettings();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.settingsid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeSettings() {
    this.settingsform.setValue({

      settingsid: 0,
      mode: '',
      settingstype: '',
      settingsname: '',
      settingsvalue:'',
      linktype: '',
      linktargetid: null,
      inactive: false,
    })
  }




  //Get 
  fngetSettings() {
    var data = {
      'settingsid': this.settingsid,
    }
    this.appService.getSettings(data)
      .subscribe(
        (res) => {
          this.settingsColl = res.json().result;
          var editdata = this.settingsColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.settingsform.valid) {
        //Check
        var data = {
          'settingsid': this.settingsid,
          'mode':this.settingsform.get('mode').value,
          'settingstype': this.settingsform.get('settingstype').value,
          'settingsname': this.settingsform.get('settingsname').value,
          'settingsvalue': this.settingsform.get('settingsvalue').value,
          'linktype': this.settingsform.get('linktype').value,
          'linktargetid': this.settingsform.get('linktargetid').value,
          'inactive': this.settingsform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
      
           
                //Update Department
                this.fnSetSettings(data);
  
         
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

 
  //Set
  fnSetSettings(data) {

    this.appService.fnSetSettings(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Settings has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.settingsid, data.settingsname);

          this.fnCloseSettings();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseSettings() {
    try {
      this.router.navigate(['/Settings']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.settingsform.setValue({
      settingsid: deptBO.settingsid,
      mode: deptBO.mode,
      settingstype: deptBO.settingstype,
      settingsname: deptBO.settingsname,
      settingsvalue: deptBO.settingsvalue,
      linktype: deptBO.linktype,
      linktargetid: deptBO.linktargetid,
      inactive: deptBO.inactive,
    });
   
    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }








  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
