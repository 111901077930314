import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addproducttagtype',
  templateUrl: './addproducttagtype.component.html',
  styleUrls: ['./addproducttagtype.component.css']
})
export class AddproducttagtypeComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  public imagePath;
  imgURL: any;
  public message: string;

  //Edit Get Values
  producttagtypeColl:any=[];
  producttagtypeid:number=0
  
  //pageColl:any=[];
  
  producttagtypeform=new FormGroup({        
    producttagtypeid:new FormControl(0),          
    producttagtypename:new FormControl('',Validators.required),  
    producttagtypeimage: new FormControl(null),      
    inactive:new FormControl(false),     
  });
   //set image
   image;
   strImage: any;
   picurl: string = "";
   picname: string = "";
 


  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Producttagtype";
  
  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.producttagtypeid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.producttagtypeform.reset();
         this.fnInitializeProductTag(); 
  
         if(this.producttagtypeid>0){
           this.fngetProductTag();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.producttagtypeid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeProductTag(){
     this.producttagtypeform.setValue({
       
      producttagtypeid:0, 
      producttagtypename:'',
      producttagtypeimage: null,    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetProductTag() {        
       var data = {
         'producttagtypeid':this.producttagtypeid,            
       }
       this.appService.getProducttagtype(data)
       .subscribe(
         (res)=>{                  
           this.producttagtypeColl=res.json().result;
           var editdata =this.producttagtypeColl[0];
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.producttagtypeform.valid){
           //Check
           var data = {
                 'producttagtypeid': this.producttagtypeid,                   
                 'producttagtypename':this.producttagtypeform.get('producttagtypename').value,
                 producttagtypeimage: this.strImage,
                 picurl: this.picurl,                 
                 'inactive':this.producttagtypeform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckProducttagtype(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.producttagtypeform.get('producttagtypeid').value>0){
                         //Update Department
                         this.fnSetProductTag(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertProductTag(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.producttagtypeform.controls['producttagtypename'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertProductTag(data){    
   
     this.appService.fnInsertProducttagtype(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new producttagtype has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.producttagtypename);
  
         this.fnCloseProductTag();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetProductTag(data){    
  
  this.appService.fnSetProducttagtype(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The producttagtype has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.producttagtypeid,data.producttagtypename);
  
       this.fnCloseProductTag();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseProductTag(){
  try{
    this.router.navigate(['/Producttagtype']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  private fnEditMode(deptBO){
  
   this.producttagtypeform.setValue({

    producttagtypeid:deptBO.producttagtypeid,
    producttagtypename:deptBO.producttagtypename,
    producttagtypeimage: null,
        inactive:deptBO.inactive,   
   });
   this.picurl = deptBO.picurl;
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  
  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }
  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
       
      //   });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  
  }
  