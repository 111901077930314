import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-addcity',
  templateUrl: './addcity.component.html',
  styleUrls: ['./addcity.component.css']
})
export class AddcityComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  cityColl:any=[];
  cityid:number=0
  

  areaColl:any=[];
  //pageColl:any=[];
  
  cityform=new FormGroup({        
    cityid:new FormControl(0),     
    //productgroup:new FormControl('',Validators.required),            
    cityname:new FormControl('',Validators.required),   
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname: String = "City";
  
  
  searchtxt: any = "";

  columnDefs = [
    { headerName: 'Area Name', field: 'areaname' },   
    { headerName: 'Pincode', field: 'pincode' },   

  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  
  constructor(private appService: DhukanDataService,private route: ActivatedRoute, private confirmationDialogService: ConfirmationDialogService,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.cityid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.cityform.reset();
         this.fnInitializeCuisine(); 
  
         if(this.cityid>0){
           this.fngetCity();
           this.fngetArea();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.cityid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeCuisine(){
     this.cityform.setValue({
       
      cityid:0, 
      cityname:'',    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetCity() {        
       var data = {
         'cityid':this.cityid,            
       }
       this.appService.getCity(data)
       .subscribe(
         (res)=>{                  
           this.cityColl=res.json().result;
           var editdata =this.cityColl[0];
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  

  fngetArea() {        
    var data = {
      'cityid':this.cityid,            
    }
    this.appService.getArea(data)
    .subscribe(
      (res)=>{                  
        this.areaColl=res.json().result;
       
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })
}
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.cityform.valid){
           //Check
           var data = {
                 'cityid': this.cityid,                   
                 'cityname':this.cityform.get('cityname').value,                 
                 'inactive':this.cityform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckCity(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.cityform.get('cityid').value>0){
                         //Update Department
                         this.fnSetCity(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertCity(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.cityform.controls['cityname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertCity(data){    
   
     this.appService.fnInsertCity(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new City has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.cityname);
  
         this.fnCloseCity();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetCity(data){    
  
  this.appService.fnSetCity(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The City has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.cityid,data.cityname);
  
       this.fnCloseCity();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseCity(){
  try{
    this.router.navigate(['/City']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  private fnEditMode(deptBO){
  
   this.cityform.setValue({

    cityid:deptBO.cityid,
    cityname:deptBO.cityname,
        inactive:deptBO.inactive,   
   });
  
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  
  fnAddArea(){
    try{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'cityid': this.cityid,
        }
        }
  
      this.router.navigate(['/AddArea'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  
  
  fnActionEditClick(data) {
    

    this.fnEditArea(data.areaid);

    
  }

  fnActionDeleteClick(data) {
   
    this.fnDeleteConfirm(data.cityid);
  }


  fnEditArea(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
            'id': deptid,
            'cityid': this.cityid,
        }
        }
  
      this.router.navigate(['/AddArea'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Area?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteArea(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteArea(deptid){
       var data = {
         'areaid': deptid,            
       }
      this.appService.fnDeleteCity(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Area has been deleted successfully.");                 

             //this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetArea();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
       
      //   });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  

}
