import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addproductvariation',
  templateUrl: './addproductvariation.component.html',
  styleUrls: ['./addproductvariation.component.css']
})
export class AddproductvariationComponent implements OnInit {


  @Input() doctype: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() productvariationsid: number = 0;
  @Input() productid: number = 0;
  @Input() variationsname: string = '';
  @Input() uomid: number = null;
  @Input() uomqty: number = 0;
  @Input() price: number = 0;
  @Input() cost: number = 0;
  @Input() mrp: number = 0;
  
  @Input() isoutofstock: number = 0;
  @Input() isdisplay: any = false;

  @Input() inactive: number = 0;

  productvariationform = new FormGroup({
    productid: new FormControl(0),
    variationsname: new FormControl('', Validators.required),
    uomid: new FormControl(null, Validators.required),
    uomqty: new FormControl(0, Validators.required),
    price: new FormControl(0),
    cost: new FormControl(0),
    mrp: new FormControl(null),
    isoutofstock: new FormControl(false),
    isdisplay: new FormControl(false),
    inactive: new FormControl(false),
  });

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;

  //Master
  uomColl: any = [];


  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService,
    private loginService: LoginDataService, private toastr: ToastrService) { }

  ngOnInit() {

    this.fngetUom();

  }

  public fnInitializeProductFormGroup() {
    this.productvariationform.patchValue({
      productid: this.productid,
      variationsname: this.variationsname,
      uomid: this.uomid,
      uomqty: this.uomqty,
      price: this.price,
      cost: this.cost,
      mrp: this.mrp,
      isoutofstock: this.isoutofstock,
      
      isdisplay: this.isdisplay,
      inactive: this.inactive,
    })


  }

  fngetUom() {
    var data = {
      'uomid': 0,
      'restaurantid': this.loginService.fnGetModRestaruntId()
    }
    this.appService.getUom(data)
      .subscribe(
        (res) => {
          this.uomColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Save Btn Click
  fnSaveBtnClick() {

    try {
      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.productvariationform.valid) {

        this.fnShowProgress();

        //Check
        var data = {
          'productvariationsid': this.productvariationsid,
          'productid': this.productid,
          'variationsname': this.productvariationform.get('variationsname').value,
          'uomid': this.productvariationform.get('uomid').value,
          'uomqty': this.productvariationform.get('uomqty').value,
          'price': this.productvariationform.get('price').value,
          'isoutofstock': this.productvariationform.get('isoutofstock').value,
          'isdisplay': this.productvariationform.get('isdisplay').value,
          'cost': this.productvariationform.get('cost').value,
          'mrp': this.productvariationform.get('mrp').value,         
          'inactive': this.productvariationform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.emitData.emit(data);
        this.activeModal.close(true);
      }
    } catch (ex) {


      this.fnHideProgress();
      this.fnShowErrorMsg(ex);
    }
  }


  public decline() {
    this.activeModal.dismiss();
  }





  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }



}
