import { MapsAPILoader } from '@agm/core';
import { ElementRef, NgZone } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';


@Component({
  selector: 'app-addarea',
  templateUrl: './addarea.component.html',
  styleUrls: ['./addarea.component.css']
})
export class AddareaComponent implements OnInit {

  //MAP
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  //set image
  image;
  strImage: any;

  picurl: string = "";
  picname: string = "";

  list = [];
  //Edit Get Values
  cityColl: any = [];
  areaColl: any = [];
  areaid: number = 0

  cityid: number = 0;

  areaform = new FormGroup({
    areaname: new FormControl('', Validators.required),
    areaid: new FormControl(0),
    cityid: new FormControl(null, Validators.required),
    pincode: new FormControl(''),
    latitude: new FormControl(0),
    longitude: new FormControl(0),
    iconurl: new FormControl(''),
    iconimage: new FormControl(null),
    isdisplay: new FormControl(false),
    radius: new FormControl(0),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Area";

  latitude = 13.0827;
  longitude = 80.2707;

  markers = [];
  mapType = 'roadmap';
  center:any;
  polygon: any[];
  
  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private http: Http,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.areaid = params.id
      }
      if (params.cityid !== undefined) {
        this.cityid = params.cityid
      }

    });

    this.fnServiceChanges();
  }

  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.isaddmode = true;
    this.areaform.reset();
    this.fnInitializeAreaFormGroup();
    if (this.areaid > 0) {
      this.fngetArea();
    }
    this.fngetCity();
    this.fnSetAutoCompleteArea();
  }

  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.areaid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnInitializeAreaFormGroup() {
    this.areaform.setValue({
      areaname: '',
      areaid: 0,
      cityid: null,
      pincode: '',
      latitude: 0,
      longitude: 0,
      iconurl: '',
      iconimage: null,
      radius: 0,
      isdisplay: false,
      inactive: false,
    })
  }

  fnSetAutoCompleteArea() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: 'IN' },
        types: ["establishment"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }



          this.areaform.setValue({
            areaname: place.name,
            latitude: parseFloat(place.geometry.location.lat().toString()).toFixed(4),
            longitude: parseFloat(place.geometry.location.lng().toString()).toFixed(4),
            areaid: this.areaform.get('areaid').value,
            cityid: this.areaform.get('cityid').value,
            inactive: this.areaform.get('inactive').value,
            pincode: this.areaform.get('pincode').value,
          });


          //set latitude, longitude and zoom
          //this.latitude = place.geometry.location.lat();
          // this.longitude = place.geometry.location.lng();
          //this.position = place.address_components[0].short_name;
          // this.zoom = 12;
        });
      });
    });
  }


  //Get 
  fngetCity() {
    var data = {
      'cityid': 0,
      'warehouseid': 0
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetArea() {
    var data = {
      'areaid': this.areaid,
    }
    this.appService.getArea(data)
      .subscribe(
        (res) => {
          this.areaColl = res.json().result;
          var editdata = {
            'areaid': this.areaColl[0].areaid,
            'areaname': this.areaColl[0].areaname,
            'cityid': this.areaColl[0].cityid,
            'latitude': this.areaColl[0].latitude,
            'longitude': this.areaColl[0].longitude,
            'pincode': this.areaColl[0].pincode,
            iconurl: this.areaColl[0].iconurl,
            radius: this.areaColl[0].radius,
            'isdisplay': this.areaColl[0].isdisplay,
            'inactive': this.areaColl[0].inactive,
          }
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.areaform.valid) {
        //Check
        var data = {
          'areaid': this.areaid,
          'areaname': this.areaform.get('areaname').value,
          'cityid': this.areaform.get('cityid').value,
          'pincode': this.areaform.get('pincode').value,
          'latitude': this.areaform.get('latitude').value,
          'longitude': this.areaform.get('longitude').value,
          'iconimage': this.strImage,
          'iconurl': this.picurl,
          'isdisplay': this.areaform.get('isdisplay').value,
          'radius': this.areaform.get('radius').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckArea(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.areaform.get('areaid').value > 0) {
                //Update Department
                this.fnSetArea(data);
              }
              else {
                //Insert Department
                this.fnInsertArea(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.areaform.controls['areaname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertArea(data) {

    this.appService.fnInsertArea(data).subscribe(
      (res) => {

        if (res.json().result != "") {
          this.toastr.success('', "The new area has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.areaname);

          this.fnCloseArea();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetArea(data) {

    this.appService.fnSetArea(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The area has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.areaid, data.areaname);

          this.fnCloseArea();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseArea() {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.cityid,
        }
      }

      this.router.navigate(['/AddCity'], navigationExtras);


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {


    this.areaform.setValue({
      areaname: deptBO.areaname,
      areaid: deptBO.areaid,
      cityid: deptBO.cityid,
      pincode: deptBO.pincode,
      latitude: deptBO.latitude,
      longitude: deptBO.longitude,
      iconurl: deptBO.iconurl,
      iconimage: null,
      radius: deptBO.radius,
      isdisplay: deptBO.isdisplay,
      inactive: deptBO.inactive,
    });

    this.picurl = deptBO.iconurl;

    this.searchCode();

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  searchCode(){
    
    this.CallGeoAPI(this.areaform.get("pincode").value).subscribe(res=>{
      console.log(res.json().results);
      var grores=res.json().results

      const result = grores[0];
   
      this.center = result.geometry.location;
      this.areaform.patchValue({ "latitude": this.center.lat, "longitude": this.center.lng });
      
      this.polygon = [
        {
          lat: result.geometry.bounds.northeast.lat,
          lng: result.geometry.bounds.northeast.lng
        },
        {
          lat: result.geometry.bounds.northeast.lat,
          lng: result.geometry.bounds.southwest.lng
        },
        {
          lat: result.geometry.bounds.southwest.lat,
          lng: result.geometry.bounds.southwest.lng
        },
        {
          lat: result.geometry.bounds.southwest.lat,
          lng: result.geometry.bounds.northeast.lng
        },
      ]
    })
    
  }

  CallGeoAPI(code) {
    let apiURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key=AIzaSyClUICP4-qGf2r4SSCZF5MzbSFXT6mIbvM`;
    return this.http.get(apiURL);
  }


  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options:any = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
  }


  //Image Import
  changeListener($event): void {
    try {
      console.log($event.target)
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }


  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });
    } catch {

    }

  }


  addMarker(lat: number, lng: number) {
    this.markers.push({ lat, lng, alpha: 0.4 });
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
