import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-adddeliverycharges',
  templateUrl: './adddeliverycharges.component.html',
  styleUrls: ['./adddeliverycharges.component.css']
})
export class AdddeliverychargesComponent implements OnInit{

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

 

  //Edit Get Values
  deliverychargesColl: any = [];
  deliverychargeid: number = 0

  taxgroupColl:any=[];

  deliverychargesform = new FormGroup({
    deliverychargeid: new FormControl(0),
    fromamt: new FormControl(null, Validators.required),
    toamt: new FormControl(null, Validators.required),
    chargeamt: new FormControl(null, Validators.required),
    taxgroupid: new FormControl(null),
    inactive: new FormControl(false),
  });

  
  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Deliverycharges";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.deliverychargeid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.deliverychargesform.reset();
      this.fnInitializeCuisine();

      if (this.deliverychargeid > 0) {
        this.fngetDeliverycharges();
      }
      this.fngetTaxgroup();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.deliverychargeid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeCuisine() {
    this.deliverychargesform.setValue({

      deliverychargeid: 0,
      fromamt: null,
      toamt: null,
      chargeamt: null,
      taxgroupid: null,
      inactive: false,
    })
  }

  fngetTaxgroup() {
    var data = {
      'taxgroupid': 0,
    }
    this.appService.getTaxgroup(data)
      .subscribe(
        (res) => {
          this.taxgroupColl = res.json().result;
         
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetDeliverycharges() {
    var data = {
      'deliverychargeid': this.deliverychargeid,
    }
    this.appService.getDeliverycharges(data)
      .subscribe(
        (res) => {
          this.deliverychargesColl = res.json().result;
          var editdata = this.deliverychargesColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.deliverychargesform.valid) {
        //Check
        var data = {
          'deliverychargeid': this.deliverychargeid,
          'fromamt': this.deliverychargesform.get('fromamt').value,
          'toamt': this.deliverychargesform.get('toamt').value,
          'chargeamt': this.deliverychargesform.get('chargeamt').value,
          'taxgroupid': this.deliverychargesform.get('taxgroupid').value,
          'inactive': this.deliverychargesform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckDeliverycharges(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.deliverychargesform.get('deliverychargeid').value > 0) {
                //Update Department
                this.fnSetDeliverycharges(data);
              }
              else {
                //Insert Department
                this.fnInsertDeliverycharges(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.deliverychargesform.controls['chargeamt'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertDeliverycharges(data) {

    this.appService.fnInsertDeliverycharges(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Deliverycharges has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.chargeamt);

          this.fnCloseDeliverycharges();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetDeliverycharges(data) {

    this.appService.fnSetDeliverycharges(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Deliverycharges has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.deliverychargeid, data.chargeamt);

          this.fnCloseDeliverycharges();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseDeliverycharges() {
    try {
      this.router.navigate(['/Deliverycharges']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.deliverychargesform.setValue({
      deliverychargeid: deptBO.deliverychargeid,
      fromamt: deptBO.fromamt,
      toamt : deptBO.toamt,
      chargeamt: deptBO.chargeamt,
      taxgroupid: deptBO.taxgroupid,
      inactive: deptBO.inactive,
    });
   

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


 






  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
