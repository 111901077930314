import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, AfterViewInit, OnDestroy {


  orderColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Order";

  filterby: String = "PLACED";
  searchtxt: any = "";
  fromdate: any = null;
  todate: any = null;


  columnDefs = [
    { headerName: 'Order No', field: 'orderno' },
    {
      headerName: 'Order Date', field: 'orderdate', type: 'datetime'
    },
    { headerName: 'Store', field: 'restauranttype' },
    { headerName: 'Restaurant Name', field: 'restaurantname' },
    { headerName: 'Customer', field: 'firstname' },
    { headerName: 'Mobile', field: 'mobile' },
    { headerName: 'City', field: 'cityname' },
   
    { headerName: 'Status', field: 'status' },
    { headerName: 'Payment', field: 'paymentmodename' },
    { headerName: 'Total Product', field: 'productcountorg' },
    { headerName: 'Total Qty', field: 'qtytotalorg' },
    { headerName: 'Grand Total', field: 'grandtotalorg', type: 'decimal' },
  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };

  orderplacedcount: number = 0;
  confirmcount: number = 0;
  readytopickcount: number = 0;
  pickedcount: number = 0;
  deliverycount: number = 0;
  cancelledcount: number = 0;
  allcount: number = 0;



  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe) {
    this.fromdate = new Date();
    this.todate = new Date();

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {



      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetStatus();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            console.log(res.json().result)
            let statusColl = res.json().result;

            // this.statusColl=[];

            for (let wbo of statusColl) {
              if (wbo.statusname === 'PLACED') { this.orderplacedcount = wbo.ordercount }
              if (wbo.statusname === 'CONFIRM') { this.confirmcount = wbo.ordercount }
              if (wbo.statusname === 'READYTOPICK') { this.readytopickcount = wbo.ordercount }
              if (wbo.statusname === 'PICKED') { this.pickedcount = wbo.ordercount }
              if (wbo.statusname === 'DELIVERED') { this.deliverycount = wbo.ordercount }
              if (wbo.statusname === 'CANCELLED') { this.cancelledcount = wbo.ordercount }              
              if (wbo.statusname === 'ALL') { this.allcount = wbo.ordercount }

            }

            // let result=res.json().result[0];
            // this.orderplacedcount=result.placedcount;
            // this.confirmcount=result.confirmcount;                       
            // this.readytopickcount=result.readytopickcount;
            // this.pickedcount=result.pickedcount;
            // this.deliverycount=result.deliveredcount;              
            // this.allcount=result.allcount;


            this.fngetOrder();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetReStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            console.log(res.json().result)
            let statusColl = res.json().result;

            // this.statusColl=[];

            for (let wbo of statusColl) {
              if (wbo.statusname === 'PLACED') { this.orderplacedcount = wbo.ordercount }
              if (wbo.statusname === 'CONFIRM') { this.confirmcount = wbo.ordercount }
              if (wbo.statusname === 'READYTOPICK') { this.readytopickcount = wbo.ordercount }
              if (wbo.statusname === 'PICKED') { this.pickedcount = wbo.ordercount }
              if (wbo.statusname === 'DELIVERED') { this.deliverycount = wbo.ordercount }
              if (wbo.statusname === 'CANCELLED') { this.cancelledcount = wbo.ordercount }              
              if (wbo.statusname === 'ALL') { this.allcount = wbo.ordercount }

            }

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fngetOrder() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'orderid': 0,
      'restaurantid': this.loginService.fnGetModRestaruntId(),
      'fromdate': this.fnFormatDatetime(this.fromdate),
      'todate': this.fnFormatDatetime(this.todate),
      'filterby': this.filterby,
    }

    this.appService.getOrder(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.orderColl = res.json().result;
            this.fngetReStatus();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddOrder() {
    try {
      this.router.navigate(['/AddOrder']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditOrder(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddOrder'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  //Grid

  fnActionEditClick(data) {
    if (!this.isedit) {
      return;
    }

    this.fnEditOrder(data.orderid);
  }



  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'orderlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
  fnGetDescription(id) {
    const index = this.orderColl.findIndex(sku => sku.orderid === id);

    if (index) {
      return this.orderColl[index].orderno;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
