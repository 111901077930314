import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-orderassign',
  templateUrl: './orderassign.component.html',
  styleUrls: ['./orderassign.component.css']
})
export class OrderassignComponent implements OnInit {

  @Input() orderBO:any={};

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService, private loginService: LoginDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  //Save Validation
  submitted: boolean;
  successfully: boolean;
  
  isduplicate: boolean;

 orderassignform = new FormGroup({  
   deliverypartnerid: new FormControl(null),
   trackno:new FormControl(null),
  });

 
  


  @Input()  deliverypartnerid: any = null;
  @Input() deliverypartnerColl: any = [];
  
  @Input()  trackno: any = null;
  
 

  @Output() emitData = new EventEmitter();

  ngOnInit() {

    this.orderassignform.reset();
    this.fnInitializeFormGroup();
    this.fngetDeliveryPartner();
    
  }


  fnInitializeFormGroup() {
    this.orderassignform.setValue({      
      deliverypartnerid: this.deliverypartnerid,
      trackno: this.trackno,     
    })
  }
  fngetDeliveryPartner() {
    var data = {
      'deliverypartnerid': 0,
      'filterby':"ACTIVE"  
     
    }
    this.appService.getDeliverypartnerlistview(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.deliverypartnerColl = res.json().result;
          console.log(this.deliverypartnerColl)
          } 
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

 

  fnSaveBtnClick() {
    try {
     

      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.orderassignform.valid) {
      
        //Check
        var data = {
          deliverypartnerid: this.orderassignform.get('deliverypartnerid').value,         
          trackno:this.orderassignform.get('trackno').value,  
        }


        this.emitData.emit(data);
        this.activeModal.close(true);


      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}


