import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addtaxation',
  templateUrl: './addtaxation.component.html',
  styleUrls: ['./addtaxation.component.css']
})
export class AddtaxationComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  taxationColl:any=[];
  taxationid:number=0
  
  //pageColl:any=[];
  
  taxationform=new FormGroup({        
    taxationid:new FormControl(0),     
    //productgroup:new FormControl('',Validators.required),            
    taxationname:new FormControl('',Validators.required),   
    taxrate:new FormControl(null,Validators.required), 
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Taxation";
  
  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.taxationid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.taxationform.reset();
         this.fnInitializeTaxation(); 
  
         if(this.taxationid>0){
           this.fngetTaxation();
         }       
  
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.taxationid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeTaxation(){
     this.taxationform.setValue({
       
      taxationid:0, 
      taxationname:'',   
      taxrate:null, 
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetTaxation() {        
       var data = {
         'taxationid':this.taxationid,            
       }
       this.appService.getTaxation(data)
       .subscribe(
         (res)=>{                  
           this.taxationColl=res.json().result;
           var editdata =this.taxationColl[0];
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.taxationform.valid){
           //Check
           var data = {
                 'taxationid': this.taxationid,                   
                 'taxationname':this.taxationform.get('taxationname').value, 
                 'taxrate':this.taxationform.get('taxrate').value,                 
                 'inactive':this.taxationform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckTaxation(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.taxationform.get('taxationid').value>0){
                         //Update Department
                         this.fnSetTaxation(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertTaxation(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.taxationform.controls['taxationname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertTaxation(data){    
   
     this.appService.fnInsertTaxation(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new taxation has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.taxationname);
  
         this.fnCloseTaxation();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetTaxation(data){    
  
  this.appService.fnSetTaxation(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The taxation has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.taxationid,data.taxationname);
  
       this.fnCloseTaxation();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseTaxation(){
  try{
    this.router.navigate(['/Taxation']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  private fnEditMode(deptBO){
  
   this.taxationform.setValue({

    taxationid:deptBO.taxationid,
    taxationname:deptBO.taxationname,
    taxrate:deptBO.taxrate,
        inactive:deptBO.inactive,   
   });
  
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  
  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
       
      //   });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  
  }
  