import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-actionformatter',
  templateUrl: './actionformatter.component.html',
  styleUrls: ['./actionformatter.component.css']
})
export class ActionformatterComponent implements OnInit {

  @Input() isadd:boolean=false;
  @Input() isedit:boolean=false;
  @Input() isdelete:boolean=false;
  @Input() isview:boolean=false;
  @Input() isprint:boolean=false;
  @Input() isexport:boolean=false;
  @Input() searchtxt: string = "";

  @Output() addChanged = new EventEmitter<number>();
  @Output() editChanged = new EventEmitter<number>();
  @Output() deleteChanged = new EventEmitter<number>();
  @Output() exportChanged = new EventEmitter<number>();
  @Output() filterChanged = new EventEmitter<number>();
  @Output() refreshChanged = new EventEmitter<number>();

  @Output() searchChanged = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
  }

  
  fnChangeSerach(){
    this.searchChanged.emit(this.searchtxt)
  }

}
