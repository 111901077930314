import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordergrid',
  templateUrl: './ordergrid.component.html',
  styleUrls: ['./ordergrid.component.css']
})
export class OrdergridComponent implements OnInit {

  pageno: any = 25;

  pages = [25, 50, 100, 500];
  
  p: number = 1;

  

  filterMetadata = { count: 0 };

  @Input() formname: any = "";

  @Input() contentcoll: any = [];
  @Input() searchtxt: any = "";
  @Input() gridOptions = {};
  @Input() isedit:any=false;
  @Input() isdelete:any=false;

  @Output() editChanged = new EventEmitter<any>();
  @Output() deleteChanged = new EventEmitter<any>();

  @Output() updateChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  fnEditBtnClick(pBO, type) {
    pBO.actiontype = type;
    this.editChanged.emit(pBO)
  }
 

  fnDeleteBtnClick(pBO) {
    this.deleteChanged.emit(pBO)
  }


  fnUpdateBtnClick(pBO,type){
    this.updateChanged.emit(pBO)
  }

}
