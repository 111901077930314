import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-confirmfailedorder',
  templateUrl: './confirmfailedorder.component.html',
  styleUrls: ['./confirmfailedorder.component.css']
})
export class ConfirmfailedorderComponent implements OnInit {

 
  @Input() cartBO:any={};

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService, private loginService: LoginDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  //Save Validation
  submitted: boolean;
  successfully: boolean;
  
  isduplicate: boolean;

 cartform = new FormGroup({   
   orderid: new FormControl('',Validators.required),
   paymentid:new FormControl('',Validators.required),
  });

 
  


  @Input() orderid: any = null;
  @Input() paymentid:  any = null;
  
 

  @Output() emitData = new EventEmitter();

  ngOnInit() {

    this.cartform.reset();
    this.fnInitializeFormGroup();
   
  }


  fnInitializeFormGroup() {
    this.cartform.setValue({      
      orderid: this.orderid,
      paymentid: this.paymentid,     
    })
  }
  
  fnSaveBtnClick() {
    try {
     

      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.cartform.valid) {
      
        //Check
        var data = {
          orderid: this.cartform.get('orderid').value,         
          paymentid:this.cartform.get('paymentid').value,  
        }


        this.emitData.emit(data);
        this.activeModal.close(true);


      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
