import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-pageorder',
  templateUrl: './pageorder.component.html',
  styleUrls: ['./pageorder.component.css']
})
export class PageorderComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string="";

  @Input() pagedetailsBO: any = {};
     
  @Input() editorValue: string = '';
 
  
  
  orderbyid:number=null;

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   
  }

    
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetPageOrder(){
    var _data={
      orderbyid:this.orderbyid
    }
    this.emitData.emit(_data);  
    this.activeModal.close(true); 


  }

}
