import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map, share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
      providedIn: 'root'
   }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress: any;

   constructor(private http: Http, private deviceService: DeviceDetectorService) {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      // this.fnGetIpAddress();
      console.log(this.deviceInfo)
   }

   fnpostInputParams(url, params) {
      const headers = new Headers({
         //'Content-Type': "application/JSON",
         // 'token': (localStorage.adtoken === undefined) ? '' : JSON.parse(localStorage.adtoken)                
      });


      return this.http.post(AppSettings.base_url + url, params, { headers: headers });
   }



   //ProductGroup 
   fnInsertProductGroup(params) {

      return this.fnpostInputParams(AppSettings.insertProductGroupUrl, params);
   }

   fnSetProductGroup(params) {

      return this.fnpostInputParams(AppSettings.setProductGroupUrl, params);
   }


   getProductGroup(params) {

      return this.fnpostInputParams(AppSettings.getProductGroupUrl, params);
   }


   fnDeleteProductGroup(params) {

      return this.fnpostInputParams(AppSettings.deleteProductGroupUrl, params);
   }

   fnCheckProductGroup(params) {

      return this.fnpostInputParams(AppSettings.checkProductGroupUrl, params);
   }

   //Cuisine 
   fnInsertCuisine(params) {

      return this.fnpostInputParams(AppSettings.insertCuisineUrl, params);
   }

   fnSetCuisine(params) {

      return this.fnpostInputParams(AppSettings.setCuisineUrl, params);
   }


   getCuisine(params) {

      return this.fnpostInputParams(AppSettings.getCuisineUrl, params);
   }


   fnDeleteCuisine(params) {

      return this.fnpostInputParams(AppSettings.deleteCuisineUrl, params);
   }

   fnCheckCuisine(params) {

      return this.fnpostInputParams(AppSettings.checkCuisineUrl, params);
   }




   //Company 
   fnInsertCompany(params) {

      return this.fnpostInputParams(AppSettings.insertCompanyUrl, params);
   }

   fnSetCompany(params) {

      return this.fnpostInputParams(AppSettings.setCompanyUrl, params);
   }


   getCompany(params) {

      return this.fnpostInputParams(AppSettings.getCompanyUrl, params);
   }


   fnDeleteCompany(params) {

      return this.fnpostInputParams(AppSettings.deleteCompanyUrl, params);
   }

   fnCheckCompany(params) {

      return this.fnpostInputParams(AppSettings.checkCompanyUrl, params);
   }


   getRestaurantCompany(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantCompanyUrl, params);
   }
   fnSetCompanyApproved(params) {

      return this.fnpostInputParams(AppSettings.setCompanyApprovedUrl, params);
   }

   //Restaurant 
   fnInsertRestaurant(params) {

      return this.fnpostInputParams(AppSettings.insertRestaurantUrl, params);
   }

   fnSetRestaurant(params) {

      return this.fnpostInputParams(AppSettings.setRestaurantUrl, params);
   }


   getRestaurant(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantUrl, params);
   }


   fnDeleteRestaurant(params) {

      return this.fnpostInputParams(AppSettings.deleteRestaurantUrl, params);
   }

   fnCheckRestaurant(params) {

      return this.fnpostInputParams(AppSettings.checkRestaurantUrl, params);
   }

   getRestaurantCuisine(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantcuisineUrl, params);
   }

   getRestaurantEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantestablishtypeUrl, params);
   }
   getRestaurantTiming(params) {

      return this.fnpostInputParams(AppSettings.getRestauranttimingUrl, params);
   }
   getRestaurantBrand(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantBrandUrl, params);
   }

   getRestaurantDishes(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantDishesUrl, params);
   }

   fnInsertRestaurantSaveas(params) {

      return this.fnpostInputParams(AppSettings.insertRestaurantSaveasUrl, params);
   }
   fnSetRestaurantApproved(params) {

      return this.fnpostInputParams(AppSettings.setRestaurantApprovedUrl, params);
   }
   getRestaurantInstanceTiming(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantInstancetimingUrl, params);
   }


   fnInsertRestaurantSlot(params) {

      return this.fnpostInputParams(AppSettings.insertRestaurantSlotUrl, params);
   }

   fnSetRestaurantSlot(params) {

      return this.fnpostInputParams(AppSettings.setRestaurantSlotUrl, params);
   }

   fnDeleteRestaurantSlot(params) {

      return this.fnpostInputParams(AppSettings.deleteRestaurantSlotUrl, params);
   }


   fnGetRestaurantSlot(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantSlotUrl, params);
   }

   //Splcategory 
   fnInsertSplcategory(params) {

      return this.fnpostInputParams(AppSettings.insertSplcategoryUrl, params);
   }

   fnSetSplcategory(params) {

      return this.fnpostInputParams(AppSettings.setSplcategoryUrl, params);
   }


   getSplcategory(params) {

      return this.fnpostInputParams(AppSettings.getSplcategoryUrl, params);
   }


   fnDeleteSplcategory(params) {

      return this.fnpostInputParams(AppSettings.deleteSplcategoryUrl, params);
   }

   fnCheckSplcategory(params) {

      return this.fnpostInputParams(AppSettings.checkSplcategoryUrl, params);
   }


   //Establishtype 
   fnInsertEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.insertEstablishtypeUrl, params);
   }

   fnSetEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.setEstablishtypeUrl, params);
   }


   getEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.getEstablishtypeUrl, params);
   }


   fnDeleteEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.deleteEstablishtypeUrl, params);
   }

   fnCheckEstablishtype(params) {

      return this.fnpostInputParams(AppSettings.checkEstablishtypeUrl, params);
   }


   //Producttagtype 
   fnInsertProducttagtype(params) {

      return this.fnpostInputParams(AppSettings.insertProducttagtypeUrl, params);
   }

   fnSetProducttagtype(params) {

      return this.fnpostInputParams(AppSettings.setProducttagtypeUrl, params);
   }


   getProducttagtype(params) {

      return this.fnpostInputParams(AppSettings.getProducttagtypeUrl, params);
   }


   fnDeleteProducttagtype(params) {

      return this.fnpostInputParams(AppSettings.deleteProducttagtypeUrl, params);
   }

   fnCheckProducttagtype(params) {

      return this.fnpostInputParams(AppSettings.checkProducttagtypeUrl, params);
   }



   //City 
   fnInsertCity(params) {

      return this.fnpostInputParams(AppSettings.insertCityUrl, params);
   }

   fnSetCity(params) {

      return this.fnpostInputParams(AppSettings.setCityUrl, params);
   }


   getCity(params) {

      return this.fnpostInputParams(AppSettings.getCityUrl, params);
   }


   fnDeleteCity(params) {

      return this.fnpostInputParams(AppSettings.deleteCityUrl, params);
   }

   fnCheckCity(params) {

      return this.fnpostInputParams(AppSettings.checkCityUrl, params);
   }



   //Area 
   fnInsertArea(params) {

      return this.fnpostInputParams(AppSettings.insertAreaUrl, params);
   }

   fnSetArea(params) {

      return this.fnpostInputParams(AppSettings.setAreaUrl, params);
   }


   getArea(params) {

      return this.fnpostInputParams(AppSettings.getAreaUrl, params);
   }


   fnDeleteArea(params) {

      return this.fnpostInputParams(AppSettings.deleteAreaUrl, params);
   }

   fnCheckArea(params) {

      return this.fnpostInputParams(AppSettings.checkAreaUrl, params);
   }

   //Foodsymbol 
   fnInsertFoodsymbol(params) {

      return this.fnpostInputParams(AppSettings.insertFoodsymbolUrl, params);
   }

   fnSetFoodsymbol(params) {

      return this.fnpostInputParams(AppSettings.setFoodsymbolUrl, params);
   }


   getFoodsymbol(params) {

      return this.fnpostInputParams(AppSettings.getFoodsymbolUrl, params);
   }


   fnDeleteFoodsymbol(params) {

      return this.fnpostInputParams(AppSettings.deleteFoodsymbolUrl, params);
   }

   fnCheckFoodsymbol(params) {

      return this.fnpostInputParams(AppSettings.checkFoodsymbolUrl, params);
   }



   //Category 
   fnInsertCategory(params) {

      return this.fnpostInputParams(AppSettings.insertCategoryUrl, params);
   }

   fnSetCategory(params) {

      return this.fnpostInputParams(AppSettings.setCategoryUrl, params);
   }


   getCategory(params) {

      return this.fnpostInputParams(AppSettings.getCategoryUrl, params);
   }


   fnDeleteCategory(params) {

      return this.fnpostInputParams(AppSettings.deleteCategoryUrl, params);
   }

   fnCheckCategory(params) {

      return this.fnpostInputParams(AppSettings.checkCategoryUrl, params);
   }

   //Subcategory 
   fnInsertSubcategory(params) {

      return this.fnpostInputParams(AppSettings.insertSubcategoryUrl, params);
   }

   fnSetSubcategory(params) {

      return this.fnpostInputParams(AppSettings.setSubcategoryUrl, params);
   }


   getSubcategory(params) {

      return this.fnpostInputParams(AppSettings.getSubcategoryUrl, params);
   }


   fnDeleteSubcategory(params) {

      return this.fnpostInputParams(AppSettings.deleteSubcategoryUrl, params);
   }

   fnCheckSubcategory(params) {

      return this.fnpostInputParams(AppSettings.checkSubcategoryUrl, params);
   }

   //Uom 
   fnInsertUom(params) {

      return this.fnpostInputParams(AppSettings.insertUomUrl, params);
   }

   fnSetUom(params) {

      return this.fnpostInputParams(AppSettings.setUomUrl, params);
   }


   getUom(params) {

      return this.fnpostInputParams(AppSettings.getUomUrl, params);
   }


   fnDeleteUom(params) {

      return this.fnpostInputParams(AppSettings.deleteUomUrl, params);
   }

   fnCheckUom(params) {

      return this.fnpostInputParams(AppSettings.checkUomUrl, params);
   }

   //User 
   fnInsertUser(params) {

      return this.fnpostInputParams(AppSettings.insertUserUrl, params);
   }

   fnSetUser(params) {

      return this.fnpostInputParams(AppSettings.setUserUrl, params);
   }


   getUser(params) {

      return this.fnpostInputParams(AppSettings.getUserUrl, params);
   }


   fnDeleteUser(params) {

      return this.fnpostInputParams(AppSettings.deleteUserUrl, params);
   }

   fnCheckUser(params) {

      return this.fnpostInputParams(AppSettings.checkUserUrl, params);
   }

   getUserlogin(params) {

      return this.fnpostInputParams(AppSettings.getLoginUserUrl, params);
   }



   //Taxation 
   fnInsertTaxation(params) {

      return this.fnpostInputParams(AppSettings.insertTaxationUrl, params);
   }

   fnSetTaxation(params) {

      return this.fnpostInputParams(AppSettings.setTaxationUrl, params);
   }


   getTaxation(params) {

      return this.fnpostInputParams(AppSettings.getTaxationUrl, params);
   }


   fnDeleteTaxation(params) {

      return this.fnpostInputParams(AppSettings.deleteTaxationUrl, params);
   }

   fnCheckTaxation(params) {

      return this.fnpostInputParams(AppSettings.checkTaxationUrl, params);
   }





   //Product 
   fnInsertProduct(params) {

      return this.fnpostInputParams(AppSettings.insertProductUrl, params);
   }

   fnSetProduct(params) {

      return this.fnpostInputParams(AppSettings.setProductUrl, params);
   }


   fnSetProductBulk(params) {

      return this.fnpostInputParams(AppSettings.setProductBulkUrl, params);
   }

   fnSetProductAvailable(params) {

      return this.fnpostInputParams(AppSettings.setProductAvailableUrl, params);
   }

   getProduct(params) {

      return this.fnpostInputParams(AppSettings.getProductUrl, params);
   }

   getProductListview(params) {

      return this.fnpostInputParams(AppSettings.getProductListviewUrl, params);
   }
   getProductTiming(params) {

      return this.fnpostInputParams(AppSettings.getProducttimingUrl, params);
   }

   fnDeleteProduct(params) {
      return this.fnpostInputParams(AppSettings.deleteProductUrl, params);
   }

   fnInsertProductVariation(params) {

      return this.fnpostInputParams(AppSettings.insertProductVariationsUrl, params);
   }

   fnSetProductVariation(params) {

      return this.fnpostInputParams(AppSettings.setProductVariationsUrl, params);
   }

   fnDeleteProductVariation(params) {

      return this.fnpostInputParams(AppSettings.deleteProductVariationsUrl, params);
   }

   fnCheckProduct(params) {

      return this.fnpostInputParams(AppSettings.checkProductUrl, params);
   }
   getProductDishes(params) {

      return this.fnpostInputParams(AppSettings.getProductDishesUrl, params);
   }
   getProductTag(params) {

      return this.fnpostInputParams(AppSettings.getProductTagUrl, params);
   }
   fnSetProductApproved(params) {

      return this.fnpostInputParams(AppSettings.setProductApprovedUrl, params);
   }
   fnSetProductOutofStock(params) {

      return this.fnpostInputParams(AppSettings.setProductOutofStockUrl, params);
   }

   //ImportProduct 
   fnInsertImportProduct(params) {

      return this.fnpostInputParams(AppSettings.insertImportProductUrl, params);
   }

   // Taxgroup
   fnInsertTaxgroup(params) {

      return this.fnpostInputParams(AppSettings.insertTaxgroupUrl, params);
   }

   fnSetTaxgroup(params) {

      return this.fnpostInputParams(AppSettings.setTaxgroupUrl, params);
   }


   getTaxgroup(params) {

      return this.fnpostInputParams(AppSettings.getTaxgroupUrl, params);
   }


   fnDeleteTaxgroup(params) {

      return this.fnpostInputParams(AppSettings.deleteTaxgroupUrl, params);
   }

   fnCheckTaxgroup(params) {

      return this.fnpostInputParams(AppSettings.checkTaxgroupUrl, params);
   }

   getTaxgroupdetails(params) {

      return this.fnpostInputParams(AppSettings.getTaxgroupdetailsUrl, params);
   }


   // Userrole
   fnInsertUserrole(params) {

      return this.fnpostInputParams(AppSettings.insertUserroleUrl, params);
   }

   fnSetUserrole(params) {

      return this.fnpostInputParams(AppSettings.setUserroleUrl, params);
   }


   getUserrole(params) {

      return this.fnpostInputParams(AppSettings.getUserroleUrl, params);
   }


   fnDeleteUserrole(params) {

      return this.fnpostInputParams(AppSettings.deleteUserroleUrl, params);
   }

   fnCheckUserrole(params) {

      return this.fnpostInputParams(AppSettings.checkUserroleUrl, params);
   }


   getRestaurantView(params) {

      return this.fnpostInputParams(AppSettings.getRestaurantViewUrl, params);
   }
   getUserRoleRights(params) {

      return this.fnpostInputParams(AppSettings.getUserRoleRightsUrl, params);
   }
   //Customer
   getCustomer(params) {

      return this.fnpostInputParams(AppSettings.getCustomerUrl, params);
   }
   getCustomerDetails(params) {

      return this.fnpostInputParams(AppSettings.getCustomerDetailsUrl, params);
   }
   fnInsertCustomerAddressAdmin(params) {

      return this.fnpostInputParams(AppSettings.insertCustomerAddressAdminUrl, params);
   }
   fnSetCustomerAddressAdmin(params) {

      return this.fnpostInputParams(AppSettings.setCustomerAddressAdminUrl, params);
   }
   fnDeleteCustomerAddressAdmin(params) {

      return this.fnpostInputParams(AppSettings.deleteCustomerAddressAdminUrl, params);
   }


   //Cart
   getCart(params) {

      return this.fnpostInputParams(AppSettings.getCartUrl, params);
   }
   getCartDetails(params) {

      return this.fnpostInputParams(AppSettings.getCartDetailsUrl, params);
   }



   // Brand
   fnInsertBrand(params) {

      return this.fnpostInputParams(AppSettings.insertBrandUrl, params);
   }

   fnSetBrand(params) {

      return this.fnpostInputParams(AppSettings.setBrandUrl, params);
   }


   getBrand(params) {

      return this.fnpostInputParams(AppSettings.getBrandUrl, params);
   }


   fnDeleteBrand(params) {

      return this.fnpostInputParams(AppSettings.deleteBrandUrl, params);
   }

   fnCheckBrand(params) {

      return this.fnpostInputParams(AppSettings.checkBrandUrl, params);
   }



   // Dishes
   fnInsertDishes(params) {

      return this.fnpostInputParams(AppSettings.insertDishesUrl, params);
   }

   fnSetDishes(params) {

      return this.fnpostInputParams(AppSettings.setDishesUrl, params);
   }


   getDishes(params) {

      return this.fnpostInputParams(AppSettings.getDishesUrl, params);
   }


   fnDeleteDishes(params) {

      return this.fnpostInputParams(AppSettings.deleteDishesUrl, params);
   }

   fnCheckDishes(params) {

      return this.fnpostInputParams(AppSettings.checkDishesUrl, params);
   }





   // Paymentmode
   fnInsertPaymentmode(params) {

      return this.fnpostInputParams(AppSettings.insertPaymentmodeUrl, params);
   }

   fnSetPaymentmode(params) {

      return this.fnpostInputParams(AppSettings.setPaymentmodeUrl, params);
   }


   getPaymentmode(params) {

      return this.fnpostInputParams(AppSettings.getPaymentmodeUrl, params);
   }


   fnDeletePaymentmode(params) {

      return this.fnpostInputParams(AppSettings.deletePaymentmodeUrl, params);
   }

   fnCheckPaymentmode(params) {

      return this.fnpostInputParams(AppSettings.checkPaymentmodeUrl, params);
   }





   // Deliverypartner
   fnInsertDeliverypartner(params) {

      return this.fnpostInputParams(AppSettings.insertDeliverypartnerUrl, params);
   }

   fnSetDeliverypartner(params) {

      return this.fnpostInputParams(AppSettings.setDeliverypartnerUrl, params);
   }


   getDeliverypartner(params) {

      return this.fnpostInputParams(AppSettings.getDeliverypartnerUrl, params);
   }

   getDeliverypartnerlistview(params) {

      return this.fnpostInputParams(AppSettings.getDeliverypartnerlistviewUrl, params);
   }


   fnDeleteDeliverypartner(params) {

      return this.fnpostInputParams(AppSettings.deleteDeliverypartnerUrl, params);
   }

   fnCheckDeliverypartner(params) {

      return this.fnpostInputParams(AppSettings.checkDeliverypartnerUrl, params);
   }
   fnSetDeliverypartnerApproved(params) {

      return this.fnpostInputParams(AppSettings.setDeliverypartneApprovedrUrl, params);
   }


   //Page 

   fnInsertPage(params) {

      return this.fnpostInputParams(AppSettings.insertPageUrl, params);
   }

   fnSetPage(params) {

      return this.fnpostInputParams(AppSettings.setPageUrl, params);
   }

   getPage(params) {

      return this.fnpostInputParams(AppSettings.getPageUrl, params);
   }


   getPageByListView(params) {

      return this.fnpostInputParams(AppSettings.getPageListViewUrl, params);
   }


   fnDeletePage(params) {
      const headers = new Headers({ 'Content-Type': "application/JSON" });
      return this.fnpostInputParams(AppSettings.deletePageUrl, params);
   }


   getLayoutType(params) {

      return this.fnpostInputParams(AppSettings.getLayoutTypeUrl, params);
   }

   fnSetPageLayoutOrder(params) {

      return this.fnpostInputParams(AppSettings.setPageLayoutOrderUrl, params);
   }


   //Order
   getOrder(params) {

      return this.fnpostInputParams(AppSettings.getOrderUrl, params);
   }
   getOrderDetails(params) {

      return this.fnpostInputParams(AppSettings.getOrderDetailsUrl, params);
   }
   setOrderStatus(params) {

      return this.fnpostInputParams(AppSettings.setOrderStatusUrl, params);
   }

   setOrderAssign(params) {

      return this.fnpostInputParams(AppSettings.setOrderAssignUrl, params);
   }


   getOrderStatus(params) {

      return this.fnpostInputParams(AppSettings.getOrderStatusUrl, params);
   }
   setCancelOrderAdmin(params) {

      return this.fnpostInputParams(AppSettings.setCancelOrderAdminUrl, params);
   }
   fnInsertOrder(params) {

      return this.fnpostInputParams(AppSettings.insertOrderAdminUrl, params);
   }

   setOrderConfimrFailed(params) {

      return this.fnpostInputParams(AppSettings.setOrderConfimrFailedUrl, params);
   }


   fnGetPrintProductOrder(params) {

      return this.fnpostInputParams(AppSettings.getprintproductorderUrl, params);
   }


   fnGetPrintDeliveryOrder(params) {

      return this.fnpostInputParams(AppSettings.getprintdeliveryorderUrl, params);
   }


   fnGetPrintOrder(params) {

      return this.fnpostInputParams(AppSettings.getprintorderUrl, params);
   }

   fnGetPrintInvoiceOrder(params) {

      return this.fnpostInputParams(AppSettings.getprintinvoiceorderUrl, params);
   }

   fnOrderFailedPaid(params) {

      return this.fnpostInputParams(AppSettings.getOrderFailedPaidUrl, params);
   }

   
   //Notification 
   fnInsertNotification(params) {

      return this.fnpostInputParams(AppSettings.insertNotificationUrl, params);
   }

   fnSetNotification(params) {

      return this.fnpostInputParams(AppSettings.setNotificationUrl, params);
   }


   getNotification(params) {

      return this.fnpostInputParams(AppSettings.getNotificationUrl, params);
   }


   fnDeleteNotification(params) {

      return this.fnpostInputParams(AppSettings.deleteNotificationUrl, params);
   }

   fnCheckNotification(params) {

      return this.fnpostInputParams(AppSettings.checkNotificationUrl, params);
   }


   //Coupon 
   fnInsertCoupon(params) {

      return this.fnpostInputParams(AppSettings.insertCouponUrl, params);
   }

   fnSetCoupon(params) {

      return this.fnpostInputParams(AppSettings.setCouponUrl, params);
   }


   getCoupon(params) {

      return this.fnpostInputParams(AppSettings.getCouponUrl, params);
   }


   fnDeleteCoupon(params) {

      return this.fnpostInputParams(AppSettings.deleteCouponUrl, params);
   }

   fnCheckCoupon(params) {

      return this.fnpostInputParams(AppSettings.checkCouponUrl, params);
   }

   //Settings 

   fnSetSettings(params) {

      return this.fnpostInputParams(AppSettings.setSettingsUrl, params);
   }


   getSettings(params) {

      return this.fnpostInputParams(AppSettings.getSettingsUrl, params);
   }


   //Deliverycharges 
   fnInsertDeliverycharges(params) {

      return this.fnpostInputParams(AppSettings.insertDeliverychargesUrl, params);
   }

   fnSetDeliverycharges(params) {

      return this.fnpostInputParams(AppSettings.setDeliverychargesUrl, params);
   }


   getDeliverycharges(params) {

      return this.fnpostInputParams(AppSettings.getDeliverychargesUrl, params);
   }


   fnDeleteDeliverycharges(params) {

      return this.fnpostInputParams(AppSettings.deleteDeliverychargesUrl, params);
   }

   fnCheckDeliverycharges(params) {

      return this.fnpostInputParams(AppSettings.checkDeliverychargesUrl, params);
   }


   //Offer 
   fnInsertOffer(params) {

      return this.fnpostInputParams(AppSettings.insertOfferUrl, params);
   }

   fnSetOffer(params) {

      return this.fnpostInputParams(AppSettings.setOfferUrl, params);
   }


   getOffer(params) {

      return this.fnpostInputParams(AppSettings.getOfferUrl, params);
   }
   getOfferListview(params) {

      return this.fnpostInputParams(AppSettings.getOfferListviewUrl, params);
   }


   fnDeleteOffer(params) {

      return this.fnpostInputParams(AppSettings.deleteOfferUrl, params);
   }

   fnCheckOffer(params) {

      return this.fnpostInputParams(AppSettings.checkOfferUrl, params);
   }


   //Report



   getReportActivityLog(params) {

      return this.fnpostInputParams(AppSettings.getreportactivitylogUrl, params);
   }
   getReportSalesView(params) {

      return this.fnpostInputParams(AppSettings.getreportsalesUrl, params);
   }


   getReportOrderItemSummary(params) {

      return this.fnpostInputParams(AppSettings.getreportorderitemsummaryUrl, params);
   }

   getReportDailyAppViewer(params) {

      return this.fnpostInputParams(AppSettings.getreportdailyappviewerUrl, params);
   }


   fnGetDashboardAdmin(params) {

      return this.fnpostInputParams(AppSettings.getdashboardadminUrl, params);
   }



   //PushNofication
   fnSendPushNotification(title: string = "", content: string = "", linkid: string = "", target: string = "", linkid1: string = "") {

      var imageUrl = "https://i2.wp.com/savethehighstreet.org/wp/wp-content/uploads/2017/03/general-enquiry-icon.png?ssl=1"
      imageUrl = "";
      var gameUrl = "";

      let url = 'https://fcm.googleapis.com/fcm/send';
      let body =
      {
         "data": {
            "title": title,
            "content": content,
            "imageUrl": imageUrl,
            "gameUrl": gameUrl,
            "linkid": linkid,
            "linkid1": linkid1
         },
         "to": "/topics/" + target
      };

      let headers = new Headers({
         'Content-Type': 'application/json',
         'Authorization': 'key=AAAACwdsT7c:APA91bFVVE6fcIVaVmNC5wNQ9hFJHi4vnsmvbMC8hfstoUtwrK2RvyQhTPHnShM-xwQZ1vKU_Py8x1K8zMqA4SeJC78tid9AGavTBTNSTo9S-j5lqBkRicJO1IgeVnDAT4WauYHUVcAn'
      });
      let options = { headers: headers };

      this.http.post(url, body, options).subscribe(data => {
         // console.log(data);
      });





   }



   fnSendNotification(data, deviceColl) {


      var imageUrl = ""

      if (data.notificationimgpath != "") {
         imageUrl = data.notificationimgpath;
      }

      if (data.customimgpath != "") {
         imageUrl = data.customimgpath;
      }

      if (data.customerid > 0) {
         var target = [];



         for (let abo of deviceColl) {
            target.push(abo.deviceid)
         }



         if (target.length > 0) {

            let url = 'https://fcm.googleapis.com/fcm/send';
            let body =
            {
               "notification": {
                  "title": data.title,
                  "body": data.notificationmsg,
                  "image": imageUrl,
                  "android": {
                     "sound": "alert.mp3",
                  },
               },
               "priority": "high",
               "data": {
                  "image": imageUrl,
                  "click_action": "FLUTTER_NOTIFICATION_CLICK",
                  "linktype": data.linktype,
                  "linktargetid": data.linktargetid,
                  "refid": data.refid,
                  // "notificationtype": "MULTILINE",                  
               },
               "registration_ids": target
            };


            let headers = new Headers({
               'Content-Type': 'application/json',
               'Authorization': 'key=' + data.notifiapi
            });
            let options = { headers: headers };
            return this.http.post(url, body, options);
         }

      }
      else {
         let url = 'https://fcm.googleapis.com/fcm/send';
         let body =
         {
            "notification": {
               "title": data.title,
               "body": data.notificationmsg,
               "image": imageUrl,
               // "sound": "alert.mp3",
               "android": {
                  "sound": "alert.mp3",
               },
            },
            "data": {
               "image": imageUrl,
               "click_action": "FLUTTER_NOTIFICATION_CLICK",
               "linktype": data.linktype,
               "linktargetid": data.linktargetid,
               "refid": data.refid,
               //"notificationtype": "MULTILINE",
            },
            "to": data.target,
         };

         let headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'key=' + data.notifiapi
         });
         console.log(body);
         let options = { headers: headers };
         return this.http.post(url, body, options);
      }


   }




   fnSendNotificationCart(data) {


      var imageUrl = ""


      let url = 'https://fcm.googleapis.com/fcm/send';
      let body =
      {
         "notification": {
            "title": data.title,
            "body": data.notificationmsg,
            "image": imageUrl,
         },
         "data": {
            "image": imageUrl,
         },
         "to": data.target
      };

      let headers = new Headers({
         'Content-Type': 'application/json',
         'Authorization': 'key=' + data.notifiapi
      });
      let options = { headers: headers };
      return this.http.post(url, body, options);


   }


   fnGetDeepLink(data) {
      let headers = new Headers({
         'Content-Type': 'application/json',         
      });
      let options = { headers: headers };
     
      
      let durl = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key="+AppSettings.firebaseConfig.apiKey
      
      let body =
      {
         "dynamicLinkInfo": {
            "dynamicLinkDomain": "yesboz.page.link",
            "link": "https://yesboz.com/post?linktype="+data.linktype+"&linktargetid="+data.linktargetid+"&refid="+data.refid+"",
            "androidInfo": {
              "androidPackageName": "com.yesboz.online"
            }     
          }, 
          "suffix": {
            "option": "SHORT" 
          }
      };

      return this.http.post(durl, body, options);


   }
   private fnExeception(err: HttpErrorResponse) {
      if (err.error instanceof ErrorEvent) {
         console.error('Client Side Error', err.error.message);
      } else {
         console.error('Server Side Error', err);
      }
      return throwError("This is problem with dept service--" + err.error.ExceptionMessage);
   }


   // "data":{
   //    "title":data.title,
   //    "content":data.notificationmsg,
   //    "imageUrl":imageUrl,
   //    "gameUrl":gameUrl,
   //    "linkid":0,
   //    "linkid1":0
   //  },

}


