import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestaurantsaveasComponent } from '../restaurantsaveas/restaurantsaveas.component';
import { SelectcuisineComponent } from '../selectcuisine/selectcuisine.component';
import { SelectestablishmentComponent } from '../selectestablishment/selectestablishment.component';
import { AddrestaurantslotComponent } from '../addrestaurantslot/addrestaurantslot.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-addrestaurant',
  templateUrl: './addrestaurant.component.html',
  styleUrls: ['./addrestaurant.component.css']
})
export class AddrestaurantComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  show: boolean;
  //Edit Get Values
  restaurantColl: any = [];
  restaurantid: number = 0
  actiontype: string = ""

  restaurantslotColl: any = [];

  restaurantdetailsColl: any = [];
  restaurantBO: any = [];
  companyColl: any = [];
  brandColl: any = [];
  packingchargestypeColl: any = [];
  restauranttypeColl: any = [];
  cityColl: any = [];
  pageColl: any = [];

  restaurantcuisinecoll: any = [];
  restaurantestablishmentcoll: any = [];
  restauranttimingcoll: any = [];
  restaurantbrandcoll: any = [];
  restaurantdishescoll: any = [];
  restaurantinstancetimingcoll: any = [];

  restaurantdisplaycuisinecoll: any = [];
  restaurantdisplayestablishmentcoll: any = [];

  //pageColl:any=[];

  restaurantform = new FormGroup({
    restaurantid: new FormControl(0),
    companyid: new FormControl(null, Validators.required),
    restaurantname: new FormControl('', Validators.required),
    restaurantcode: new FormControl('', Validators.required),
    address: new FormControl(''),
    costfortwo: new FormControl(null, Validators.required),
    preparationmin: new FormControl(null, Validators.required),
    deliverymin: new FormControl(null),
    contactperson: new FormControl(null),
    contactno1: new FormControl(null, Validators.required),
    contactno2: new FormControl(null),
    geolocation: new FormControl(null),
    longitude: new FormControl(null, Validators.required),
    latitude: new FormControl(null, Validators.required),
    converradius: new FormControl(null, Validators.required),
    isveg: new FormControl(null),
    gstnumber: new FormControl(null),
    fssinumber: new FormControl(null),
    overallratings: new FormControl(null, [Validators.required, Validators.max(5)]),
    restaurantimage: new FormControl(null),
    pwd: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    brandid: new FormControl(null),
    isapproved: new FormControl(false),
    isnew: new FormControl(false),
    packingcharges: new FormControl(null),
    isowndelivery: new FormControl(false),
    isinstancedelivery: new FormControl(false),
    issloteddelivery: new FormControl(false),
    minorderamt: new FormControl(null),
    packingchargestype: new FormControl(''),
    deliverycharges: new FormControl(null),
    restauranttype: new FormControl(null, Validators.required),
    cityid: new FormControl(null, Validators.required),
    mindeliverycharges: new FormControl(null),
    addtionalconverradius: new FormControl(null),
    addtionaldeliverycharges: new FormControl(null),
    slotdays: new FormControl(null),
    hinttext: new FormControl(null),
    pageid: new FormControl(null),
    deliveryhint: new FormControl(null),
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Restaurant";


  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private confirmationDialogService: ConfirmationDialogService,
    public router: Router, private toastr: ToastrService, private productFindService: NgbModal, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.restaurantid = params.id
      }
      if (params.actiontype !== undefined) {
        this.actiontype = params.actiontype
      }


    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.restaurantform.reset();
      this.fnInitializeRestaurant();

      if (this.restaurantid > 0) {
        this.fngetRestaurant();
        this.fngetRestaurantSlot();
      }
      this.fngetRestaurantCuisine();
      this.fngetRestaurantEstablishtype();
      this.fngetRestaurantTiming();
      this.fngetRestaurantBrand(),
        this.fngetRestaurantDishes(),
        this.fngetRestaurantInstanceTiming();

      this.fngetCompany();
      this.fngetBrand();
      this.fnPackingChargeType();

      this.fnRestaurantType();
      this.fngetCity();
      this.fngetPage();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.restaurantid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeRestaurant() {
    this.restaurantform.setValue({

      restaurantid: 0,
      companyid: null,
      restaurantname: '',
      restaurantcode: 'New',
      address: '',
      costfortwo: null,
      preparationmin: null,
      deliverymin: null,
      contactperson: null,
      contactno1: null,
      contactno2: null,
      geolocation: null,
      longitude: null,
      latitude: null,
      converradius: null,
      isveg: null,
      gstnumber: null,
      fssinumber: null,
      overallratings: null,
      restaurantimage: null,
      pwd: '',
      location: '',
      brandid: null,
      isapproved: false,
      isnew: false,
      packingcharges: null,
      isowndelivery: false,
      isinstancedelivery: false,
      issloteddelivery: false,
      minorderamt: null,
      packingchargestype: '',
      deliverycharges: null,
      restauranttype: '',
      cityid: null,
      mindeliverycharges: null,
      addtionalconverradius: null,
      addtionaldeliverycharges: null,
      slotdays: null,
      hinttext: null,
      pageid:null,
      deliveryhint:null,
      inactive: false,
    })
  }

  fnPackingChargeType() {
    this.packingchargestypeColl = []
    this.packingchargestypeColl.push({ value: 'RATE', displayname: 'Rate' })
    this.packingchargestypeColl.push({ value: 'AMOUNT', displayname: 'Amount' })

  }

  fnRestaurantType() {
    this.restauranttypeColl = []
    this.restauranttypeColl.push({ value: 'RESTAURANT', displayname: 'Restaurant' })
    this.restauranttypeColl.push({ value: 'RETAIL', displayname: 'Retail' })
    this.restauranttypeColl.push({ value: 'FRESH', displayname: 'Fresh' })
    this.restauranttypeColl.push({ value: 'NATIVE', displayname: 'Native' })
  }


  fngetCompany() {


    this.fnShowProgress();
    var data = {
      'comapnyid': 0,
    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.companyColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
  fngetBrand() {


    this.fnShowProgress();
    var data = {
      'brandid': 0,
    }
    this.appService.getBrand(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.brandColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }




  fngetCity() {


    this.fnShowProgress();
    var data = {
      'brandid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.cityColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fngetPage() {

    this.fnShowProgress();
    var data = {
      'pageid': 0,
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.pageColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetRestaurant() {
    var data = {
      'restaurantid': this.restaurantid,

    }
    this.appService.getRestaurant(data)
      .subscribe(
        (res) => {
          this.restaurantColl = res.json().result;
          var editdata = this.restaurantColl[0];
          this.restaurantBO = editdata;

          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantSlot() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.fnGetRestaurantSlot(data)
      .subscribe(
        (res) => {

          this.restaurantslotColl = res.json().result;


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantCuisine() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantCuisine(data)
      .subscribe(
        (res) => {
          this.restaurantcuisinecoll = res.json().result;
          this.fnFormatCuisineFormat();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantEstablishtype() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantEstablishtype(data)
      .subscribe(
        (res) => {
          this.restaurantestablishmentcoll = res.json().result;
          this.fnFormatEstablishmentFormat();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantTiming() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantTiming(data)
      .subscribe(
        (res) => {
          this.restauranttimingcoll = res.json().result;

          console.log(this.restauranttimingcoll)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantBrand() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantBrand(data)
      .subscribe(
        (res) => {
          this.restaurantbrandcoll = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetRestaurantDishes() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantDishes(data)
      .subscribe(
        (res) => {
          this.restaurantdishescoll = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetRestaurantInstanceTiming() {
    var data = {
      'restaurantid': this.restaurantid,
    }
    this.appService.getRestaurantInstanceTiming(data)
      .subscribe(
        (res) => {
          this.restaurantinstancetimingcoll = res.json().result;

          console.log(this.restauranttimingcoll)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnResetValidation() {
    if (this.loginService.fnCheckFornControlNotfound(this.restaurantform.get('restaurantimage'))) {
      this.restaurantform.controls['restaurantimage'].setErrors(null);
    }
  }

  fnSetValidation() {
    let _ischeck = false;

    if (this.restaurantform.get('restaurantimage').value == null && this.picurl === '') {
      this.restaurantform.controls['restaurantimage'].setErrors({ 'notfound': true });
      _ischeck = true;
    }

    return _ischeck;
  }


  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;

      this.fnResetValidation()
      //Validate
      if (this.restaurantform.valid) {

        if (this.fnSetValidation()) {
          return;
        }

        //Check
        var data = {
          'restaurantid': this.restaurantid,
          'companyid': this.restaurantform.get('companyid').value,
          'restaurantname': this.restaurantform.get('restaurantname').value,
          'restaurantcode': this.restaurantform.get('restaurantcode').value,
          'address': this.restaurantform.get('address').value,
          'costfortwo': this.restaurantform.get('costfortwo').value,
          'preparationmin': this.restaurantform.get('preparationmin').value,
          'deliverymin': this.restaurantform.get('deliverymin').value,
          'contactperson': this.restaurantform.get('contactperson').value,
          'contactno1': this.restaurantform.get('contactno1').value,
          'contactno2': this.restaurantform.get('contactno2').value,
          'geolocation': this.restaurantform.get('geolocation').value,
          'longitude': this.restaurantform.get('longitude').value,
          'latitude': this.restaurantform.get('latitude').value,
          'converradius': this.restaurantform.get('converradius').value,
          'isveg': this.restaurantform.get('isveg').value,
          'gstnumber': this.restaurantform.get('gstnumber').value,
          'fssinumber': this.restaurantform.get('fssinumber').value,
          'overallratings': this.restaurantform.get('overallratings').value,
          restaurantimage: this.strImage,
          picurl: this.picurl,
          'pwd': this.restaurantform.get('pwd').value,
          'location': this.restaurantform.get('location').value,
          'brandid': this.restaurantform.get('brandid').value,
          'isapproved': this.restaurantform.get('isapproved').value,
          'isnew': this.restaurantform.get('isnew').value,
          'packingcharges': this.restaurantform.get('packingcharges').value,
          'isowndelivery': this.restaurantform.get('isowndelivery').value,
          'isinstancedelivery': this.restaurantform.get('isinstancedelivery').value,
          'issloteddelivery': this.restaurantform.get('issloteddelivery').value,
          'minorderamt': this.restaurantform.get('minorderamt').value,
          'deliverycharges': this.restaurantform.get('deliverycharges').value,
          'packingchargestype': this.restaurantform.get('packingchargestype').value,
          'restauranttype': this.restaurantform.get('restauranttype').value,
          'cityid': this.restaurantform.get('cityid').value,
          'mindeliverycharges': this.restaurantform.get('mindeliverycharges').value,
          'addtionalconverradius': this.restaurantform.get('addtionalconverradius').value,
          'addtionaldeliverycharges': this.restaurantform.get('addtionaldeliverycharges').value,
          'slotdays': this.restaurantform.get('slotdays').value,
          'hinttext': this.restaurantform.get('hinttext').value,
          'pageid': this.restaurantform.get('pageid').value,
          'deliveryhint': this.restaurantform.get('deliveryhint').value,
          'inactive': this.restaurantform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'restaurantcuisinecoll': this.restaurantcuisinecoll,
          'restaurantestablishmentcoll': this.restaurantestablishmentcoll,
          'restauranttimingcoll': this.restauranttimingcoll,
          'restaurantbrandcoll': this.restaurantbrandcoll,
          'restaurantdishescoll': this.restaurantdishescoll,
          'restaurantinstancetimingcoll': this.restaurantinstancetimingcoll,
        }
        this.appService.fnCheckRestaurant(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.restaurantform.get('restaurantid').value > 0) {
                //Update Department
                this.fnSetRestaurant(data);
              }
              else {
                //Insert Department
                this.fnInsertRestaurant(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.restaurantform.controls['restaurantname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertRestaurant(data) {

    this.appService.fnInsertRestaurant(data).subscribe(
      (res) => {
        if (res.json().status == 200) {
          this.toastr.success('', "The new Restaurant has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.restaurantname);

          this.fnCloseRestaurant();
        }
        else {
          console.log(res.json())
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetRestaurant(data) {

    this.appService.fnSetRestaurant(data).subscribe(
      (res) => {
        if (res.json().status == 200) {
          this.toastr.success('', "The Restaurant has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.restaurantid, data.restaurantname);

          this.fnCloseRestaurant();
        }
        else {
          console.log(res.json())
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  fnSetApproved() {

    var data = {
      'restaurantid': this.restaurantid,
      'isapproved': this.restaurantBO.isapproved,
      'modifiedby': this.loginService.fnGetModUserId(),

    }
    this.appService.fnSetRestaurantApproved(data).subscribe((res) => {
      window.location.reload()
      if (res.json().result === 200) {

        this.toastr.success('', "The Approved has been updated successfully.");
      }
    })
  }

  fnRestaurantSaveasDetails() {

    const modalRef = this.productFindService.open(RestaurantsaveasComponent, { size: <any>'m' });

    modalRef.componentInstance.restaurantid = this.restaurantid;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      if (receivedEntry === 'DONE') {
        this.fnCloseRestaurant();
      }

    });
  }

  fnTiming() {
    for (let row of this.restauranttimingcoll) {
      if (row.starttime < row.endtime) {

      }
    }
  }

  //Close
  fnCloseRestaurant() {
    try {
      this.router.navigate(['/Restaurant']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnCopytoallBtnClick() {
    if (this.restauranttimingcoll.length > 0) {
      var data = this.restauranttimingcoll[0];

      for (let abo of this.restauranttimingcoll) {
        abo.inactive = data.inactive;
        abo.starttime = data.starttime;
        abo.endtime = data.endtime;
      }
    }
  }

  fnCopyInstancetoallBtnClick() {
    if (this.restaurantinstancetimingcoll.length > 0) {
      var data = this.restaurantinstancetimingcoll[0];

      for (let abo of this.restaurantinstancetimingcoll) {
        abo.inactive = data.inactive;
        abo.starttime = data.starttime;
        abo.endtime = data.endtime;
      }
    }
  }


  //SwitchRestauran
  fnSwitchRestaurant() {
    var data = {
      restaurantid: this.restaurantid
    }
    this.appService.getRestaurantView(data).subscribe(
      (res) => {

        if (res.json().status == 200) {
          localStorage.setItem('restoken', JSON.stringify(res.json().result.restaurantid));
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else {
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }



  checkValue(values: any, index) {
    this.restaurantcuisinecoll[index].ischeck = values.currentTarget.checked;
  }

  fnEstablishcheckValue(values: any, index) {
    this.restaurantestablishmentcoll[index].ischeck = values.currentTarget.checked;
  }
  checkValueBrand(values: any, index) {
    this.restaurantbrandcoll[index].ischeck = values.currentTarget.checked;
  }

  checkValueDishes(values: any, index) {
    this.restaurantdishescoll[index].ischeck = values.currentTarget.checked;
  }


  fnFormatCuisineFormat() {
    this.restaurantdisplaycuisinecoll = [];

    for (let abo of this.restaurantcuisinecoll) {
      if (abo.ischeck) {
        this.restaurantdisplaycuisinecoll.push(abo)
      }
    }

  }
  fnFormatEstablishmentFormat() {
    this.restaurantdisplayestablishmentcoll = [];

    for (let abo of this.restaurantestablishmentcoll) {
      if (abo.ischeck) {
        this.restaurantdisplayestablishmentcoll.push(abo)
      }
    }

  }



  fnEditBtnClick() {
    this.iseditmode = true;
    this.isviewmode = false;
  }
  private fnEditMode(deptBO) {

    this.restaurantform.setValue({

      restaurantid: deptBO.restaurantid,
      companyid: deptBO.companyid,
      restaurantname: deptBO.restaurantname,
      restaurantcode: deptBO.restaurantcode,
      address: deptBO.address,
      costfortwo: deptBO.costfortwo,
      preparationmin: deptBO.preparationmin,
      deliverymin: deptBO.deliverymin,
      contactperson: deptBO.contactperson,
      contactno1: deptBO.contactno1,
      contactno2: deptBO.contactno2,
      geolocation: deptBO.geolocation,
      longitude: deptBO.longitude,
      latitude: deptBO.latitude,
      converradius: deptBO.converradius,
      isveg: deptBO.isveg,
      gstnumber: deptBO.gstnumber,
      fssinumber: deptBO.fssinumber,
      overallratings: deptBO.overallratings,
      restaurantimage: null,
      pwd: deptBO.pwd,
      location: deptBO.location,
      brandid: deptBO.brandid,
      isapproved: deptBO.isapproved,
      isnew: deptBO.isnew,
      packingcharges: deptBO.packingcharges,
      isowndelivery: deptBO.isowndelivery,
      isinstancedelivery: deptBO.isinstancedelivery,
      issloteddelivery: deptBO.issloteddelivery,
      minorderamt: deptBO.minorderamt,
      deliverycharges: deptBO.deliverycharges,
      packingchargestype: deptBO.packingchargestype,
      restauranttype: deptBO.restauranttype,
      cityid: deptBO.cityid,
      mindeliverycharges: deptBO.mindeliverycharges,
      addtionalconverradius: deptBO.addtionalconverradius,
      addtionaldeliverycharges: deptBO.addtionaldeliverycharges,
      slotdays: deptBO.slotdays,
      hinttext: deptBO.hinttext,
      pageid: deptBO.pageid,
      deliveryhint:deptBO.deliveryhint,
      inactive: deptBO.inactive,
    });
    this.picurl = deptBO.picurl;


    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;

    if (this.actiontype === "EDIT") {
      this.iseditmode = true;
      this.isviewmode = false;
    }
  }
  fnSelectCuisine() {

    const modalRef = this.productFindService.open(SelectcuisineComponent, { size: <any>'l' });

    modalRef.componentInstance.restaurantid = this.restaurantid;
    modalRef.componentInstance.restaurantcuisinecoll = this.restaurantcuisinecoll;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.restaurantdisplaycuisinecoll = receivedEntry;
      this.fnFormatCuisineFormat()

    });
  }
  fnSelectEstablishment() {

    const modalRef = this.productFindService.open(SelectestablishmentComponent, { size: <any>'l' });

    modalRef.componentInstance.restaurantid = this.restaurantid;
    modalRef.componentInstance.restaurantestablishmentcoll = this.restaurantestablishmentcoll;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.restaurantdisplayestablishmentcoll = receivedEntry;
      this.fnFormatEstablishmentFormat()

    });
  }



  //RestaurantSlot
  private fnAddRestaurantSlot() {

    const modalRef = this.productFindService.open(AddrestaurantslotComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.restaurantid = this.restaurantid

    modalRef.componentInstance.fnInitializeProductFormGroup();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fnInsertRestaurantSlot(receivedEntry);
    });

  }


  private fnEditRestaurantSlot(deptBO) {

    const modalRef = this.productFindService.open(AddrestaurantslotComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.slotid = deptBO.slotid
    modalRef.componentInstance.restaurantid = this.restaurantid
    modalRef.componentInstance.starttime = deptBO.starttime
    modalRef.componentInstance.endtime = deptBO.endtime
    modalRef.componentInstance.cutoftime = deptBO.cutoftime

    modalRef.componentInstance.fnInitializeProductFormGroup();
    modalRef.componentInstance.ngOnInit();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fnSetRestaurantSlot(receivedEntry);
    });

  }


  public fnDeleteRestaurantSlotConfirm(deptBO) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Restaurant Slot?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteRestaurantSlot(deptBO)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Insert
  fnInsertRestaurantSlot(data) {

    this.appService.fnInsertRestaurantSlot(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The New RestaurantSlot has been added successfully.Insert Successfully");

          // this.fnInsertActivityLog('INSERT', 0, data.productname);
          this.fngetRestaurantSlot();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetRestaurantSlot(data) {

    this.appService.fnSetRestaurantSlot(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The RestaurantSlot has been updated successfully.");

          //  this.fnInsertActivityLog('EDIT', this.productid, data.productname);

          this.fngetRestaurantSlot();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Delete
  fnDeleteRestaurantSlot(deptBO) {

    var data = {
      slotid: deptBO.slotid,
      modifiedby: this.loginService.fnGetModUserId(),
    }

    this.appService.fnDeleteRestaurantSlot(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The RestaurantSlot has been deleted successfully.");

          //  this.fnInsertActivityLog('EDIT', this.productid, data.productname);

          this.fngetRestaurantSlot();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  fnCloseCuisine(cBO) {

    for (let abo of this.restaurantcuisinecoll) {
      if (abo.cuisineid === cBO.cuisineid) {
        abo.ischeck = false;
      }
    }

    this.fnFormatCuisineFormat()


  }
  fnCloseEstablishment(cBO) {

    for (let abo of this.restaurantestablishmentcoll) {
      if (abo.establishtypeid === cBO.establishtypeid) {
        abo.ischeck = false;
      }
    }

    this.fnFormatEstablishmentFormat()


  }
  fnShowpassword(event) {

    this.show = !this.show;
  }
  // fnTimeValidation(){
  //   for(let row of this.restauranttimingcoll)
  //   {
  //     var start=this.restauranttimingcoll.starttime
  //     var end=this.restauranttimingcoll.endtime
  //     if(start>end){
  //       alert()
  //     }
  //   }
  // }
  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
